<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb/>
            </div>
          </div>
          <div class="content-panel">
            <div class="my-artartist">
              <div class="my-artwork">
                <div class="title-wrap">
                  <h2 class="section-title capital">
                    MY ARTWORK
                  </h2>
<!--                  <DownloadButtonArtWorkCategory :artWorksCategories="artWorksCategories"/>-->
                  <div v-if="allArtWorks.length > 0" class="title-exp">
                    <p>업로드한 아트워크는 ART & ARTISTS 페이지에 모두 노출됩니다.<br>
                      '카테고리 관리'에서 카테고리 생성 및 삭제, 아트워크 노출 순서 변경이 가능합니다.</p>
                    <div class="button-box">
                      <button type="button" class="btn-art" id="btnManage" @click="openArtWorkManageModal">
                        <span>아트워크 관리</span>
                        <img src="@/assets/images/icon/ico_right.svg" alt="">
                      </button>
                    </div>
                  </div>
                </div>
                <div class="register">
                  <ul>
                    <li>
                      <button type="button" class="register-artwrork" @click="() => openModal(MODE_REGISTER, null)">
                        <img src="@/assets/images/icon/ico_add_ye.svg" alt="">
                        <span v-if="!allArtWorks.length">첫 아트워크를 등록해주세요.</span>
                      </button>
                    </li>
                    <li v-for="(artWork, index) in allArtWorks" :key="index">
                      <img v-if="artWork" :src="artWork.imageUrl" :alt="artWork.title"/>
                      <div class="mask" style="flex-direction: column; gap: 10px">
                        <button type="button" class="btn-art-edit" @click="() => openModal(MODE_MODIFY, artWork)">
                          아트워크 수정
                        </button>
                        <DownloadButtonArtWork :art-work-uuid="artWork.uuid"/>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>

  <!-- s::모달창 -->
  <!-- 아트워크 등록 모달 -->
  <ArtworkModal
      v-if="isModalVisible"
      :art-work="modalArtWork"
      :modal-mode="modalMode"
      @close="isModalVisible = false"
  />

  <!-- 아트워크 노출 관리 모달 -->
  <div ref="modalManageArtworkRef" class="modal-wrap2 modal" id="modalManageArtwork" v-if="isManageModalVisible">
    <div class="sort-panel">
      <div class="modal-title-wrap">
        <h2 class="section-title">아트워크 노출 관리</h2>
        <button type="button" class="close-modal" @click="closeArtWorkManageModal">
          <img src="@/assets/images/icon/ico_close_w.svg" alt="">
        </button>
      </div>
      <div class="inner-box">
        <div class="l-cont">
          <div class="cont-wrap">
            <div class="category-wrap">
              <ul class="category">
                <li>
                  <button type="button" id="category-all" :class="['btn-category', { 'active': !selectedArtWorksCategory }]"
                          @click="setCategory(null)">All
                  </button>
                </li>
                <li v-for="(category, index) in artWorksCategories" :key="category.uuid">
                  <button type="button" :id="'category-' + index"
                          :class="['btn-category', { 'active': selectedArtWorksCategory && selectedArtWorksCategory.uuid === category.uuid }]"
                          @click="setCategory(category)">
                    <span>{{ category.name }}</span>
                  </button>
                </li>
              </ul>
              <div class="btn-wrap">
                <button type="button" v-if="selectedArtWorksCategory" class="add-s" id="btnEditCategory" @click="setCategoryUpdateModalVisible(true)">
                  <span>수정</span>
                  <img src="@/assets/images/icon/ico_edit.svg" alt="">
                </button>
                <button type="button" class="add-s" id="btnAddCategory" @click="setCategoryAddModalVisible(true)">
                  <span>추가</span>
                  <img src="@/assets/images/icon/ico_add_wh.svg" alt="">
                </button>
              </div>
            </div>

            <div class="sort-list-wrap">
              <div class="sort-list-content">
                <div class="sort-title">
                  <h3>{{ selectedArtWorksCategory ? selectedArtWorksCategory.name : "전체" }} 아트워크</h3>
                  <p>우측 상단의 추가+ 버튼을 누르면 카테고리를 3개까지 생성할 수 있습니다.</p>
                </div>
                <!-- 드래그 아이템 내용 -->

                <!-- 왼쪽 목록 -->
                <draggable
                    ref="leftArtWorkList"
                    v-model="filteredLeftArtWorks"
                    tag="ul"
                    group="artworkGroup"
                    item-key="uuid"
                    class="sort-list connected-sortable"
                    :class="{ 'disabled': isMoveDisabled }"
                    ghost-class="dragging"
                    @end="onDragEnd"
                    :disabled="isMoveDisabled"
                    :handle="'.left-handler'"
                >

                  <template #item="{ element }">
                    <li :data-uuid="element.uuid" class="draggable-item" :class="{ selected: selectedLeftArtWorks.includes(element.uuid) }"
                        @click="toggleLeftSelection(element.uuid)">
                      <!--                      <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">-->
                      <i>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                          <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round"
                                stroke-linejoin="round"/>
                        </svg>
                      </i>
                      <figure class="left-handler">
                        <img :src="element.imageUrl" :alt="element.title || element.titleEn">
                      </figure>
                      <div class="mask txt" :class="{ 'left-handler' : !isMobile}">
                        <p>
                          {{ element.title || element.titleEn}}<br>
                          {{ element.size }}<br>
                          {{ element.year }}<br>
                        </p>
                      </div>
                    </li>
                  </template>
                </draggable>
              </div>
              <span v-show="isMoveDisabled" class="disabled-text"></span> <!-- ‘ALL’ 카테고리에서는 빼기가 불가능합니다(노출 순서 변경만 가능).-->
              <div v-show="!isMoveDisabled" class="sort-bottom">
                <button type="button" class="btn-line btn-insert" :disabled="selectedLeftArtWorks.length === 0" @click="moveSelectedToRight">
                  <span>카테고리에 넣기</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 8 18" fill="none">
                    <path
                        d="M-8.79878e-07 1.68512L0.780931 0.765626L8 9.26562L0.780933 17.7656L3.36909e-07 16.8461L6.43814 9.26563L-8.79878e-07 1.68512Z"
                        fill="#16151A"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="r-cont">
          <div class="cont-wrap">
            <div class="cont-title">
              <p v-if="!selectedArtWorksCategory" class="pc">이미지를 드래그하면 노출 순서 변경 가능</p>
              <p v-else class="pc">
                - 이미지를 드래그하면 노출 순서 변경<br>
                - 빨간 버튼은 카테고리에서 제외 기능<br>
                - 이미지 중복 선택 후 빼기도 가능
              </p>
              <p class="mo" v-show="!isMoveDisabled" style="font-size: 12px">
                - 이미지를 드래그하면 노출 순서 변경<br>
                - 빨간 버튼은 카테고리에서 제외 기능<br>
                - 이미지 중복 선택 후 빼기도 가능
              </p>
              <button v-show="!isMoveDisabled" type="button" class="btn-line mobile btn-remove" :disabled="selectedRightArtWorks.length === 0"
                      @click="moveSelectedToLeft">
                <span>카테고리에서 빼기</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="8" viewBox="0 0 10 5" fill="none">
                  <path d="M0.650645 4.7954L0.123047 4.34731L5.00026 0.205078L9.87748 4.34731L9.34988 4.7954L5.00026 1.10126L0.650645 4.7954Z"
                        fill="#FFF3CE"/>
                </svg>
              </button>
            </div>
            <div ref="sortListWrapRef" class="sort-list-wrap no-bg">
              <div :class="['sort-list-border', { 'empty': !filteredRightArtWorks || filteredRightArtWorks.length === 0 }]">

                <!-- 오른쪽 목록 -->
                <draggable
                    ref="rightArtWorkList"
                    v-model="filteredRightArtWorks"
                    tag="ul"
                    group="artworkGroup"
                    item-key="uuid"
                    class="sort-list2 connected-sortable"
                    ghost-class="dragging"
                    @start="onDragStart"
                    @end="onDragEnd"
                    :move="onDragMove"
                    :handle="'.right-handler'"
                >
                  <template #item="{ element }">
                    <li :data-uuid="element.uuid" class="draggable-item"
                        :class="{ selected: selectedRightArtWorks.includes(element.uuid), 'right-handler': !isMobile }"
                        @click="!isMoveDisabled ? toggleRightSelection(element.uuid) : null">
                      <div class="dots right-remove" @click.stop="moveToLeft(element.uuid)">
                        <img v-if="!isMoveDisabled" src="@/assets/images/icon/ico_minus.svg" alt="">
                      </div>
                      <!--                      <i>-->
                      <!--                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">-->
                      <!--                          <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round"-->
                      <!--                                stroke-linejoin="round"/>-->
                      <!--                        </svg>-->
                      <!--                      </i>-->
                      <figure>
                        <img :src="element.imageUrl" :alt="element.title || element.titleEn">
                      </figure>
                      <div class="mask txt">
                        <p class="title">{{ element.title || element.titleEn}}</p>
                        <p>
                          {{ element.size }}<br>
                          {{ element.year }}<br>
                        </p>
                      </div>
                      <div :class="{'right-handler': isMobile }">
                        <img src="@/assets/images/icon/ico_drag2.svg" alt="" draggable="true">
                      </div>
                    </li>
                  </template>
                </draggable>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-box" style="gap: 6px;">
        <button v-show="!isMoveDisabled" type="button" class="btn-line pc-btn-remove" :disabled="selectedRightArtWorks.length === 0"
                @click="moveSelectedToLeft">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="8" viewBox="0 0 10 5" fill="none" style="transform: rotate(270deg);">
            <path d="M0.650645 4.7954L0.123047 4.34731L5.00026 0.205078L9.87748 4.34731L9.34988 4.7954L5.00026 1.10126L0.650645 4.7954Z"
                  fill="#FFF3CE"/>
          </svg>
          <span>카테고리에서 빼기</span>
        </button>
        <button type="button" class="btn-art" @click="updateArtWorksCategoryAndOrder">
          <span>설정 완료</span>
          <img src="@/assets/images/icon/ico_right2.svg" alt="">
        </button>
      </div>
    </div>
  </div>

  <!-- 카테고리 추가 모달 -->
  <CategoryAddModal
      v-if="isCategoryAddModalVisible"
      v-model:categoryName="inputCategoryName"
      v-model:categoryHide="inputCategoryHide"
      @save="saveCategory"
      @close="setCategoryAddModalVisible(false)"
  />

  <!-- 카테고리 수정 모달 -->
  <CategoryUpdateModal
      v-if="isCategoryUpdateModalVisible"
      v-model:categoryName="inputCategoryName"
      v-model:categoryHide="inputCategoryHide"
      @close="setCategoryUpdateModalVisible(false)"
      @openDeleteModal="setCategoryDeleteModalVisible(true)"
      @update="updateCategory"
  />

  <!-- 카테고리 삭제 모달 -->
  <CategoryDeleteModal
      v-if="isCategoryDeleteModalVisible"
      :categoryName="selectedArtWorksCategory.name"
      @close="setCategoryDeleteModalVisible(false)"
      @delete="deleteCategory"
  />

</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch} from 'vue';
import 'swiper/swiper-bundle.css';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {useUiStore} from "@/store/useUiStore";
import draggable from 'vuedraggable';
import {useDeviceStore} from "@/store/deviceStore";
import DownloadButtonArtWorkCategory from "@/components/ui/DownloadButtonArtWorkCategory.vue";
import DownloadButtonArtWork from "@/components/ui/DownloadButtonArtWork.vue";
import CategoryAddModal from "@/components/profileArtWork/CategoryAddModal.vue";
import CategoryUpdateModal from "@/components/profileArtWork/CategoryUpdateModal.vue";
import CategoryDeleteModal from "@/components/profileArtWork/CategoryDeleteModal.vue";
import {useRoute} from "vue-router";
import router from "@/router";
import ArtworkModal from "@/components/profileArtWork/ArtworkModal.vue";

const deviceStore = useDeviceStore();
const isMobile = computed(() => deviceStore.isMobile);
const uiStore = useUiStore();
const route = useRoute();

const MODE_REGISTER = 'register';
const MODE_MODIFY = 'modify';

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const modalMode = ref(null);

const allArtWorks = ref([]);
const artWorksCategories = ref(null);
const selectedArtWorksCategory = ref(null);
const modalArtWork = ref(null);

const isModalVisible = ref(false);
const isManageModalVisible = ref(false);
const isCategoryAddModalVisible = ref(false);
const isCategoryUpdateModalVisible = ref(false);
const isCategoryDeleteModalVisible = ref(false);

const inputCategoryName = ref(null);
const inputCategoryHide = ref(false);

const leftArtWorkList = ref(null);
const rightArtWorkList = ref(null);

const selectedLeftArtWorks = ref([]);
const selectedRightArtWorks = ref([]);
const isMoveDisabled = computed(() => !selectedArtWorksCategory.value);

const modalManageArtworkRef = ref(null);
const sortListWrapRef = ref(null);

const scrollSpeed = 5 // 스크롤 속도
const scrollThreshold = 200; // 상단/하단 스크롤 범위
const isScrolling = ref(false);  // 스크롤 반복 여부


/* 아트워크 등록/수정 모달 s */
const openModal = (mode, artWork) => {
  if (artWork) {
    modalArtWork.value = artWork;
  } else {
    modalArtWork.value = {
      uuid: null,
      imageUrl: null,
      title: null,
      titleEn: null,
      year: '',
      size: null,
      material: null,
      genre: null,
      etc: null,
      themeSubject: '',
      themeTime: '',
      themeFocus: '',
      themeStyle: '',
      description: null,
    };
  }

  modalMode.value = mode;
  isModalVisible.value = true;
}
/* 아트워크 등록/수정 모달 e */

/* 아트워크 관리 모달 s */
const openArtWorkManageModal = () => {
  isManageModalVisible.value = true;
  uiStore.showOverlay();

};

const closeArtWorkManageModal = () => {
  window.history.back();
};

const resetArtWorkManageState = () => {
  isManageModalVisible.value = false;
  setCategory(null);
  selectedLeftArtWorks.value = [];
  selectedRightArtWorks.value = [];
  uiStore.hideOverlay();
}

const handleArtWorkManageModalPopState = (event) => {
  if(isCategoryAddModalVisible.value || isCategoryUpdateModalVisible.value || isCategoryDeleteModalVisible.value){
    return;
  }
  resetArtWorkManageState();
}

watch(() => isManageModalVisible.value, (newVal) => {
  if(newVal){
    window.history.pushState({artWorkManageModalOpen: true}, '');
    window.addEventListener('popstate', handleArtWorkManageModalPopState);
  } else {
    window.removeEventListener('popstate', handleArtWorkManageModalPopState);
  }
});

const updateItems = () => {

  // 1) DOM 순서를 기준으로 uuid를 추출
  const leftUuids = leftArtWorkList.value.modelValue.map(el => el.uuid);
  const rightUuids = rightArtWorkList.value.modelValue.map(el => el.uuid);

  // 2) 조건에 따라 업데이트 분기 처리
  if (!selectedArtWorksCategory.value) {
    // "ALL" 선택: sortOrder 값을 업데이트
    // leftUuids.forEach((uuid, index) => {
    //   const item = allArtWorks.value.find(i => i.uuid === uuid);
    //   if (item) {
    //     item.sortOrder = index;
    //   }
    // });
    if (leftUuids.length > 0) {
      console.error("err")
      return;
    }
    rightUuids.forEach((uuid, index) => {
      const item = allArtWorks.value.find(i => i.uuid === uuid);
      if (item) {
        item.sortOrder = index;
      }
    });
  } else {
    // 특정 카테고리 선택: categoryUuid, categorySortOrder 값을 업데이트
    leftUuids.forEach((uuid, index) => {
      const item = allArtWorks.value.find(i => i.uuid === uuid);
      if (item) {
        item.categoryUuid = null;
        item.categorySortOrder = null;
      }
    });
    rightUuids.forEach((uuid, index) => {
      const item = allArtWorks.value.find(i => i.uuid === uuid);
      if (item) {
        item.categoryUuid = selectedArtWorksCategory.value.uuid;
        item.categorySortOrder = index;
      }
    });
  }
  selectedLeftArtWorks.value = [];
  selectedRightArtWorks.value = [];

};

const groupedLeftArtWorks = computed(() => {
  const groups = {};
  artWorksCategories.value.forEach(category => {
    allArtWorks.value.forEach(artWork => {
      if (!artWork.categoryUuid) {
        const key = category.uuid;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(artWork);
      }
    });
  })

  // 그룹 내에서는 sortOrder 기준으로 정렬
  Object.keys(groups).forEach(key => {
    groups[key].sort((a, b) => a.sortOrder - b.sortOrder);
  });

  return Object.entries(groups).map(([category, items]) => ({category, items}));
});

const groupedRightArtWorks = computed(() => {
  const groups = {};

  allArtWorks.value.forEach(artWork => {
    // categoryUuid가 존재하고 categorySortOrder가 정의되어 있으면 해당 카테고리 그룹에 넣습니다.
    if (artWork.categoryUuid && artWork.categorySortOrder != null) {
      const key = artWork.categoryUuid;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(artWork);
    }

    // 모든 작품을 "ALL" 그룹에 넣고 sortOrder 기준으로 정렬합니다.
    const key = "ALL";
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(artWork);
  });

  // 각 그룹 내 정렬: "ALL" 그룹은 sortOrder, 나머지 그룹은 categorySortOrder 기준 정렬
  Object.keys(groups).forEach(key => {
    if (key === "ALL") {
      groups[key].sort((a, b) => a.sortOrder - b.sortOrder);
    } else {
      groups[key].sort((a, b) => a.categorySortOrder - b.categorySortOrder);
    }
  });
  // 템플릿에서 쉽게 반복할 수 있도록 배열 형태로 변환
  return Object.entries(groups).map(([category, items]) => ({category, items}));
});

const filteredLeftArtWorks = ref([]);
watch(
    () => selectedArtWorksCategory.value,
    (newCategory) => {
      if (!newCategory) {
        filteredLeftArtWorks.value = []; // 선택된 카테고리가 없으면 빈 배열 유지
      } else {
        const group = groupedLeftArtWorks.value.find(group => group.category === newCategory.uuid);
        filteredLeftArtWorks.value = group ? [...group.items] : [];
      }
    },
    {immediate: true} // 처음 로드될 때도 실행
);

const filteredRightArtWorks = ref([]);
watch(
    [() => selectedArtWorksCategory.value, () => allArtWorks.value],
    ([newCategory, newAllArtWorks]) => {
      if (!newAllArtWorks.length) {
        return;
      } // allArtWorks가 비어있으면 실행 안 함.

      const newGroupedRightArtWorks = groupedRightArtWorks.value; // 최신 grouped 데이터 가져오기

      if (!newCategory) {
        const group = newGroupedRightArtWorks.find(group => group.category === 'ALL');
        filteredRightArtWorks.value = group ? [...group.items] : [];
      } else {
        const group = newGroupedRightArtWorks.find(group => group.category === newCategory.uuid);
        filteredRightArtWorks.value = group ? [...group.items] : [];
      }
    },
    {immediate: true}
);

const toggleLeftSelection = (artWorkUuid) => {
  const index = selectedLeftArtWorks.value.indexOf(artWorkUuid);
  if (index === -1) {
    selectedLeftArtWorks.value.push(artWorkUuid);
  } else {
    selectedLeftArtWorks.value.splice(index, 1);
  }
};

const toggleRightSelection = (artWorkUuid) => {
  const index = selectedRightArtWorks.value.indexOf(artWorkUuid);
  if (index === -1) {
    selectedRightArtWorks.value.push(artWorkUuid);
  } else {
    selectedRightArtWorks.value.splice(index, 1);
  }
};

// 선택된 아이템들을 오른쪽(카테고리 영역)으로 이동시키는 함수
const moveSelectedToRight = () => {
  if (!selectedArtWorksCategory.value) {
    alert("먼저 카테고리를 선택해주세요.");
    return;
  }

  selectedLeftArtWorks.value.forEach(uuid => {
    const artWork = allArtWorks.value.find(item => item.uuid === uuid);
    if (artWork) {
      // 선택된 카테고리의 uuid를 할당하여 오른쪽 리스트로 이동 처리
      artWork.categoryUuid = selectedArtWorksCategory.value.uuid;
      // 오른쪽 리스트의 마지막 순서 번호에 맞춰 categorySortOrder를 할당할 수 있습니다.
      artWork.categorySortOrder = filteredRightArtWorks.value.length;
      filteredRightArtWorks.value.push(artWork);
    }
  });

  filteredLeftArtWorks.value = filteredLeftArtWorks.value.filter(
      item => !selectedLeftArtWorks.value.includes(item.uuid)
  );

  // 이동 후 선택 상태 초기화
  selectedLeftArtWorks.value = [];
};

const moveSelectedToLeft = () => {
  if (!selectedArtWorksCategory.value) {
    alert("먼저 카테고리를 선택해주세요.");
    return;
  }

  selectedRightArtWorks.value.forEach(uuid => {
    const artWork = allArtWorks.value.find(item => item.uuid === uuid);
    if (artWork) {
      // 선택된 카테고리의 uuid를 할당하여 오른쪽 리스트로 이동 처리
      artWork.categoryUuid = null;
      // 오른쪽 리스트의 마지막 순서 번호에 맞춰 categorySortOrder를 할당할 수 있습니다.
      artWork.categorySortOrder = null;
      filteredLeftArtWorks.value.push(artWork);
    }
  });

  filteredRightArtWorks.value = filteredRightArtWorks.value.filter(
      item => !selectedRightArtWorks.value.includes(item.uuid)
  );

  // 이동 후 선택 상태 초기화
  selectedRightArtWorks.value = [];
};

const moveToLeft = (uuid) => {
  // 선택한거 취소하고 넘기기
  // selectedRightArtWorks.value = [uuid];
  // moveSelectedToLeft();

  // 선택 유지하면서 넘기기
  const artWork = allArtWorks.value.find(item => item.uuid === uuid);
  if (artWork) {
    // 선택된 카테고리의 uuid를 할당하여 오른쪽 리스트로 이동 처리
    artWork.categoryUuid = null;
    // 오른쪽 리스트의 마지막 순서 번호에 맞춰 categorySortOrder를 할당할 수 있습니다.
    artWork.categorySortOrder = null;
    filteredLeftArtWorks.value.push(artWork);
  }

  filteredRightArtWorks.value = filteredRightArtWorks.value.filter(item => item.uuid !== uuid);
  selectedRightArtWorks.value = selectedRightArtWorks.value.filter(item => item !== uuid);
}

const setCategory = (category) => {
  if (category) {
    if (!selectedArtWorksCategory.value || selectedArtWorksCategory.value.uuid !== category.uuid) {
      selectedArtWorksCategory.value = category;
    }
  } else {
    if (selectedArtWorksCategory.value) {
      selectedArtWorksCategory.value = null;
    }
  }

  selectedLeftArtWorks.value = [];
  selectedRightArtWorks.value = [];
};

const disableScroll = () => {
  if (modalManageArtworkRef.value) {
    modalManageArtworkRef.value.style.overflow = 'hidden'
    // document.body.addEventListener('touchmove', preventDefault, { passive: false })
  }
}

const enableScroll = () => {
  if (modalManageArtworkRef.value) {
    modalManageArtworkRef.value.style.overflow = 'auto'
    // document.body.removeEventListener('touchmove', preventDefault)
  }
}

const onDragStart = (e) => {
  if (isMobile.value) {
    disableScroll() // 드래그 시작 시 스크롤 비활성화
    // sortListWrapRef.value.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

const onDragEnd = () => {
  if (isMobile.value) {
    enableScroll(); // 모바일일 때만 enableScroll 호출
  }
  updateItems();
  isScrolling.value = false;
}

// `onDragMove` 이벤트에서 스크롤 방향 감지
const onDragMove = (event) => {
  if (!sortListWrapRef.value) {
    return;
  }

  const {top, bottom} = sortListWrapRef.value.getBoundingClientRect();
  const currentPos = event.originalEvent.clientY;
  if (currentPos < top + scrollThreshold) {
    handleScroll(-1); // 위로 스크롤
  } else if (currentPos > bottom - scrollThreshold) {
    handleScroll(1); // 아래로 스크롤
  } else {
    isScrolling.value = false; // 스크롤 중지
  }
};

const handleScroll = (direction) => {
  const scrollContainer = document.querySelector('.sort-list2');

  const smoothScroll = () => {
    if (!isScrolling.value) {
      return;
    } // 스크롤 중지되면 종료

    const maxScrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight; // 최대 스크롤 값
    const currentScrollTop = scrollContainer.scrollTop; // 현재 스크롤 위치
    if ((direction === -1 && currentScrollTop <= 0) || (direction === 1 && currentScrollTop >= maxScrollTop)) {
      isScrolling.value = false;
      return;
    }

    scrollContainer.scrollBy({
      top: direction * scrollSpeed, // 위(-1) or 아래(+1) 이동
    });

    requestAnimationFrame(smoothScroll); // 반복 호출
  };

  if (!isScrolling.value) {
    isScrolling.value = true;
    smoothScroll();
  }
};

const updateArtWorksCategoryAndOrder = async () => {
  uiStore.showLoading();
  const payload = allArtWorks.value.map((artWork) => ({
    artWorkUuid: artWork.uuid,
    // "ALL"인 경우엔 sortOrder 업데이트, 그 외엔 categorySortOrder 업데이트
    sortOrder: artWork.sortOrder != null ? artWork.sortOrder : null,
    categoryUuid: artWork.categoryUuid || null,
    categorySortOrder: artWork.categorySortOrder != null ? artWork.categorySortOrder : null,
  }));

  try {
    await apiClient.put('/v1/artists/me/artworks/category-and-order-bulk', payload);
    alert("정상 반영되었습니다.");
    await fetchArtWorks();
  } catch (error) {
    console.error("업데이트 실패:", error);
    // alert("업데이트에 실패했습니다.");
  } finally {
    closeArtWorkManageModal();
    uiStore.hideLoading();
  }
};
/* 아트워크 관리 모달 e */


/* 아트워크 카테고리 모달 s */
const saveCategory = async () => {
  try {
    if (!inputCategoryName.value) {
      alert("카테고리 이름을 입력해주세요.");
      return;
    }

    if (artWorksCategories.value.length >= 3) {
      alert("생성 가능한 카테고리 갯수를 초과했습니다. (3개까지 생성 가능)");
      return;
    }

    await apiClient.post(`/v1/artists/me/artworks/categories`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    await fetchArtWorksCategories();

    setCategoryAddModalVisible(false);
    alert("카테고리가 생성되었습니다.");
  } catch (error) {
    alert("카테고리 생성에 실패했습니다.");
  }
};

const updateCategory = async () => {
  try {
    if (!inputCategoryName.value) {
      alert("카테고리 이름을 입력해주세요.");
      return;
    }

    await apiClient.put(`/v1/artists/me/artworks/categories/${selectedArtWorksCategory.value.uuid}`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    await fetchArtWorksCategories();

    // 업데이트된 목록에서 선택된 카테고리를 찾아서 반영
    const updatedCategory = artWorksCategories.value.find(
        category => category.uuid === selectedArtWorksCategory.value.uuid
    );
    if (updatedCategory) {
      selectedArtWorksCategory.value = updatedCategory;
    }

    setCategoryUpdateModalVisible(false);
    alert("카테고리가 수정되었습니다.");
  } catch (error) {
    alert("카테고리 수정에 실패했습니다.");
  }
}

const deleteCategory = async () => {
  try {
    await apiClient.delete(`/v1/artists/me/artworks/categories/${selectedArtWorksCategory.value.uuid}`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    await fetchArtWorksCategories();
    await fetchArtWorks();
    setCategory(null);

    setCategoryDeleteModalVisible(false);
    setCategoryUpdateModalVisible(false);
    alert("카테고리가 삭제되었습니다.");
  } catch (error) {
    alert("카테고리 삭제에 실패했습니다.");
  }
}

const setCategoryAddModalVisible = (isVisible) => {
  inputCategoryName.value = null;
  inputCategoryHide.value = false;

  isCategoryAddModalVisible.value = isVisible
}

const setCategoryUpdateModalVisible = (isVisible) => {
  if (isVisible) {
    inputCategoryName.value = selectedArtWorksCategory.value.name;
    inputCategoryHide.value = selectedArtWorksCategory.value.isHidden;
  } else {
    inputCategoryName.value = null;
    inputCategoryHide.value = false;
  }

  isCategoryUpdateModalVisible.value = isVisible
}

const setCategoryDeleteModalVisible = (isVisible) => {
  isCategoryDeleteModalVisible.value = isVisible
}
/* 아트워크 카테고리 모달 e */



/* 아트워크 조회 s */
// 데이터 로드 및 초기화
const fetchArtWorks = async () => {
  try {
    const response = await apiClient.get('/v1/artists/me/artworks');
    allArtWorks.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const fetchArtWorksCategories = async () => {
  try {
    const response = await apiClient.get(`/v1/artists/me/artworks/categories`);
    artWorksCategories.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
/* 아트워크 조회 e */


// 컴포넌트가 마운트되었을 때 데이터 로드
onMounted(async () => {
  uiStore.showLoading();
  await fetchArtWorks();
  await fetchArtWorksCategories();
  uiStore.hideLoading(200 + allArtWorks.value.length * 5);
});

onBeforeUnmount(() => {
  uiStore.hideOverlay();
});
</script>

<style scoped>
/* 퍼블 끝나고 넣기 mypage.css */
.mypage .my-artartist .my-artwork .register > ul li {
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.mypage .my-artartist .my-artwork .register > ul li > img {
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.disabled {
  pointer-events: none; /* 클릭 이벤트나 드래그 이벤트를 차단 */
}

.disabled-text {
  font-size: 14px;
  color: #ff9696; /* 경고 색상(빨간색) */
  font-weight: bold;
  pointer-events: none; /* 텍스트가 클릭되지 않도록 */
  height: 43px;
  word-break: keep-all;
}

/* 드래그 선택한 영역*/
.draggable-item.sortable-chosen {
  border: 4px solid #ff5c00;
}

/* 드래그 선택한 요소*/
.dragging {
  transform: scale(0.9);
}

.right-handler {
  cursor: grab;
}

.right-handler:active {
  cursor: grabbing;
}

.modal-wrap2 .sort-list li:active {
  cursor: grabbing;
}

.right-remove {
  cursor: pointer;
}

.modal-wrap2 .sort-list2 {
  max-height: 80vh;
}

.button-box .btn-line.pc-btn-remove {
  background: transparent;
  border: 1px solid #FFF3CE;
  width: fit-content;
}

.button-box .btn-line.pc-btn-remove span {
  color: #FFF3CE;
  font-size: 16px;
  margin-left: -6px;
}

.button-box .btn-line.pc-btn-remove:disabled span {
  color: #999;
}

.button-box .btn-line.pc-btn-remove:disabled {
  border: 1px solid #999;
}

@media (max-width: 1024px) {
  .button-box .btn-line.pc-btn-remove {
    display: none;
  }
}

.modal-wrap2 .sort-list2 li .txt p.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.modal-wrap2.modal {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

</style>