import { defineStore } from "pinia";
import { apiClient } from "@/services/auth-header";
import { useAuthStore } from '@/store/auth.module';

export const useUnreadStore = defineStore("unread", {
  state: () => ({
    unreadAnsweredInquiriesCount: 0, // 미확인 답변된 문의글 수
    unreadNoticesCount: 0, // 미확인 공지 수
  }),
  actions: {
    // unreadAnsweredInquiriesCount 값 갱신
    setUnreadCount(count) {
      this.unreadAnsweredInquiriesCount = count;
    },

    // unreadNoticesCount 값 갱신
    setUnreadNoticesCount(count) {
      this.unreadNoticesCount = count;
    },

    // unreadAnsweredInquiriesCount 수 가져오기
    async fetchUnreadAnsweredInquiriesCount() {
      try {
        const response = await apiClient.get("/v1/support/inquiries/unread-count");
        this.setUnreadCount(response.data); 
      } catch (error) {
        console.error("Error fetching unread inquiries count:", error);
      }
    },

    // unreadNoticesCount 가져오기
    async fetchUnreadNoticesCount() {
      try {
        const response = await apiClient.get("/v1/support/notices/unread-count");
        this.setUnreadNoticesCount(response.data); 
      } catch (error) {
        console.error("Error fetching unread notices count:", error);
      }
    },

    // 모든 unread 상태 업데이트
    async fetchAllUnreadCounts() {
      const authStore = useAuthStore();
      if(authStore.status.user == null) {
        return;
      }

      try {
        await this.fetchUnreadAnsweredInquiriesCount();
        await this.fetchUnreadNoticesCount();
      } catch (error) {
        //console.error("Error fetching all unread counts:", error);
      }
    },
  },
});
