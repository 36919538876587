import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import { oauthRoutes } from "@/router/oauthRoutes";
import { userRoutes } from "./userRoutes";
import { artistRoutes } from "./artistRoutes";
import { articleRoutes } from "./articleRoutes";
import Policy from "@/components/policy/Policy.vue";
import NotFound from "@/views/NotFound.vue";
import { supportRoutes } from "@/router/supportRoutes";
import { myPageRoutes } from "@/router/myPageRoutes";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (!sessionStorage.getItem("reloaded")) {
        sessionStorage.setItem("reloaded", "true");
        window.location.href = to.fullPath; // 새로고침
      } else {
        sessionStorage.removeItem("reloaded"); // 이후에는 정상 라우팅
        next();
      }
    }
    // meta: {
    //   title: 'Article21',
    //   description: 'Article21의 공식 홈페이지입니다. 다양한 아티스트와 작품을 소개합니다.',
    //   keywords: 'Article21, 아티스트, 작품, 전시, 이벤트'
    // }
  },
  ...userRoutes,
  ...oauthRoutes,
  ...artistRoutes,
  ...articleRoutes,
  ...supportRoutes,
  ...myPageRoutes,
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: Policy,
  },
  {
    path: "/terms",
    name: "TermsPolicy",
    component: Policy,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const { title, description, keywords } = to.meta;
  // console.log(title, description, keywords )
  setMeta({
    title: title || 'ARTICLE21',
    description: description || 'More Art Better Me',
    keywords: keywords || 'ARTICLE21, 아티클21, 아티스트, 미술작가, 미술작품, 전시, 이벤트, 칼럼, 작가 인터뷰'
  });

  const isAuthenticated = !!localStorage.getItem("user"); // 로그인 여부 체크

  if (to.path.startsWith("/my/") && !isAuthenticated) {
    alert("로그인 후 이용하실 수 있습니다.");
    next("/"); 
    return;
  }

  next(); // 정상적인 라우팅 진행
});

const setMeta = ({ title, description, keywords }) => {
  if (title) document.title = title;

  const updateMetaTag = (name, content) => {
    let tag = document.querySelector(`meta[name="${name}"]`);
    if (!tag) {
      tag = document.createElement('meta');
      tag.name = name;
      document.head.appendChild(tag);
    }
    tag.setAttribute('content', content);
  };

  if (description) updateMetaTag('description', description);
  if (keywords) updateMetaTag('keywords', keywords);
};

// router/index.js
// router.beforeEach((to, from, next) => {
// const isAuthenticated = store.state.auth.status.loggedIn;
// const isAdmin = store.state.auth.user && store.state.auth.user.roles[0] === 'ROLE_ADMIN';
// if(isAuthenticated){
// }
//
// if (to.path.includes('admin') && (!isAuthenticated || !isAdmin)) {
//     next('/');
// } else {
//     next();
// }
// });

export default router;
