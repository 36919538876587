<template>
  <div class="title">
    <span>{{ title }}</span>
  </div>
  <div class="col  field style-04">
    <div class="row" v-for="(item, index) in internalData" :key="index">
      <div class="field-one input-text-1 link-box">
        <input type="text" v-model="item.interviewLink" maxlength="999" :placeholder="placeholder">
      </div>
      <transition name="fade">
        <div v-show="item.interviewLink" class="field-one input-text-1 no-line title-box">
          <input type="text" v-model="item.interviewTitle" maxlength="999" placeholder="인터뷰 제목을 넣어주세요.">
        </div>
      </transition>
      <button v-if="index === 0" type="button" class="add-list" @click="addListItem(item.type, item.typeLabel)">
        <img src="@/assets/images/icon/ico_add3.svg">
      </button>
      <button v-else type="button" class="add-list delete" @click="removeItem(item.type, index)">
        <img src="@/assets/images/icon/ico_delete.svg">
      </button>
    </div>
  </div>
</template>

<script setup>
import {reactive, toRefs, watch} from 'vue'
import {apiClient} from "@/services/auth-header";

const props = defineProps({
  title: String,
  placeholder: String,
  data: Array,
});
const {title, placeholder, data} = toRefs(props);
// props.data를 reactive 형태로 변환한 내부 데이터
const internalData = reactive(
    (props.data && props.data.length > 0) // props.data가 유효한 경우
        ? props.data.map((item) => ({
          interviewLink: item.interviewLink,
          interviewTitle: item.interviewTitle,
        }))
        : [{interviewLink: '', interviewTitle: ''}] // props.data가 없거나 빈 배열인 경우 기본값 추가
);

const maxItems = 3;
const addListItem = () => {
  if (internalData.length >= maxItems) {
    alert(`최대 ${maxItems}개까지 등록 가능합니다`);
    return;
  }
  internalData.push({interviewLink: '', interviewTitle: ''});
};
const removeItem = (type, index) => {
  internalData.splice(index, 1);

  // 데이터가 비어 있으면 기본값 추가
  if (internalData.length === 0) {
    internalData.push({interviewLink: '', interviewTitle: ''});
  }
};

let lastFetchedURL = "";
let timeout = null;

const getTitleFromURL = async (index) => {
  const url = internalData[index].interviewLink;

  if (!url || !url.startsWith("http") || url === lastFetchedURL) return;
  lastFetchedURL = url;

  try {
    const response = await apiClient.get(`/v1/artists/fetch-title`, { params: { url } });
    internalData[index].interviewTitle = response.data;
  } catch (error) {
    console.error("타이틀을 가져오는 중 오류 발생:", error);
  }
};

// 배포하고 사용시 js 기다리지 않음. 수동으로 사용자가 작성하도록 변경.
// watch(
//     () => internalData.map((item) => item.interviewLink),
//     (newLinks, oldLinks) => {
//       newLinks.forEach((link, index) => {
//         if (link && link !== oldLinks[index]) {
//           clearTimeout(timeout); // 기존 요청 취소
//           timeout = setTimeout(() => getTitleFromURL(index), 1000);
//         }
//       });
//     }
// );

const getData = () => {
  return Array.from(internalData)
  .filter((item) => item.interviewLink) // interviewLink가 있는 항목만 필터링
  .map((item) => ({
    interviewLink: item.interviewLink,
    interviewTitle: item.interviewTitle,
  }));
};

defineExpose({
  getData
});
</script>

<style scoped>
.title-box {
  flex: 7;
}

.link-box {
  flex: 3;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

</style>