<template>
  <div class="content-panel wd100">
    <div class="my-artartist">
      <div class="my-artwork my-favorite">
        <div class="title-wrap">
          <h2 ref="SeeMoreTopRef" class="section-title">ARTWORK</h2>
        </div>

        <ul v-if="artWorks && artWorks.length > 0" class="favorite artwork">
          <transition-group name="fade">
            <li v-for="(artWork, index) in artWorks" :key="artWork.uuid">
              <img :src="artWork.imageUrl" :alt="artWork.title || artWork.titleEn" class="artWorkImg">
              <div class="mask">
                <p>{{ artWork.title || artWork.titleEn}}<br/>{{ artWork.year }}</p>
              </div>
              <HeartButton v-model:isLike="artWork.isLike" :uuid="artWork.uuid" :likeType="artWork.likeType" />
              <ShareButton :artist-user-account-id="artWork.artistUserAccountId" :uuid="artWork.uuid"/>
              <button type="button" class="btn-more" @click="artWorkModalOpen(index)">See More</button>
            </li>
          </transition-group>
        </ul>
        <SeeMoreButton
            :totalPages="totalPages"
            :currentPage="currentPage"
            :fetchItems="fetchArtWorks"
            :itemRef="SeeMoreTopRef"
        />

      </div>
    </div>
  </div>
  <ArtWorksModalSwiper
      v-model:isArtWorkModalOpen="isModalOpen"
      v-model:artWorks="artWorks"
      :slideIndex="artWorksSlideIndex"
      location="favoriteArtwork"
      :totalPages="totalPages"
      :currentPage="currentPage"
      :fetchItems="fetchArtWorks"
  />
</template>

<script setup>
import { onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {apiClient} from "@/services/auth-header";
import HeartButton from "@/components/ui/HeartButton.vue";
import ShareButton from "@/components/ui/ShareButton.vue";
import ArtWorksModalSwiper from "@/components/ui/ArtWorksModalSwiper.vue";
import {useUiStore} from "@/store/useUiStore";
import SeeMoreButton from "@/components/ui/SeeMoreButton.vue";
const uiStore = useUiStore();

const artWorks = ref([]);
const route = useRoute();

const likeType = ref("ART_WORK");
const currentPage = ref(Number(route.query.page || 0));
const size = ref(20);
const totalPages = ref(0);
const isFetching = ref(false);
const isModalOpen = ref(false);
const artWorksSlideIndex = ref(-1);
const SeeMoreTopRef = ref(null);

const artWorkModalOpen = (index) => {
  artWorksSlideIndex.value = index
  isModalOpen.value = true;
}

watch(()=> isModalOpen.value, (newVal) => {
  if(newVal) {
    uiStore.showOverlay();
  }else{
    uiStore.hideOverlay();
  }
})

const fetchArtWorks = async (page = 0, append = false) => {
  if (isFetching.value) {
    return;
  }
  isFetching.value = true;

  try {
    uiStore.showLoading();
    const response = await apiClient.get(`/v1/users/me/likes?likeType=${likeType.value}&page=${page}&size=${size.value}`);
    const newData = response.data.content;
    if (append) {
      artWorks.value.push(...newData);
    } else {
      artWorks.value = newData;
    }

    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  } finally {
    isFetching.value = false;
    uiStore.hideLoading();
  }
};

onMounted(async () => {
  await fetchArtWorks(currentPage.value);
});

</script>

<style scoped>
ul.favorite li{
  aspect-ratio: 1 / 1;
  object-fit: cover;
}
ul.favorite li img.artWorkImg {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>