<template>
  <button type="button" class="btn-heart" :class="{ on: isLike }"
          @click.stop.prevent="toggleHeart"
          @mousedown="scaleDown"
          @mouseup="scaleUp"
          @mouseleave="resetScale">
    <svg v-if="heartLocation  === 'card'" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
          d="M19.4578 3.28264C18.9691 2.75712 18.3889 2.34024 17.7503 2.05582C17.1117 1.77139 16.4272 1.625 15.7359 1.625C15.0446 1.625 14.3601 1.77139 13.7215 2.05582C13.0829 2.34024 12.5026 2.75712 12.0139 3.28264L10.9997 4.37276L9.98554 3.28264C8.99842 2.22162 7.6596 1.62555 6.26361 1.62555C4.86761 1.62555 3.52879 2.22162 2.54168 3.28264C1.55456 4.34365 1 5.78269 1 7.28319C1 8.7837 1.55456 10.2227 2.54168 11.2838L3.55588 12.3739L10.9997 20.375L18.4436 12.3739L19.4578 11.2838C19.9467 10.7585 20.3346 10.1348 20.5992 9.44839C20.8638 8.76196 21 8.02622 21 7.28319C21 6.54017 20.8638 5.80443 20.5992 5.118C20.3346 4.43157 19.9467 3.80791 19.4578 3.28264Z"
          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 19 19" fill="none">
      <path
          d="M16.6178 3.0062C16.2066 2.56398 15.7184 2.21319 15.181 1.97385C14.6436 1.73452 14.0676 1.61133 13.4859 1.61133C12.9042 1.61133 12.3282 1.73452 11.7908 1.97385C11.2534 2.21319 10.7652 2.56398 10.354 3.0062L9.50054 3.92352L8.6471 3.0062C7.81646 2.11337 6.68987 1.61179 5.51517 1.61179C4.34046 1.61179 3.21387 2.11337 2.38323 3.0062C1.55259 3.89902 1.08594 5.10995 1.08594 6.37259C1.08594 7.63524 1.55259 8.84617 2.38323 9.73899L3.23666 10.6563L9.50054 17.3891L15.7644 10.6563L16.6178 9.73899C17.0293 9.29698 17.3556 8.77218 17.5783 8.19456C17.801 7.61695 17.9156 6.99783 17.9156 6.37259C17.9156 5.74736 17.801 5.12824 17.5783 4.55063C17.3556 3.97301 17.0293 3.44821 16.6178 3.0062Z"
          stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>

</template>


<script setup>
import {ref, defineProps, defineEmits} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useAuthStore} from "@/store/auth.module";

const props = defineProps({
  isLike: Boolean,
  uuid: String,
  likeType: String,
  heartLocation: {type: String, default: 'card'},
});

// const isLiked = ref(props.isLike); // 내부 상태
const authStore = useAuthStore();
const emit = defineEmits(["update:isLike"]);

const toggleHeart = async () => {
  if (!authStore.status.loggedIn) {
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  if (props.likeType && props.uuid) {
    try {
      if (!props.isLike) {
        await apiClient.post('/v1/users/me/likes', {
          type: props.likeType,
          uuid: props.uuid,
        });
      } else {
        await apiClient.delete('/v1/users/me/likes', {
          data: {
            type: props.likeType,
            uuid: props.uuid,
          },
        });
      }
      //isLiked.value = !isLiked.value; // 모달에서 부모로 넘겨줘야해서 변경: v-model 사용.
      emit('update:isLike', !props.isLike);  // 부모로 값 변경 알리기
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  }
};

const scale = ref(1);
const rate = 0.2;
const scaleDown = () => {
  scale.value = 1 - rate;
};

const scaleUp = () => {
  scale.value = 1 + rate;
  setTimeout(() => {
    scale.value = 1;
  }, 150);
};

const resetScale = () => {
  setTimeout(() => {
    scale.value = 1;
  }, 150);
};

</script>

<style scoped>
/**/
@media (hover: hover) {
  .btn-heart:hover svg {
    transition: transform 0.3s ease-out;
    transform: scale(1.1);
  }
}

.btn-heart {
  transition: transform 0.3s ease-out;
  transform: scale(v-bind(scale));
  min-width: 22px;
  min-height: 22px;
  aspect-ratio: 1;
}

.btn-heart.on svg path {
  fill: #FF5C00;
  stroke: #FF5C00;
}

/* .heart-btn {
  background: none;
  border: none;
  cursor: pointer;
  width: 32px; 
}

.heart-btn.on svg path {
  fill: #FF5C00;
  stroke: none;
} */
</style>
