<template>
  <!-- s::MAIN -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <main v-else>
    <div v-if="homeMainBanner" class="main-text" @click="goToUrl(homeMainBanner.linkUrl)"
         :style="{ cursor: homeMainBanner.linkUrl ? 'pointer' : 'default' }">
      <template v-if="homeMainBanner.mediaType === 'MP4'">
        <video autoplay muted loop playsinline @loadeddata="onImageLoaded" style="height: 100%;object-fit: contain; ">
          <source :src="homeMainBanner.imageUrl" type="video/mp4">
        </video>
      </template>
      <template v-else>
        <img :src="homeMainBanner.imageUrl" @load="onImageLoaded" alt="Banner Image" style="width: 100%; height: 100%; object-fit: contain;">
      </template>
    </div>

    <section v-if="artists.length" class="section01">
      <div class="art-box">
        <div class="title-box">
          <h2>ART & ARTISTS</h2>
          <div class="button-box">
            <router-link :to="{ name: 'ArtistList' }">See More</router-link>
          </div>
        </div>

        <div class="slide-container">
          <div class="slide-title">
            <h3>Artists of The Month</h3>
          </div>
          <div class="slide-box" :class="isMobile ? 'mo' : 'pc'">  <!-- class: pc, mo-->
            <div class="tab-cont" v-for="(artist, index) in artists" :key="artist.uuid">
              <div class="inner">
                <div v-if="!isMobile" class="artists" @click="goToArtistDetail(artist.userAccountId)">
                  <div class="artists-txt">
                    <h3>
                      {{ artist.nameEn }}
                      <HeartButton v-model:isLike="artist.isLike" :uuid="artist.uuid" :likeType="artist.likeType"/>
                    </h3>
                    <p>{{ artist.nameKo }}</p>
                  </div>
                  <div class="img-box">
                    <figure>
                      <img :src="artist.imageUrl" alt="">
                    </figure>
                  </div>
                </div>

                <div class="slide-wrap">
                  <!-- Swiper -->
                  <div class="swiper " :class="'mySwiper-' + index">
                    <div class="swiper-wrapper">
                      <div v-if="isMobile" class="swiper-slide">
                        <div class="artists" @click="goToArtistDetail(artist.userAccountId)">
                          <div class="artists-txt">
                            <h3>
                              {{ artist.nameEn }}
                              <HeartButton v-model:isLike="artist.isLike" :uuid="artist.uuid" :likeType="artist.likeType"/>
                            </h3>
                            <p>{{ artist.nameKo }}</p>
                          </div>
                          <div class="img-box">
                            <figure>
                              <img :src="artist.imageUrl" alt="">
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div class="swiper-slide" v-for="artWork in artist.artWorks" :key="artWork.uuid">
                        <router-link
                            :to="{ name: 'ArtistDetail', params: { userAccountId: artist.userAccountId }, query: { artwork: artWork.uuid } }">
                          <img :src="artWork.imageUrl">
                          <div class="txt">
                            <h4>{{ artWork.title }}</h4>
                            <p>{{ artWork.material }}</p>
                            <span>{{ artWork.year }}</span>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="homeSubBanner" class="section02">
      <div v-if="homeSubBanner" class="visual-wrap">
        <figure @click="goToUrl(homeSubBanner.linkUrl)" :style="{ cursor: homeSubBanner.linkUrl ? 'pointer' : 'default' }">
          <template v-if="homeSubBanner.mediaType === 'MP4'">
            <video autoplay muted loop playsinline>
              <source :src="homeSubBanner.imageUrl" type="video/mp4">
            </video>
          </template>
          <template v-else>
            <img :src="homeSubBanner.imageUrl" alt="Banner Image">
          </template>
        </figure>
      </div>
    </section>

    <section v-if="articleSpecial" class="section03">
      <div class="article-title">
        <h3>ARTICLE</h3>
        <ul>
          <li>
            <router-link :to="{ name: 'Special' }">SPECIAL</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Exhibition' }">EXHIBITION</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Event' }">EVENT</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'News' }">NEWS</router-link>
          </li>
        </ul>
      </div>

      <router-link v-if="articleSpecial && articleSpecial.uuid" :to="{ name: getRouteNameByValue(articleSpecial.likeType), params: { uuid: articleSpecial.uuid } }">
        <div class="article-wrap">
          <figure><img :src="articleSpecial.previewImageUrl" alt=""></figure>
          <div class="article-txt">
            <div class="sub-title">
              <span>{{ articleSpecial.likeType }}</span>
              <HeartButton v-model:isLike="articleSpecial.isLike" :uuid="articleSpecial.uuid" :likeType="articleSpecial.likeType"/>
            </div>
            <div class="explaint-txt">
              <div class="artists-txt">
                <h4>{{ articleSpecial.subTitle }}</h4>
                <span class="sub">{{ articleSpecial.title }}</span>
              </div>
              <p>{{ articleSpecial.homeContent }}</p>
            </div>
          </div>
        </div>
      </router-link>
    </section>

    <section v-if="articles.length" class="section04">
      <div class="swiper mySwiper4">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="article in articles" :key="article.uuid">
            <router-link :to="{ name: getRouteNameByValue(article.likeType), params: { uuid: article.uuid } }">
              <figure><img :src="article.previewImageUrl || article.imageUrl" alt=""></figure>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
  <!-- e::MAIN -->
</template>

<script setup>
import {computed, nextTick, onMounted, ref} from 'vue';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import {apiClient} from '@/services/auth-header';
import router from "@/router";
import {useAuthStore} from '@/store/auth.module';
import {useUnreadStore} from '@/store/unread.module';
import HeartButton from "@/components/ui/HeartButton.vue";
import {ARTICLE_LIKE_TYPE} from '@/common/common-enum';
import {useDeviceStore} from "@/store/deviceStore";
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();

const deviceStore = useDeviceStore();
const isMobile = computed(() => deviceStore.isMobile);

const authStore = useAuthStore();
const unreadStore = useUnreadStore();

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const artists = ref([]);
const homeMainBanner = ref(null);
const homeSubBanner = ref(null);
const articles = ref([]);
const articleSpecial = ref(null);
const activeTab = ref(1);
let tempItems = null;

const goToUrl = (linkUrl = false) => {
  if (linkUrl) {
    const url = linkUrl.startsWith('http')
        ? linkUrl
        : 'https://' + linkUrl;
    window.open(url, '_blank');
  }
};

const onImageLoaded = () => {
  nextTick(() => {
    handleDataLoad();
  });
};

const handleDataLoad = async () => {
  if (tempItems) {
    // 메인 베너 후 나머지 데이터를 셋팅 (순차x 필요시 수정)
    artists.value = await getArtists();
    homeSubBanner.value = await getHomeSubBanner();
    articleSpecial.value = await getArticleSpecial();
    articles.value = await getArticles();
    await initSwiper();
    tempItems = null;
  }
};

const getArtists = async () => {
  return tempItems.artists;
};

const getHomeSubBanner = async () => {
  return tempItems.homeSubBanner;
};

const getArticleSpecial = async () => {
  return tempItems.articleSpecial;
};

const getArticles = async () => {
  return tempItems.articles;
};

const getRouteNameByValue = (value) => {
  const item = ARTICLE_LIKE_TYPE.find(item => item.value === value);
  return item ? item.routeName : null;
};

const goToArtistDetail = (userAccountId) => {
  // <router-link :to="{ name: 'ArtistDetail', params: { userAccountId: artist.userAccountId } }"> 로 사용시  css 깨짐.
  router.push({name: 'ArtistDetail', params: {userAccountId}});  // 라우터로 이동
};

// Swiper 초기화 함수
const initSwiper = async () => {
  await nextTick();
  artists.value.forEach((artist, index) => {
    const swiper = new Swiper(`.mySwiper-${index}`, {
      observer: true,
      observeParents: true,
      spaceBetween: 18,
      slidesPerView: 'auto',
      autoResize: false,
      loop: true,
      freeMode: true,
      breakpoints: {
        375: {
          spaceBetween: 11,
        },
      },
      autoplay: !isMobile.value
          ? {
            delay: 1000 * (artists.value.length),  // 모든 Swiper의 딜레이는 동일
            disableOnInteraction: false
          }
          : false,
    });

    // autoplay 시작 시간을 개별적으로 다르게 설정
    if (!isMobile.value) {
      swiper.autoplay.stop(); // 처음에는 autoplay 멈춤
      setTimeout(() => {
        swiper.autoplay.start(); // 일정 시간 후 autoplay 시작
      }, index * 1000); // 각 Swiper의 시작 시간을 다르게 설정 (1초 간격)
    }
  });

  new Swiper(".mySwiper4", {
    observer: true,
    observeParents: true,
    spaceBetween: 18,
    slidesPerView: 'auto',
    autoResize: false,
    loop: true,
    freeMode: false,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
  });
};

// 탭 변경 함수
const setActiveTab = (tabId) => {
  activeTab.value = tabId;
  initSwiper(); // 탭 변경 시 Swiper 초기화
};

const loadItems = async () => {
  try {
    uiStore.showLoading();
    const response = await apiClient.get('/v1/home');
    homeMainBanner.value = response.data.homeMainBanner;
    tempItems = response.data;
    // localStorage.setItem("menu", JSON.stringify(response.data.menu));
  } catch (err) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    uiStore.hideLoading();
  }
};

onMounted(async () => {
  await loadItems();
  await unreadStore.fetchAllUnreadCounts();
});

</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 비디오가 비율에 맞춰 크기 맞추기 */
}

.section04 .mySwiper4 .swiper-wrapper .swiper-slide figure img {
  width: 100%;
  aspect-ratio: 287.73 / 394.6;
  /* object-fit: cover; */
  border-radius: 20px;
}

/*.video-container {
  display: flex;
  justify-content: center;
}

video {
  width: 1200px;
}
.section02 .layout li {
  cursor: pointer;
}*/
</style>