<template>
  <HeaderSection />
  <article :id="($route.name !== 'Home') ? 'pages' : ''">
    <router-view :key="$route.fullPath" />
  </article>
  <FloatingMenuBox />
  <FooterSection />
  <Login v-if="loginMode !== 'none'" />
  <GlobalOverlay/>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import HeaderSection from '@/components/layout/Header.vue';
import FooterSection from '@/components/layout/Footer.vue';
import FloatingMenuBox from '@/components/layout/FloatingMenuBox.vue';
import Login from '@/components/auth/Login.vue';
import GlobalOverlay from "@/components/ui/GlobalOverlay.vue";
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();
const loginMode = computed(() => uiStore.loginMode);

// import { useStore } from 'vuex';
// const store = useStore();
// const currentUser = computed(() => store.state.auth.user);
// const showArtistBoard = computed(() => {
//   return currentUser.value && currentUser.value.roles && currentUser.value.roles.includes('ROLE_ARTIST');
// });

const redirectIfWww = () => {
  const host = window.location.hostname;
  const url = window.location.href;

  if (host.startsWith('www.')) {
    const newUrl = url.replace('://www.', '://');
    window.location.replace(newUrl);
  }
};

onMounted(() => {
  redirectIfWww();
});
</script>

<style>
/* 퍼블 끝나면 적용하기기 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 임시 스피너 */
.btn-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid #F7BE17;
  border-top: 3px solid #ffffff00;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-check {
  font-size: 24px;
  color: #F7BE17;
  font-weight: bold;
}

.bbs .bbs-view .view-content p {
  white-space: pre-line;
}

/* 글 내용 많은곳 적용하기 */
.white-space-pre-line {
  white-space: pre-line;      /* 줄바꿈 유지 */
  word-wrap: break-word;      /* 긴 단어 줄바꿈 */
  word-break: keep-all;       /* 단어 중간에서 줄바꿈하지 않음 */
  text-align: justify;        /* 양쪽 정렬 */
}

/* 모바일 화면에 적용할 스타일 */
@media (max-width: 460px) {
  .white-space-pre-line {
    word-break: break-all !important;   /* 모바일에서 단어 중간에서 줄바꿈 */
    line-height: 1.8 !important;
  }
}
</style>
