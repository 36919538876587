import Special from '@/views/article/Special.vue';
import Exhibition from '@/views/article/Exhibition.vue';
import ExhibitionDetail from '@/views/article/ExhibitionDetail.vue';
import Event from '@/views/article/Event.vue';
import News from '@/views/article/News.vue';
import EventDetail from "@/views/article/EventDetail.vue";
import NewsDetail from "@/views/article/NewsDetail.vue";
import SpecialDetail from "@/views/article/SpecialDetail.vue";
import Article from "@/views/article/Article.vue";

export const articleRoutes = [

    {
        path: '/article',
        name: 'Article',
        redirect: '/article/special',
        children: [
            {
                path: '/article/special',
                name: 'Special',
                component: Special,
                props: true,
                // meta: {
                //     title: 'Article21 | special',
                //     description: 'Article21의 특별 기사 목록입니다.',
                //     keywords: '아티스트, 작품, 특별 기사'
                // }
            },
            {
                path: '/article/special/:uuid',
                name: 'SpecialDetail',
                component: SpecialDetail,
                props: true
            },
            {
                path: '/article/special/share/:uuid/:subTitle',
                name: 'SpecialDetailCode',
                component: SpecialDetail,
                props: true
            },
            {
                path: '/article/exhibition',
                name: 'Exhibition',
                component: Article,
                props: true
            },
            {
                path: '/article/exhibition/:uuid',
                name: 'ExhibitionDetail',
                component: ExhibitionDetail,
                props: true
            },
            {
                path: '/article/event',
                name: 'Event',
                component: Article,
                props: true
            },
            {
                path: '/article/event/:uuid',
                name: 'EventDetail',
                component: EventDetail,
                props: true
            },
            {
                path: '/article/news',
                name: 'News',
                component: Article,
                props: true
            },
            {
                path: '/article/news/:uuid',
                name: 'NewsDetail',
                component: NewsDetail,
                props: true
            },
        ]
    },

];