<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="customer">
                <div class="bbs">
                  <section v-if="notice">
                    <div class="title-wrap">
                                        <h2 class="section-title">공지사항</h2>
                                    </div>
                    <div class="bbs-view">
                      <h3>{{ notice.title }}</h3>
                      <div class="util">
                        <span></span>
                        <span>{{ notice.createdBy }}</span>
                        <span>{{ notice.createdAt }}</span>
                      </div>
                      <div class="view-content">
                        <p class="white-space-pre-line">{{ notice.content }}</p>
                      </div>
                      <div class="button-box">
                        <button type="button" @click="goList" >목록으로</button>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
<!--  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--  </button>-->
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";
import {useRouter, useRoute} from "vue-router";
import { useUnreadStore } from '@/store/unread.module';
import {useUiStore} from "@/store/useUiStore";
const uiStore = useUiStore();

const unreadStore = useUnreadStore();
const route = useRoute();
const router = useRouter();

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const noticeUuid = ref(route.params.uuid);
const API_URL = "/v1/support"
const notice = ref(null);

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const goList = () => {
  if(history.state.back) {
    router.back();
  }
  else {
    // const basePath = route.path.split('/').slice(0, 4).join('/');
    // router.push({path: basePath});
    router.push({name: 'Notice'});
  }
};


const loadItem = async () => {
  try {
    uiStore.showLoading();
    const response = await apiClient.get(`${API_URL}/notices/${noticeUuid.value}`);
    notice.value = response.data;
  } catch (error) {
    console.error("Error fetching NoticeDetail data:", error);
  }finally {
    loading.value = false;
    if(!notice.value.isRead){
      await markAsRead();
    }
    await unreadStore.fetchUnreadNoticesCount();
    uiStore.hideLoading();
  }
};

const markAsRead = async () => {
  try {
    const response = await apiClient.post(`${API_URL}/notices/${noticeUuid.value}/read`);
  } catch (error) {
    console.error('Error marking as read:', error);
  }
};


onMounted(() => {
  loadItem();
});
</script>
