<template>
  <div class="tab-content" id="content1">
    <div class="tab-inner">
      <div class="input-wrap">
        <span>제목</span>
        <input type="text" v-model="inquiry.title" class="input-cs" placeholder="제목을 넣어주세요.">
      </div>
      <div class="input-wrap">
        <span>내용</span>
        <textarea v-model="inquiry.content" placeholder="문의 사항을 알려주세요."></textarea>
      </div>
      <div class="input-wrap">
        <span>첨부파일</span>
        <div class="file-box">
          <label for="fileInput" class="file-label">
            <span>파일</span>
          </label>
          <input type="file" id="fileInput" class="file-input" @change="loadFile" accept="image/*"
                 multiple />
          <div class="file-txt">
            <span class="file-name">관련 이미지를 첨부해주세요.</span>
            <span class="file-sub">10MB 이하 / PDF, JPG, PNG 형식</span>
          </div>
        </div>
        <div class="file-names">
          <template v-for="(file, index) in selectedFiles" :key="index">
            <div class="file-name" :class="'file-item-' + index">
              <span>{{ file.name }}</span>
              <a @click="deleteFile(file.name, index)">
                <img src="@/assets/images/icon/ico_close_gray.svg"
                     style="width: 10px; padding-left: 5px" alt="" />
              </a>
            </div>
          </template>
        </div>
      </div>
      <div class="input-wrap">
        <span>이메일</span>
        <input type="email" v-model="inquiry.email" class="input-cs" placeholder="abc@article21.co.kr">
      </div>
      <div class="input-wrap">
        <span>전화번호</span>
        <input type="tel" v-model="inquiry.phoneNum"  class="input-cs" placeholder="010-0000-0000">
      </div>
      <div v-if="!isLogin" class="input-wrap">
        <span class="checkbox-type3">
          <input v-model="isChecked" type="checkbox" id="privacy">
          <label for="privacy">
            <span>개인정보 수집 및 이용 동의</span>
            <span class="sub" :style="!isChecked ? 'color: red':''">문의하신 내용에 대한 답변을 받으시려면 동의해주세요.</span>
          </label>
        </span>
      </div>
      <div class="input-wrap">
        <button type="button" @click="saveInquiry(true)" :disabled="!isChecked">
          <span>보내기</span>
          <img src="@/assets/images/icon/ico_send.svg" alt="">
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {inquiryFunction} from "@/composables/inquiryFunction";

const { isLogin, inquiry, selectedFiles, selectedCategory, loadProfile, saveInquiry, loadFile, deleteFile }= inquiryFunction();
const isChecked = ref(isLogin || false); // 체크 상태

onMounted(() => {
  loadProfile();
  selectedCategory("INQUIRY");
});
</script>

<style scoped>
button:disabled {
  background-color: #ddd;
  color: #999;
  cursor: not-allowed;
  opacity: 0.6;
}
</style>