<template>
  <a v-if="location === 'card'" class="btn-share" @click="handleShare(artistUserAccountId,uuid)" @mousedown="scaleDown"
     @mouseup="scaleUp"
     @mouseleave="resetScale">
    <img src="@/assets/images/icon/ico_share_w2.svg" alt="">
  </a>
  <button v-else-if="location === 'button'" type="button" class="btn-share" @click="handleShare(artistUserAccountId,uuid)" @mousedown="scaleDown"
          @mouseup="scaleUp"
          @mouseleave="resetScale">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 25 25" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.6012 6.79974C21.9268 6.79974 23.0012 5.72522 23.0012 4.39987C23.0012 3.07452 21.9268 2 20.6012 2C19.2756 2 18.2012 3.07452 18.2012 4.39987C18.2012 5.72522 19.2756 6.79974 20.6012 6.79974ZM20.6012 8.29974C22.7551 8.29974 24.5012 6.55371 24.5012 4.39987C24.5012 2.24603 22.7551 0.5 20.6012 0.5C18.4473 0.5 16.7012 2.24603 16.7012 4.39987C16.7012 4.63308 16.7216 4.86152 16.7609 5.08347L7.68878 10.2379C6.99604 9.07044 5.78197 8.29641 4.40003 8.29641C2.2461 8.29641 0.5 10.1767 0.5 12.4963C0.5 14.8158 2.2461 16.6961 4.40003 16.6961C5.73973 16.6961 6.92167 15.9687 7.62381 14.8606L16.741 20.0406C16.7148 20.2233 16.7012 20.4101 16.7012 20.6001C16.7012 22.754 18.4473 24.5 20.6012 24.5C22.7551 24.5 24.5012 22.754 24.5012 20.6001C24.5012 18.4463 22.7551 16.7003 20.6012 16.7003C19.1766 16.7003 17.9304 17.4641 17.2498 18.6045L8.1967 13.4609C8.2643 13.1512 8.30006 12.8282 8.30006 12.4963C8.30006 12.2098 8.27343 11.9301 8.22269 11.6597L17.3117 6.49574C18.0043 7.58046 19.2187 8.29974 20.6012 8.29974ZM20.6012 23C21.9268 23 23.0012 21.9255 23.0012 20.6001C23.0012 19.2748 21.9268 18.2003 20.6012 18.2003C19.2756 18.2003 18.2012 19.2748 18.2012 20.6001C18.2012 21.9255 19.2756 23 20.6012 23ZM6.80006 12.4963C6.80006 14.0939 5.62284 15.1961 4.40003 15.1961C3.17722 15.1961 2 14.0939 2 12.4963C2 10.8986 3.17722 9.79641 4.40003 9.79641C5.62284 9.79641 6.80006 10.8986 6.80006 12.4963Z"
            fill="white"/>
    </svg>
  </button>
  <button v-else-if="location === 'artist'" type="button" class="btn" @click="handleShare(artistUserAccountId,uuid)">
    <span>페이지 공유하기</span>
    <img src="@/assets/images/icon/ico_share_b2.svg" alt="">
  </button>
</template>

<script setup>
import {defineProps, ref} from 'vue';

const props = defineProps({
  artistUserAccountId: {type: String, default: '', required: false,},
  uuid: {type: String, default: '', required: false,},
  location: {type: String, default: 'card'},
});

const handleShare = (artistUserAccountId, uuid) => {
  let url = window.location.origin;

  if (artistUserAccountId) {
    url += `/artist/${artistUserAccountId}`;
  }else{
    url += window.location.pathname
  }

  if (uuid) {
    const params = new URLSearchParams(window.location.search);
    params.set('artwork', uuid);  // artwork 파라미터 추가
    url += `?${params.toString()}`;
  }

  navigator.clipboard.writeText(url).then(() => {
    alert('공유 링크가 복사되었습니다.');
  }).catch((err) => {
    alert('URL 복사 실패: ' + err);
  });
};

const scale = ref(1);
const rate = 0; //alert 변경하고 적용
const scaleDown = () => {
  scale.value = 1 - rate;
};

const scaleUp = () => {
  scale.value = 1 + rate;
  setTimeout(() => {
    scale.value = 1;
  }, 150);
};

const resetScale = () => {
  setTimeout(() => {
    scale.value = 1;
  }, 150);
};
</script>

<style scoped>
@media (hover: hover) {
  .btn-share:hover {
    transition: transform 0.3s ease-out;
    transform: scale(1.1);
  }
}

.btn-share {
  transition: transform 0.3s ease-out;
  transform: scale(v-bind(scale));
}

</style>