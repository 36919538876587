export const ARTIST_INTERESTS = [
  {value: 'PROMOTION', label: '아티스트 및 작품 홍보'},
  {value: 'ARCHIVING', label: '아카이빙 및 포트폴리오 기능 이용'},
  {value: 'SELLING', label: '직접 제작한 아트상품 판매'},
  {value: 'INFORMATION', label: '예술 관련 정보 확인'},
  {value: 'COMMUNITY', label: '커뮤니티 활동'},
  {value: 'COMMERCIAL_OPPORTUNITIES', label: '상업적 기회(협업 등)'},
  {value: 'ETC', label: '기타(직접 입력)'},
];

export const USER_INTERESTS = [
  {value: 'ARTIST_INFO', label: '아티스트 및 작품 정보'},
  {value: 'COMMUNITY', label: '커뮤니티 활동'},
  {value: 'ART_INFO', label: '예술 관련 정보'},
  {value: 'BUYING', label: '아티스트 상품 구매'},
  {value: 'ETC', label: '기타(직접 입력)'},
];

export const USER_ROLE = [
  {value: 'ARTIST', label: '아티스트'},
  {value: 'MEMBER', label: '일반'},
  {value: 'ARTIST_PENDING', label: '아티스트 승인 대기'},
];

export const ARTIST_LIST_SORT_OPTIONS = [
  { value: "createdAt", direction: "DESC", label: "최신순" },
  { value: "nameKo", direction: "ASC", label: "이름순" },
];

export const ARTIST_EDUCATIONS = [
  {value: 'UNDERGRADUATE', label: '학부', text1: '학교명', text2: '학과'},
  {value: 'GRADUATE', label: '대학원', text1: '학교명', text2: '학과'},
  {value: 'ETC', label: '기타', text1: '교육 기관명', text2: '교육 과정'},
];

export const ARTIST_EDUCATIONS_STATUS = [
  {value: 'GRADUATED', label: '졸업'},
  {value: 'COMPLETED', label: '수료'},
  {value: 'ENROLLED', label: '재학'},
];

export const ARTIST_EXPERIENCES = [
  {value: 'SOLO_EXHIBITION', label: '개인전', text1: '전시명', text2: '장소'},
  {value: 'GROUP_EXHIBITION', label: '그룹전', text1: '전시명', text2: '장소'},
  {value: 'RESIDENCY', label: '레지던시', text1: '레지던시명', text2: '기관'},
  {value: 'WORKSHOP', label: '워크숍', text1: '워크숍명', text2: '장소'},
  {value: 'GRANT', label: '지원사업', text1: '사업명', text2: '장소'},
];

export const ARTIST_ACTIVITY_GENRES = [
  {value: 'WESTERN_PAINTING', label: '서양화'},
  {value: 'KOREAN_PAINTING', label: '한국화'},
  {value: 'SCULPTURE', label: '조소'},
  {value: 'INSTALLATION', label: '설치'},
  {value: 'MEDIA', label: '미디어'},
  {value: 'PHOTOGRAPHY', label: '사진'},
  {value: 'ILLUSTRATION', label: '일러스트'},
  {value: 'ETC', label: '기타'},
];

export const ARTIST_ARTWORK_THEME_SUBJECT = [
  {value: 'NATURE', label: '자연'},
  {value: 'PEOPLE', label: '사람'},
  {value: 'OBJECT', label: '물건'},
];

export const ARTIST_ARTWORK_THEME_TIME = [
  {value: 'PAST', label: '과거'},
  {value: 'PRESENT', label: '현재'},
  {value: 'FUTURE', label: '미래'},
];

export const ARTIST_ARTWORK_THEME_FOCUS = [
  {value: 'AESTHETICS', label: '아름다움'},
  {value: 'SOCIAL_MESSAGE', label: '사회적 메시지'},
  {value: 'POLITICAL_MESSAGE', label: '정치적 메시지'},
  {value: 'PERSONAL_STORY', label: '개인적 이야기'},
];

export const ARTIST_ARTWORK_THEME_STYLE = [
  {value: 'CONCRETE', label: '구체적'},
  {value: 'ABSTRACT', label: '추상적'},
];

export const ARTIST_ARTICLE_NEWS_TYPE = [
  {value: 'COMPETITION', label: '공모'},
  {value: 'ETC', label: '기타'},
];

export const INQUIRY_TYPE = [
  {value: 'INQUIRY', label: '문의 사항'},
  {value: 'CONTENT_POST_REQUEST', label: '콘텐츠 게시 요청'},
  {value: 'USER_ROLE_TYPE_CHANGE', label: '회원 유형 전환'},
];

export const ARTICLE_LIKE_TYPE = [
  { value: 'SPECIAL', title: 'SPECIAL', class: 'list-type3 special', routeName: 'SpecialDetail', size: 4 },
  { value: 'EXHIBITION', title: 'EXHIBITION', class: 'list-type1 favorite', routeName: 'ExhibitionDetail', size: 8 },
  { value: 'EVENT', title: 'EVENT', class: 'list-type1 favorite', routeName: 'EventDetail', size: 8 },
  { value: 'NEWS', title: 'NEWS', class: 'list-type1 favorite', routeName: 'NewsDetail', size: 8 },
];

export const ARTICLE_LIST_SORT_OPTIONS = [
  { value: "createdAt", direction: "DESC", label: "최신순" },
];