<template>
  <div class="content-panel wd100">
    <div class="my-artartist">
      <div class="my-artist">
        <div class="title-wrap">
          <h2 ref="SeeMoreTopRef" class="section-title">ART & ARTISTS</h2>
        </div>

        <ul v-if="artists && artists.length > 0" class="my-list">
          <transition-group name="fade">
            <li v-for="artist in artists" :key="artist.uuid">
              <router-link :to="{ name: 'ArtistDetail', params: { userAccountId: artist.userAccountId } }">
                <div class="txt">
                  <h3>{{ artist.nameEn }}</h3>
                  <p>{{ artist.nameKo }}</p>
                </div>
                <div class="img-box">
                  <figure>
                    <img :src="artist.imageUrl" alt=""/>
                  </figure>
                </div>
              </router-link>
              <HeartButton v-model:isLike="artist.isLike" :uuid="artist.uuid" :likeType="likeType"/>
            </li>
          </transition-group>
        </ul>
        <SeeMoreButton
            :totalPages="totalPages"
            :currentPage="currentPage"
            :fetchItems="fetchArtists"
            :itemRef="SeeMoreTopRef"
        />

      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {apiClient} from "@/services/auth-header";
import HeartButton from "@/components/ui/HeartButton.vue";
import {useUiStore} from "@/store/useUiStore";
import SeeMoreButton from "@/components/ui/SeeMoreButton.vue";
const uiStore = useUiStore();

const artists = ref(null);
const route = useRoute();

const likeType = ref("ARTIST");
const currentPage = ref(Number(route.query.page || 0));
const size = ref(20);
const totalPages = ref(0);
const isFetching = ref(false);
const SeeMoreTopRef = ref(null);

const fetchArtists = async (page = 0, append = false) => {
  if (isFetching.value) {
    return;
  }
  isFetching.value = true;

  try {
    uiStore.showLoading();
    const response = await apiClient.get(`/v1/users/me/likes?likeType=${likeType.value}&page=${page}&size=${size.value}`);
    const newData = response.data.content;

    if (append) {
      artists.value.push(...newData);
    } else {
      artists.value = newData;
    }

    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  } finally {
    isFetching.value = false;
    uiStore.hideLoading();
  }
};


onMounted(async () => {
  await fetchArtists(currentPage.value);
});

</script>