<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: ARTICLE -->
    <div class="article">
      <div class="layout">
        <div class="box">
          <ArticleSnb/>
          <div v-if="event" class="content-panel">
            <div class="view-type1">
              <ArticleButtons v-model:isLike="event.isLike" :uuid="event.uuid" :likeType="event.likeType"/>
              <div class="img">
                <img :src="event.imageUrl" alt="">
              </div>
              <div class="info-box">
                <h3 v-html="event.title"/>
                <dl>
                  <dt>기간</dt>
                  <dd>{{ getPeriod() }}</dd>
                  <template v-if="getTime()">
                    <dt>시간</dt>
                    <dd>{{ getTime() }}</dd>
                  </template>
                  <template v-if="event.location">
                    <dt>
                      장소
                      <a @click="openKakaoMap(event.location)">
                        <img src="@/assets/images/icon/ico_map_pin.svg" alt="카카오맵"
                             style="width: clamp(16px, 1.4vw, 18px); cursor: pointer; margin-bottom: -2px;"/>
                      </a>
                    </dt>
                    <dd>{{ event.location }} {{ event.locationDetail }}</dd>
                  </template>
                  <template v-if="event.contactPhoneNum || event.contactEmail">
                    <dt>문의</dt>
                    <dd>{{ event.contactPhoneNum }}</dd>
                    <dd v-if="event.contactEmail">{{ event.contactEmail }}</dd>
                  </template>
                  <template v-if="event.content">
                    <dd>
                      <p class="txt white-space-pre-line">
                        {{ event.content }}
                      </p>
                    </dd>
                  </template>
                  <template v-if="event.originLink">
                    <dt>바로가기</dt>
                    <dd>
                      <a :href="event.originLink" target="_blank" class="link" rel="noopener noreferrer" >{{ event.originLink }}</a>
                    </dd>
                  </template>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {apiClient} from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import ArticleButtons from "@/components/article/ArticleButtons.vue";
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();

const route = useRoute();
const articleUuid = ref(route.params.uuid);
const loading = ref(true);
const error = ref(null);
const event = ref(null);

const getPeriod = () => {
  let period = event.value.periodFrom + ' ~ ' + event.value.periodTo;
  if (event.value.periodMemo) {
    period += ' (' + event.value.periodMemo + ')';
  }
  return period;
}

const getTime = () => {
  let time = '';
  if (event.value.timeFrom) {
    time += event.value.timeFrom;
  }

  if (event.value.timeTo) {
    time += ' ~ ' + event.value.timeTo;
  }

  if (event.value.timeMemo) {
    time += ' (' + event.value.timeMemo + ')';
  }

  return time;
}

const openKakaoMap = (address) => {
  if (!address) {
    return;
  }
  const encodedAddress = encodeURIComponent(address);
  const kakaoMapUrl = `https://map.kakao.com/?q=${encodedAddress}`;
  window.open(kakaoMapUrl, "_blank");
};

onMounted(async () => {
  try {
    uiStore.showLoading();
    const response = await apiClient.get(`/v1/contents/articles/events/${articleUuid.value}`);
    event.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
    uiStore.hideLoading();
  }
});
</script>
