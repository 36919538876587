<template><!-- s::PAGE -->
  <div class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb/>
            </div>
          </div>
          <div class="content-panel">
            <div class="faq">
              <h2 class="page-title">FAQ</h2>
              <div class="box">

                <div class="faq-top">
                  <SearchBar v-model="searchQuery" placeholder="검색어" />

                  <!-- 회원 구분에 따른 FAQ 데이터 표시 -->
                  <div class="faq-tab">
                    <div>
                      <a
                          v-for="(category, index) in filteredFaqData"
                          :key="category.category"
                          :href="'#' + category.category"
                          :class="{'on': activeTab === index}"
                          @click.prevent="changeTab(index)"
                      >
                        {{ category.category }}
                      </a>
                    </div>
                  </div>

                </div>
<!--                <ul class="head">-->
<!--                  <li>-->
<!--                    <div class="col col1">유형</div>-->
<!--                    <div class="col col2">제목</div>-->
<!--                  </li>-->
<!--                </ul>-->

                <!-- 각 대분류의 소분류 내용 표시 -->
                <div v-for="(category, index) in filteredFaqData" :key="category.category" class="tab-content" v-show="activeTab === index">
                  <dl class="toggle-box">
                    <div v-for="(item, i) in category.items" :key="i" class="faq-one">
                      <dt @click="toggleContent(index, i)">
<!--                        <span>{{ item.type }}</span>-->
                        [{{ item.type }}] {{ item.title }}
                      </dt>
                      <dd v-show="activeItem[index] === i" style="white-space: pre-line;">
                        {{ item.content }}
                      </dd>
                    </div>
                  </dl>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
</template>


<script setup>
import {ref, computed, watch} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {faqData} from "@/assets/js/faqData"
import SearchBar from "@/components/ui/SearchBar.vue";
import {useAuthStore} from "@/store/auth.module";

const authStore = useAuthStore();
const user = authStore.status.user;
const isArtist = ref(user.roleType === "ARTIST");

const searchQuery = ref('');

// currentFaqData는 사용자의 역할에 따라 데이터가 달라짐
const currentFaqData = computed(() => {
  if (isArtist.value) {
    // 아티스트인 경우, 공통과 아티스트 데이터를 합쳐서 반환
    return [...faqData.common, ...faqData.artist];
  } else {
    // 공통인 경우, 공통 데이터만 반환
    return faqData.common;
  }
});

// 탭 클릭 시 활성화 상태 변경
const activeTab = ref(0);
const changeTab = (index) => {
  activeTab.value = index;
};

// 내용 펼치기 토글
const activeItem = ref([]);
const toggleContent = (tabIndex, itemIndex) => {
  activeItem.value[tabIndex] = activeItem.value[tabIndex] === itemIndex ? null : itemIndex;
};

const filteredFaqData = computed(() => {
  return currentFaqData.value
  .map(category => {
    const filteredItems = category.items.filter(item =>
        item.title.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
        item.content.toLowerCase().includes(searchQuery.value.toLowerCase())
    );

    // 필터된 항목이 있을 때만 반환
    return filteredItems.length > 0 ? { ...category, items: filteredItems } : null;
  })
  .filter(Boolean); // null인 항목은 제거
});

watch(filteredFaqData, (newData) => {
  if (searchQuery.value) {
    // 검색 시 첫 번째 탭으로 이동
    activeTab.value = newData.length > 0 ? 0 : -1;
  }
});

</script>

<style scoped>


</style>