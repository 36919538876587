<template>
  <!-- s::PAGE -->
  <div class="contents art-artist">
    <!-- s:: ART & ARTIST -->
    <div class="art-artist">
      <div class="list">
        <div class="layout">
          <div class="visual-slide">
            <div class="swiper visualSwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in banners">
                  <template v-if="item.mediaType === 'MP4'">
                    <figure>
                      <video autoplay muted loop playsinline style="width: 100%; height: 100%; object-fit: cover;">
                        <!-- style="width: 100%; height: 100%; object-fit: cover;" -->
                        <source :src="item.imageUrl" type="video/mp4">
                      </video>
                    </figure>
                  </template>
                  <template v-else>
                    <figure><img :src="item.imageUrl" alt=""></figure>
                  </template>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>

          <div ref="artistsRef" class="filter-box">
            <div class="group-box">
              <SearchBar v-model="searchQuery" placeholder="아티스트 이름" @submit="onSearch"/>

              <!-- 정렬 방식 텍스트 -->
              <div class="sort-options">
                <span class="checkbox-type1">
                  <input type="checkbox" id="searchOnlyLike" v-model="searchOnlyLike" @change="toggleSearchOnlyLike">
                  <label for="searchOnlyLike" style="color: #999;">
                    관심 아티스트
                  </label>
                </span>
                <span v-for="(option, index) in ARTIST_LIST_SORT_OPTIONS" :key="index"
                      :class="{ selected: selectedSort.value === option.value }" @click="setSortOption(option)">
                  {{ option.label }}
                </span>
              </div>
            </div>

            <!-- [todo] 자음검색 기능 MVP 에서 일단 제외 추후 적용.
            <div class="sort-box">
              <a v-for="(filter, index) in filters" :key="index" href="#" :class="{ on: selectedIndex === index }"
                @click.prevent="selectFilter(index)">
                {{ filter }}
              </a>
            </div> -->
          </div>

          <ul v-show="artists && artists.length > 0" class="ret-list">
            <li v-for="artist in artists" :key="artist.uuid">
<!--              <router-link :to="{ name: 'ArtistDetail', params: { userAccountId: artist.userAccountId } }" >-->
                <a @click="beforeNavigate(artist.userAccountId)">
                <div class="txt">
                  <h3>{{ artist.nameEn }}</h3>
                  <p>{{ artist.nameKo }}</p>
                </div>
                <div class="img-box">
                  <figure>  <!--  class="arch-image"-->
                    <img :src="artist.imageUrl" :alt="artist.nameKo"/>
                  </figure>
                </div>
                </a>
<!--              </router-link>-->
              <HeartButton v-model:isLike="artist.isLike" :uuid="artist.uuid" likeType="ARTIST"/>
            </li>
          </ul>
          <ul v-show="searchQuery && !(artists && artists.length > 0)">
            <NoSearchResult/>
          </ul>
          <Pagination :current-page="currentPage" :total-pages="totalPages"
                      :visible-count="5" @goToPage="goToPage"/>
          <InfiniteScroll :on-reach-end="moreData" :current-page="currentPage"
                          :total-pages="totalPages" :is-fetching="isFetching"/>

        </div>
      </div>
    </div>
    <!-- e:: ART & ARTIST -->
  </div>
  <!-- e::PAGE -->
</template>
<script setup>
import {onMounted, ref, nextTick} from 'vue';
import {useRoute} from "vue-router";
import router from "@/router";
import {apiClient} from "@/services/auth-header";
import Swiper from "swiper";
import {useAuthStore} from "@/store/auth.module";
import Pagination from "@/components/ui/Pagination.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll.vue";
import {ARTIST_LIST_SORT_OPTIONS} from "@/common/common-enum";
import SearchBar from "@/components/ui/SearchBar.vue";
import HeartButton from "@/components/ui/HeartButton.vue";
import NoSearchResult from "@/components/ui/NoSearchResult.vue";
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();
const route = useRoute();
const authStore = useAuthStore();
const artists = ref([]);
const banners = ref([]);
const mainSwiper = ref();
const selectedSort = ref(ARTIST_LIST_SORT_OPTIONS[0]);
const selectedIndex = ref(0); // 현재 선택된 인덱스
const artistsRef = ref(null);

// 페이지 정보
const searchOnlyLike = ref(route.query.like || false);
const searchQuery = ref(route.query.q || "");
const currentPage = ref(Number(route.query.page || 0));
const size = ref(24);
const totalPages = ref(0);
const isFetching = ref(false);

const filters = ['전체', 'ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];
const selectFilter = (index) => { // 자음 검색 기능
  selectedIndex.value = index; // 선택된 인덱스 변경
  searchQuery.value = index === 0 ? '' : filters[index]; // '전체' 선택 시 빈 문자열, 나머지는 그대로
  //관심 선택 해제해야하면 searchOnlyLike.value = false;
  //param 하나더 넘겨서 초성검색(성만) 인거 구분해야할듯.
  setSortOption(ARTIST_LIST_SORT_OPTIONS[1])
};

const beforeNavigate = async (userAccountId) => {
  await router.push({name: route.name, query: {q: searchQuery.value, like: searchOnlyLike.value, page: currentPage.value, sort: selectedSort.value.value, direction: selectedSort.value.direction}});
  await router.push({ name: 'ArtistDetail', params: { userAccountId } });
};

const setSortOption = (sort) => {
  if (selectedSort.value.value != sort.value) {
    selectedSort.value = sort;
    fetchArtists(0); // 정렬 방식 변경 시 첫 페이지부터 다시 로딩
  }
};

const toggleSearchOnlyLike = () => {
  if (searchOnlyLike && !authStore.status.loggedIn) {
    searchOnlyLike.value = false;
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  fetchArtists(0); // 관심 아티스트 선택 시 첫 페이지부터 다시 로딩
}

async function goToPage(page = 0, append = false) {


  //await router.push({name: route.name, query: {q: searchQuery.value, like: searchOnlyLike.value, page: page, sort: selectedSort.value.value, direction: selectedSort.value.direction}});

  const url = new URL(window.location.href);
  url.searchParams.set("q", searchQuery.value);
  url.searchParams.set("like", searchOnlyLike.value);
  url.searchParams.set("page", page);
  url.searchParams.set("sort", selectedSort.value.value);
  url.searchParams.set("direction", selectedSort.value.direction);

  window.history.pushState({ goToPage: page }, "", url.toString());
  await fetchArtists(page, append);
}

function moreData(page = 0, append = false) {
  fetchArtists(page, append);
}

function onSearch(query) {
  searchQuery.value = query;
  currentPage.value = 0;
  goToPage();
}

const fetchBanners = async () => {
  try {
    const response = await apiClient.get('/v1/contents/banners', {
      params: {type: 'ART_ARTIST'}
    });
    banners.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const fetchArtists = async (page = 0, append = false) => {
  if (isFetching.value) {
    return;
  }
  isFetching.value = true;

  try {
    const response = await apiClient.get(
        `/v1/contents/artists?q=${searchQuery.value}&like=${searchOnlyLike.value}&page=${page}&size=${size.value}&sort=${selectedSort.value.value}&direction=${selectedSort.value.direction}`);
    const newData = response.data.content;
    if (append) {
      artists.value.push(...newData);
    } else {
      artists.value = newData;
    }

    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  } finally {
    isFetching.value = false;
  }
};

const setSwiper = async () => {
  mainSwiper.value = new Swiper('.visualSwiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    pagination: {
      el: ".swiper-pagination"
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  }).init();
};

const init = async () => {
  uiStore.showLoading();
  await fetchBanners();
  await fetchArtists(currentPage.value);
  await setSwiper();
  if (route.query?.page !== undefined) {
    artistsRef.value.scrollIntoView({block: "start"});
  }
  uiStore.hideLoading();
};

onMounted(async () => {
  await init();
});
</script>

<style scoped>
/*  아치 이미지 temp
.arch-image { 
  position: relative;
  width: 100%;
  aspect-ratio: 1/4;
}

.arch-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 100% 70%;
  border-top-right-radius: 100% 70%;
} */

.sort-options {
  display: flex;
  align-items: center;
  gap: 20px;
  /* 옵션 간의 간격 */
}

.sort-options span {
  cursor: pointer;
  font-size: clamp(14px, 2.0vw, 16px);
  color: #999;
  /* 기본 텍스트 색상 (회색) */
  transition: color 0.3s ease;
}

.sort-options span.selected {
  color: #ffc107;
  /* 선택된 텍스트 색상 (노란색) */
  font-weight: bold;
  /* 선택된 텍스트 강조 */
}

.sort-options .checkbox-type1 label {
  font-size: clamp(14px, 2.0vw, 16px);
}

/* 모든 슬라이드 내 이미지 가운데 정렬 */
.swiper-slide {
  display: flex;
  justify-content: center; /* 좌우 중앙 정렬 */
  align-items: center; /* 상하 중앙 정렬 */
  text-align: center; /* 텍스트 중앙 정렬 (선택사항) */
}

</style>