<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <!-- s::PAGE -->
  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div v-if="!pdfModalVisible" class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>

            <div class="content-panel">
              <div class="my-artartist">
                <div class="my-portfolio">
                  <section>
                    <h2 class="section-title">포트폴리오 제작용 프로필 선택</h2>
                    <div class="profile-box">
                      <div class="col1">
                        <dl>
                          <dt>작가명 (필수)</dt>
                          <dd>
                            <div class="chk-group">
                              <span class="checkbox-type1">
                                <input type="checkbox" id="artistNameKo" v-model="checkStates.artistNameKo">
                                <label for="artistNameKo">국문</label>
                              </span>
                              <span class="checkbox-type1">
                                <input type="checkbox" id="artistNameEn" v-model="checkStates.artistNameEn">
                                <label for="artistNameEn">영문</label>
                                </span>
                            </div>
                          </dd>
                          <dt>학력</dt>
                          <dd>
                            <div class="chk-group">
                              <span v-if="undergraduate" class="checkbox-type1">
                                <input type="checkbox" id="chk2-1" v-model="checkStates.undergraduate">
                                <label for="chk2-1">학부</label>
                              </span>
                              <span v-if="graduate" class="checkbox-type1">
                                <input type="checkbox" id="chk2-2" v-model="checkStates.graduate">
                                <label for="chk2-2">대학원</label>
                              </span>
                              <span v-if="other" class="checkbox-type1">
                                <input type="checkbox" id="chk2-3" v-model="checkStates.other">
                                <label for="chk2-3">기타</label>
                              </span>
                            </div>
                          </dd>
                          <dt>경력</dt>
                          <dd>
                            <ul>
                              <li v-if="careerData.EXHIBITION.data.value.length > 0">
                                <span class="checkbox-type1">
                                    <input type="checkbox" id="chk3-1" v-model="checkStates.exhibition" @change="toggleAllCheckboxes($event.target.checked,'EXHIBITION')">
                                    <label for="chk3-1">개인전</label>
                                </span>
                                <button type="button" @click="selectCareers('EXHIBITION')">부분 선택</button>
                              </li>
                              <li v-if="careerData.GROUP_EXHIBITION.data.value.length > 0">
                                <span class="checkbox-type1">
                                    <input type="checkbox" id="chk3-2" v-model="checkStates.group_exhibition" @change="toggleAllCheckboxes($event.target.checked,'GROUP_EXHIBITION')">
                                    <label for="chk3-2">그룹전</label>
                                </span>
                                <button type="button" @click="selectCareers('GROUP_EXHIBITION')">부분 선택</button>
                              </li>
                              <li v-if="careerData.RESIDENCY.data.value.length > 0">
                                <span class="checkbox-type1">
                                  <input type="checkbox" id="chk3-3" v-model="checkStates.residency" @change="toggleAllCheckboxes($event.target.checked,'RESIDENCY')">
                                  <label for="chk3-3">레지던시</label>
                                </span>
                                <button type="button" @click="selectCareers('RESIDENCY')">부분 선택</button>
                              </li>
                              <li v-if="careerData.WORKSHOP.data.value.length > 0">
                                <span class="checkbox-type1">
                                  <input type="checkbox" id="chk3-4" v-model="checkStates.workshop" @change="toggleAllCheckboxes($event.target.checked,'WORKSHOP')">
                                  <label for="chk3-4">워크숍</label>
                                </span>
                                <button type="button" @click="selectCareers('WORKSHOP')">부분 선택</button>
                              </li>
                            </ul>
                          </dd>
                          <dt>기타</dt>
                          <dd>
                            <ul>
                              <li v-if="careerData.AWARD.data.value.length > 0">
                                <span class="checkbox-type1">
                                  <input type="checkbox" id="chk4-1" v-model="checkStates.award" @change="toggleAllCheckboxes($event.target.checked,'AWARD')">
                                  <label for="chk4-1">수상 내역</label>
                                </span>
                                <button type="button" @click="selectCareers('AWARD')">부분 선택</button>
                              </li>
                              <li v-if="careerData.COLLECTION.data.value.length > 0">
                                <span class="checkbox-type1">
                                  <input type="checkbox" id="chk4-2" v-model="checkStates.collection" @change="toggleAllCheckboxes($event.target.checked,'COLLECTION')">
                                  <label for="chk4-2">작품 소장 내역</label>
                                </span>
                                <button type="button" @click="selectCareers('COLLECTION')">부분 선택</button>
                              </li>
                              <li v-if="careerData.INTERVIEW.data.value.length > 0">
                                <span class="checkbox-type1">
                                  <input type="checkbox" id="chk4-3" v-model="checkStates.interview" @change="toggleAllCheckboxes($event.target.checked,'INTERVIEW')">
                                  <label for="chk4-3">인터뷰</label>
                                </span>
                                <button type="button" @click="selectCareers('INTERVIEW')">부분 선택</button>
                              </li>
                              <li v-if="artist.introduction">
                                <span class="checkbox-type1">
                                  <input type="checkbox" id="chk4-4" v-model="checkStates.introduction">
                                  <label for="chk4-4">아티스트 소개</label>
                                </span>
                              </li>
                              <li>
                                <span class="checkbox-type1">
                                  <input type="checkbox" id="chk4-5" v-model="checkStates.profileImage">
                                  <label for="chk4-5">프로필 이미지</label>
                                </span>
                              </li>
                            </ul>
                          </dd>
                        </dl>
                      </div>

                      <div class="col2">
                        <div class="inner" v-if="selectedCareer">
                          <dl>
                            <dt>{{ selectItemTitle }}
                              <template v-if="selectedCareer === 'EXHIBITION' || selectedCareer === 'GROUP_EXHIBITION'">(최대 20개 가능)</template>
                              <template v-if="selectedCareer === 'RESIDENCY' || selectedCareer === 'WORKSHOP'">(최대 5개 가능)</template>
                              <template v-if="selectedCareer === 'AWARD' || selectedCareer === 'COLLECTION' || selectedCareer === 'INTERVIEW'">(최대 10개 가능)</template>
                            </dt>
                            <dd>
                              <ul>
                                <li v-for="(item, index) in selectItems" :key="index">
                                  <span class="checkbox-type1">
                                    <input type="checkbox" :id="'chk' + item.id" v-model="item.isPortfolio" @change="updateSelection(item)">
                                    <label :for="'chk' + item.id">
                                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                                    </label>
                                  </span>
                                </li>
                              </ul>
                            </dd>
                          </dl>
                          <div class="btn-box">
                            <button type="button" @click="completeSelection">부분 선택 완료</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="button-group">
                      <button type="button" class="btn-preview" @click="previewProFile">선택 프로필 미리보기</button>
                      <button @click="saveDraft('profile')" type="button">프로필 임시저장</button>
                    </div>
                  </section>

                  <section>
                    <h2 class="section-title">포트폴리오 제작용 아트워크 선택</h2>
                    <h2 class="section-title">(원하시는 작품을 선택 후 옆으로 드래그하거나 '추가' 버튼을 눌러보세요!)</h2>
                    <div class="sort-panel">
                      <div class="inner-box">
                        <div class="sortable-list left-list l-cont">
                          <ul ref="leftList" class="sort-list">
                            <li
                                v-for="(item, index) in leftItems"
                                :key="item.id"
                                :data-id="item.id"
                                @click="toggleActive(index, item.id)"
                                :class="{ on: activeIndexes.includes(index) }"
                            >
                              <i>{{ clickOrder.indexOf(item.id) !== -1 ? clickOrder.indexOf(item.id) + 1 : '' }}</i>
                              <img :src="item.watermarkedImageUrl" alt="" style="width: 177px;height: 177px;"/>
                              <div class="mask">
                                <p>
                                  {{ item.title }}_{{ item.theme }}<br/>
                                  {{ item.material }} _ {{ item.size }}<br/>
                                  {{ item.year }}
                                </p>
                              </div>
                            </li>
                          </ul>
                          <div class="btn-box">
                            <button @click="moveToRightItems" type="button">추가</button>
                          </div>
                        </div>
                        <div class="sortable-list right-list r-cont">
                          <ul ref="rightList" class="sort-list2">
                            <li
                                v-for="item in rightItems"
                                :key="item.id"
                                :data-id="item.id"
                            >
                              <figure>
                                <img :src="item.watermarkedImageUrl" alt="" style="width: 58px;height: 58px;"/>
                              </figure>
                              <div class="txt">
                                <p>
                                  {{ item.title }}_{{ item.theme }}<br/>
                                  {{ item.material }}<br/>{{ item.size }}
                                  {{ item.year }}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="button-box">
                        <button @click="saveDraft('artworks')" type="button">아트워크 임시저장</button>
                      </div>
                    </div>
                  </section>

                  <div class="button-box">
                    <button @click="makePortfolio" type="button">포트폴리오 제작</button>
                    <router-link to="/my/profile">기존 프로필 수정</router-link>
                    <router-link to="/my/artwork">기존 아트워크 수정</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->

      <!-- s:: OUTPUT PORTFOLIO -->
      <div ref="contentToConvert" v-if="pdfModalVisible" class="print-panel">
        <div class="layout">
          <div class="portfolio-slide">
            <button type="button" class="close-print" @click="closePreViewPdfModal">
              <img src="@/assets/images/icon/ico_close_b.svg" alt="">
            </button>
            <div class="swiper-container slider main-swiper">
              <div class="button-box" style="position: absolute;right: 380px;z-index: 100;">
                <button @click="exportPDF" type="button">PDF 내보내기</button>
              </div>
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="inner">
                    <div class="frame frame1" @mouseover="showMessage" @mouseleave="hideMessage">
                      <div class="col1">
                        <em>Portfolio</em>
                        <h2 v-if="artist.artistNameEn">
                          {{ artist.artistNameEn.split(' ')[0] }}<br/>
                          {{ artist.artistNameEn.split(' ')[1] }}
                        </h2>
                        <h3 v-if="artist.artistNameKo">
                          {{ artist.artistNameKo }}
                        </h3>
                        <div ref="border_style1" class="text-box" style="border:1px solid #009ddf;">
                          <button @click="removeTextBox" type="button" class="btn_remove">
                            <img src="@/assets/images/icon/ico_close_blue.svg" alt=""/>
                          </button>
                          <textarea v-model="textarea" @input="handleInput" :maxlength="37"></textarea>
                          <div v-if="isMessageVisible" class="tooltip">
                            작가님에 대해 간략하게 설명해주세요.<br>
                            표지에 삽입을 원치 않을 경우 우측 상단의 X 버튼을 누르면 영역이 삭제됩니다.
                          </div>
                        </div>
                      </div>
                      <div ref="border_style2" class="col2" style="border:1px solid #009ddf;">
                        <button @click="removeImageBox" type="button" class="btn_remove">
                          <img src="@/assets/images/icon/ico_close_blue.svg" alt=""/>
                        </button>
                        <div class="img-wrap">
                          <img v-if="previewImageFile" :src="previewImageFile.url" :alt="previewImageFile.name" crossOrigin=""/>
                          <div :class="['filebox', { on: previewImageFile }]">
                            <label for="upload-img" style="top: 70%;">이미지 업로드</label>
                            <div v-if="isMessageVisible" class="tooltip">
                              포트폴리오 표지에 들어갈 이미지를 업로드해 주세요. (대표작 사진 등)
                              표지에 이미지 삽입을 원치 않을 경우 우측 상단의 X 버튼을 누르면 영역이 삭제됩니다.
                            </div>
                            <input type="file" id="upload-img" @change="previewUploadImage" accept="image/*" />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="inner">
                    <div class="frame frame2">
                      <div class="top-box">
                        <div class="col">
                          <h2>{{ artist.artistNameEn }}</h2>
                          <div class="list-box" v-if="checkStates.undergraduate || checkStates.graduate || checkStates.other">
                            <h3>Education</h3>
                            <ul>
                              <li v-if="undergraduate">{{ undergraduate.year }} {{ undergraduate.title }} {{ undergraduate.description }} {{ undergraduate.statusLabel }} </li>
                              <li v-if="graduate">{{ graduate.year }} {{ graduate.title }} {{ graduate.description }} {{ graduate.statusLabel }} </li>
                              <li v-if="other">{{ other.year }} {{ other.title }} {{ other.description }} {{ other.statusLabel }} </li>
                            </ul>
                          </div>
                        </div>
                        <figure>
                          <img v-if="checkStates.profileImage" :src="artist.profileImageUrl+'?time='+ new Date().valueOf()" :alt="artist.artistNameKo" crossOrigin=""/>
                          <figcaption v-if="checkStates.introduction">
                            {{ artist.introduction }}
                            <strong>{{ artist.artistNameKo }}</strong>
                          </figcaption>
                        </figure>
                      </div>
                      <template v-if="selectedItemValues.filter(({ type }) => type === 'EXHIBITION').length > 4 || selectedItemValues.filter(({ type }) => type === 'GROUP_EXHIBITION').length > 4">
                        <div class="txt-list">
                          <div v-if="checkStates.exhibition" class="list-box">
                            <h3>Solo Exhibition</h3>
                            <ul>
                              <li v-for="item in selectedItemValues.filter(({ type }) => type === 'EXHIBITION')" :key="item.id">
                                {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                              </li>
                            </ul>
                          </div>
                          <div v-if="checkStates.group_exhibition" class="list-box">
                            <h3>Group Exhibition</h3>
                            <ul>
                              <li v-for="item in selectedItemValues.filter(({ type }) => type === 'GROUP_EXHIBITION')" :key="item.id">
                                {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div v-for="(chunk, index) in itemChunks" :key="index" class="txt-list">
                          <div class="list-box" v-for="category in chunk" :key="category.key">
                            <h3>{{ category.label }}</h3>
                            <ul>
                              <li v-for="item in selectedItemValues.filter(({ type }) => type === category.key)" :key="item.id">
                                {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </template>
                      <div class="page-info">
                        <i><img src="@/assets/images/icon/ico_shape.png" alt=""></i>
                        <span>02</span>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="selectedItemValues.filter(({ type }) => type === 'EXHIBITION').length > 4 && selectedItemValues.filter(({ type }) => type === 'GROUP_EXHIBITION').length > 4">
                  <div v-if="checkStates.residency || checkStates.workshop || checkStates.award || checkStates.collection" class="swiper-slide">
                    <div class="inner">
                      <div class="frame frame3">
                        <div v-for="(chunk, index) in itemChunks2" :key="index" class="txt-list">
                          <div class="list-box" v-for="category in chunk" :key="category.key">
                            <h3>{{ category.label }}</h3>
                            <ul>
                              <li v-for="item in selectedItemValues.filter(({ type }) => type === category.key)" :key="item.id">
                                {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="page-info">
                          <i><img src="@/assets/images/icon/ico_shape.png" alt=""></i>
                          <span>03</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div class="swiper-slide" v-for="(artwork, index) in singleImageList" :key="index" :data-id="artwork.id">
                  <div class="inner">
                    <div class="frame frame4">
                      <figure>
                        <img :src="artwork.watermarkedImageUrl+'?time='+ new Date().valueOf()" :alt="artwork.title" style="height: 404px;" crossOrigin=""/>
                        <figcaption>
                          <strong>{{ artwork.title }}</strong>
                          {{ artwork.year }}
                          / {{ artwork.size }} / {{ artwork.material }}
                        </figcaption>
                      </figure>
                      <div class="page-info">
                        <i><img src="@/assets/images/icon/ico_shape.png" alt=""/></i>
                        <span>{{ formatPageNumber(index + 4) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide" v-for="(artwork, index) in doubleImageList" :key="index" :data-id="index">
                  <div class="inner">
                    <div class="frame frame5">
                      <div class="img-list">
                        <figure>
                          <img :src="artwork[0].watermarkedImageUrl+'?time='+ new Date().valueOf()" :alt="artwork[0].title" style="height: 404px;" crossOrigin=""/>
                          <figcaption>
                            <strong>{{ artwork[0].title }}</strong>
                            {{ artwork[0].year }}
                            / {{ artwork[0].size }} / {{ artwork[0].material }}
                          </figcaption>
                        </figure>
                        <figure>
                          <img :src="artwork[1].watermarkedImageUrl+'?time='+ new Date().valueOf()" :alt="artwork[1].title" style="height: 404px;" crossOrigin=""/>
                          <figcaption>
                            <strong>{{ artwork[1].title }}</strong>
                            {{ artwork[1].year }}
                            / {{ artwork[1].size }} / {{ artwork[1].material }}
                          </figcaption>
                        </figure>
                      </div>

                      <div class="page-info">
                        <i><img src="@/assets/images/icon/ico_shape.png" alt="" crossOrigin=""/></i>
                        <span>{{ formatPageNumber(singleImageList.length + index + 4) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-control">
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
            <div class="swiper-container thumbnail-swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(thumbnail, index) in thumbnailSwiper" :key="index">
                  <img src="@/assets/images/@draft/@mask.png" :alt="thumbnail">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: OUTPUT PORTFOLIO -->
    </div>
  <!-- e::PAGE -->

  <!-- s::프로필 미리보기 모달 -->
  <div class="modal-popup modal-profile" v-if="profileModalVisible">
    <button @click="closeProfileModal" type="button" class="close-modal">
      <img src="https://integer0916.cafe24.com/article21/images/icon/ico_close_w.svg" alt="">
    </button>
    <div class="profile">
      <div class="view">
        <div class="picture-box">
          <figure v-if="checkStates.profileImage">
            <img :src="artist.profileImageUrl" alt="" style="width: 163px; height: 228px;">
          </figure>
        </div>
        <div class="cont-box">
          <ul>
            <li>
              <div class="title">
                <span>작가명</span>
              </div>
              <div class="col">
                <div class="row" v-if="checkStates.artistNameKo">
                  <div class="sub-title">
                    <span>국문</span>
                  </div>
                  <div class="field">{{ artist.artistNameKo }}</div>
                </div>
                <div class="row" v-if="checkStates.artistNameEn">
                  <div class="sub-title">
                    <span>영문</span>
                  </div>
                  <div class="field">{{ artist.artistNameEn }}</div>
                </div>
              </div>
            </li>
            <li v-if="checkStates.undergraduate || checkStates.graduate || checkStates.other">
              <div class="title">
                <span>학력</span>
              </div>
              <div class="col">
                <div class="row" v-if="checkStates.undergraduate">
                  <div class="sub-title">
                    <span>학부</span>
                  </div>
                  <div class="field">
                    {{ undergraduate.year }} {{ undergraduate.title }} {{ undergraduate.description }} {{ undergraduate.statusLabel }}
                  </div>
                </div>
                <div class="row" v-if="checkStates.graduate">
                  <div class="sub-title">
                    <span>대학원</span>
                  </div>
                  <div class="field">
                    {{ graduate.year }} {{ graduate.title }} {{ graduate.description }} {{ graduate.statusLabel }}
                  </div>
                </div>
                <div class="row" v-if="checkStates.other">
                  <div class="sub-title">
                    <span>기타</span>
                  </div>
                  <div class="field">
                    {{ other.year }} {{ other.title }} {{ other.description }} {{ other.statusLabel }}
                  </div>
                </div>
              </div>
            </li>
            <li v-if="checkStates.exhibition || checkStates.group_exhibition || checkStates.residency || checkStates.workshop ">
              <div class="title">
                <span>경력</span>
              </div>
              <div class="col">
                <div class="row" v-if="checkStates.exhibition">
                  <div class="sub-title">
                    <span>개인전</span>
                  </div>
                  <div class="field">
                    <p v-for="item in selectedItemValues.filter(({ type }) => type === 'EXHIBITION')" :key="item.id">
                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="checkStates.group_exhibition">
                  <div class="sub-title">
                    <span>그룹전</span>
                  </div>
                  <div class="field">
                    <p v-for="item in selectedItemValues.filter(({ type }) => type === 'GROUP_EXHIBITION')" :key="item.id">
                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="checkStates.residency">
                  <div class="sub-title">
                    <span>레지던시</span>
                  </div>
                  <div class="field">
                    <p v-for="item in selectedItemValues.filter(({ type }) => type === 'RESIDENCY')" :key="item.id">
                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                    </p>
                  </div>
                </div>
                <div class="row" v-if="checkStates.workshop">
                  <div class="sub-title">
                    <span>워크숍</span>
                  </div>
                  <div class="field">
                    <p v-for="item in selectedItemValues.filter(({ type }) => type === 'WORKSHOP')" :key="item.id">
                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan" v-if="checkStates.award">
              <div class="title">
                <span>수상내역</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <p v-for="item in selectedItemValues.filter(({ type }) => type === 'AWARD')" :key="item.id">
                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan" v-if="checkStates.collection">
              <div class="title">
                <span>작품 소장 내역</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <p v-for="item in selectedItemValues.filter(({ type }) => type === 'COLLECTION')" :key="item.id">
                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan" v-if="checkStates.interview">
              <div class="title">
                <span>인터뷰</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <p v-for="item in selectedItemValues.filter(({ type }) => type === 'INTERVIEW')" :key="item.id">
                      {{ item.year }} {{ item.title }} / {{ item.description }}, {{ item.location }}<br/>
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li class="colspan" v-if="checkStates.introduction">
              <div class="title">
                <span>아티스트 한 줄 소개</span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="field">
                    <p>{{ artist.introduction }}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="button-box">
            <button type="button">수정하기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- e::프로필 미리보기 모달 -->

  <!-- s::모달창 등록-->
  <div class="modal-popup modal-print" v-if="selectSizeModalVisible">
    <button @click="closePdfModal" type="button" class="close-modal">
      <img src="https://integer0916.cafe24.com/article21/images/icon/ico_close_w.svg" alt="">
    </button>
    <div class="form-box">
      <div class="field-box">
        <ul>
          <li class="colspan">
            <div class="title">
              <span>크기</span>
            </div>
            <div class="col">
              <div class="row" v-for="size in sizes" :key="size.id">
                <div class="field">
                  <p class="radio-type1">
                    <input
                        type="radio"
                        :id="size.id"
                        name="size-group"
                        :value="size.value"
                        v-model="selectedSize"
                        @change="changeWidthHeight"
                    />
                    <label :for="size.id">{{ size.label }}</label>
                  </p>
                </div>
              </div>
            </div>
          </li>
          <li class="colspan">
            <div class="title">
              <span>방향</span>
            </div>
            <div class="col">
              <div class="row" v-for="direction in directions" :key="direction.id">
                <div class="field">
                  <p class="radio-type1">
                    <input
                        type="radio"
                        :id="direction.id"
                        name="direction-group"
                        :value="direction.value"
                        v-model="selectedDirection"
                        @change="changeDirection"
                    />
                    <label :for="direction.id">{{ direction.label }}</label>
                  </p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="btn-box">
      <button @click="previewPdf" type="button">설정 완료</button>
    </div>
  </div>
  <!-- e::모달창 등록-->

  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import html2canvas from "html2canvas";
import {jsPDF} from 'jspdf';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const profileModalVisible = ref(false);
const pdfModalVisible = ref(false);
const selectSizeModalVisible = ref(false);
const artist = ref(null);
const previewImageFile = ref(null);
const education = ref([]);
const career = ref([]);
const others = ref([]);
const border_style1 = ref(null);
const border_style2 = ref(null);
const emit = defineEmits(['mode-change']);
const isMessageVisible = ref(false);

const showMessage = () => isMessageVisible.value = true;
const hideMessage = () => isMessageVisible.value = false;

const checkStates = reactive({
  artistNameKo: true,
  artistNameEn: true,
  profileImage: false,
  introduction: false,

  undergraduate: false,
  graduate: false,
  other: false,
  exhibition: false,
  group_exhibition: false,
  residency: false,
  workshop: false,
  award: false,
  collection: false,
  interview: false,
});

const categories = [
  { key: 'EXHIBITION', label: 'Solo Exhibition', stateKey: 'exhibition' },
  { key: 'GROUP_EXHIBITION', label: 'Group Exhibition', stateKey: 'group_exhibition' },
  { key: 'RESIDENCY', label: 'Residency', stateKey: 'residency' },
  { key: 'COLLECTION', label: 'Collection', stateKey: 'collection' },
  { key: 'WORKSHOP', label: 'Workshop', stateKey: 'workshop' },
  { key: 'INTERVIEW', label: 'Interview', stateKey: 'interview' },
  { key: 'AWARD', label: 'Award', stateKey: 'award' },
];

const itemChunks = computed(() => {
  const chunks = [];
  let chunk = [];
  for (const category of categories) {
    if (checkStates[category.stateKey]) {
      chunk.push(category);
      if (chunk.length === 2) {
        chunks.push(chunk);
        chunk = [];
      }
    }
  }
  if (chunk.length) {
    chunks.push(chunk);
  }
  return chunks;
});

const itemChunks2 = computed(() => {
  const chunks = [];
  let chunk = [];
  for (const category of categories) {
    if (checkStates[category.stateKey] && category.stateKey !== 'exhibition' && category.stateKey !== 'group_exhibition') {
      chunk.push(category);
      if (chunk.length === 2) {
        chunks.push(chunk);
        chunk = [];
      }
    }
  }
  if (chunk.length) {
    chunks.push(chunk);
  }
  return chunks;
});

const undergraduate = ref(null);
const graduate = ref(null);
const other = ref(null);
const careerData = {
  EXHIBITION: { title: "개인전", data: ref(null) },
  GROUP_EXHIBITION: { title: "그룹전", data: ref(null) },
  RESIDENCY: { title: "레지던시", data: ref(null) },
  WORKSHOP: { title: "워크숍", data: ref(null) },
  AWARD: { title: "수상 내역", data: ref(null) },
  COLLECTION: { title: "작품 소장 내역", data: ref(null) },
  INTERVIEW: { title: "인터뷰", data: ref(null) },
};
const selectedCareer = ref(null);
const selectedItems = ref({});
const selectedItemValues = ref([]);
const activeIndexes = ref([]);
const clickOrder = ref([]);
const rightItems = ref([]);
const leftItems = ref([]);
const rightList = ref(null);
const leftList = ref(null);
const contentToConvert = ref(null);
const singleImageList = ref([]);
const doubleImageList = ref([]);
const selectedSize = ref('A4');
const selectedDirection = ref('horizontal');
const sizes = [
  { id: 'size-radio1', label: 'A4(210 x 297mm)', value: 'A4' },
  { id: 'size-radio2', label: 'A5(148 x 210mm)', value: 'A5' },
  { id: 'size-radio3', label: 'B5(182 x 257mm, 16절)', value: 'B5' },
  { id: 'size-radio4', label: 'B6(128 x 182mm, 36절)', value: 'B6' },
  { id: 'size-radio5', label: 'Presentation(16:9)', value: 'Presentation' }
];
const directions = [
  { id: 'direction-radio1', label: '가로', value: 'horizontal' },
  { id: 'direction-radio2', label: '세로', value: 'vertical ' }
];
const pdfSettings = ref({
  pageWidth: 210,
  pageHeight: 297,
  orientation: 'landscape',
  scale: 3,
  imageQuality: 1.0
});
const mainSwiper = ref(null);
const thumbnailSwiper = ref([]);
const selectItems = ref([]);
const selectItemTitle = computed(() => selectedCareer.value ? careerData[selectedCareer.value].title : '');

// 데이터 로드 및 초기화
const loadProfile = async () => {
  loading.value = true;

  try {
    const response = await apiClient.post('/user/profile');
    artist.value = response.data;

    const profile = response.data.profile;
    const filterProfile = (type) => profile.filter(res => res.type === type);

    undergraduate.value = filterProfile("UNDERGRADUATE")[0];
    graduate.value = filterProfile("GRADUATE")[0];
    other.value = filterProfile("OTHER")[0];

    careerData.EXHIBITION.data.value = filterProfile("EXHIBITION");
    careerData.GROUP_EXHIBITION.data.value = filterProfile("GROUP_EXHIBITION");
    careerData.RESIDENCY.data.value = filterProfile("RESIDENCY");
    careerData.WORKSHOP.data.value = filterProfile("WORKSHOP");
    careerData.AWARD.data.value = filterProfile("AWARD");
    careerData.COLLECTION.data.value = filterProfile("COLLECTION");
    careerData.INTERVIEW.data.value = filterProfile("INTERVIEW");

    const typeCheck = {};
    profile.forEach(item => {
      if (!typeCheck[item.type]) {
        typeCheck[item.type] = item.isPortfolio;
      } else {
        typeCheck[item.type] = typeCheck[item.type] || item.isPortfolio;
      }
      updateSelection(item);
    });

    for (const type in typeCheck) {
      checkStates[type.toLowerCase()] = typeCheck[type];
    }

    response.data.artWorks.forEach(artWork => {
      artWork.isPortfolio ? rightItems.value.push(artWork) : leftItems.value.push(artWork);
    });

    profile.forEach(item => {
      const { type } = item;
      (["UNDERGRADUATE", "GRADUATE", "OTHER"].includes(type) ? education.value :
          ["EXHIBITION", "GROUP_EXHIBITION", "RESIDENCY", "WORKSHOP"].includes(type) ? career.value :
              others.value).push(item);
    });
    career.value.sort((a, b) => b.year - a.year);
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

// 체크된 항목이 있는지 확인하는 함수
const checkAnySelected = () => Object.values(checkStates).some(value => value);

// 커리어 선택 함수
const selectCareers = (careerType) => {
  selectedCareer.value = careerType;
  selectItems.value = careerData[careerType].data.value.map(item => ({...item}))
};
const careerTypeToIndex = {
  'EXHIBITION': 1,
  'GROUP_EXHIBITION': 2,
  'RESIDENCY': 3,
  'WORKSHOP': 4,
};


// 체크박스 변경 함수
watch(selectItems, (newItems) => {
  const anyChecked = newItems.some(item => item.isPortfolio);
  const checkBox = document.querySelector(`#chk3-${careerTypeToIndex[selectedCareer.value]}`);
  if (checkBox) {
    checkBox.checked = anyChecked;
  }
  newItems.forEach(item => {
    careerData[item.type].data.value.map(data => {
      if(item.id === data.id){
        data.isPortfolio = item.isPortfolio
      }
    });
  });
}, { deep: true });

const toggleAllCheckboxes = (checked, careerType) => {
  selectedCareer.value = careerType;
  selectItems.value = careerData[careerType].data.value.map(item => ({...item}))
  selectItems.value.forEach(item => {
    item.isPortfolio = checked;
    updateSelection(item, careerType);
  });
  const checkBox = document.querySelector(`#chk3-${careerTypeToIndex[careerType]}`);
  if (checkBox) {
    checkBox.checked = checked;
  }
  careerData[careerType].data.value.map(data => data.isPortfolio = checked);
};

// 선택 완료 처리 함수
const updateSelection = (item, careerType) => {
  const type = item.type.toLowerCase();
  if (item.isPortfolio) {
    selectedItemValues.value.push({ ...item });
    checkStates[type] = true;
  } else {
    const index = selectedItemValues.value.findIndex(i => i.id === item.id);
    if (index !== -1) selectedItemValues.value.splice(index, 1);
    checkStates[type] = selectItems.value.some(i => i.type === type.toUpperCase() && i.isPortfolio);
  }
};

const completeSelection = () => {
  const careerKey = selectedCareer.value ? selectedCareer.value.toLowerCase() : '';
  if (careerKey) {
    checkStates[careerKey] = true;
    selectedCareer.value = null;
  } else {
    console.error('Invalid career selected');
  }
};

// 임시저장 함수
const saveDraft = async (type) => {
  const message = type === "profile" ? "선택한 정보를 저장 하시겠습니까?" : "선택한 작품목록을 저장 하시겠습니까?";
  const updateUrl = type === "profile" ? "/user/update/portfolio" : "/artwork/update/portfolio";
  const updateData = type === "profile" ? selectedItemValues.value.map(item => item.id) : rightItems.value.map(item => item.id);
  if(confirm(message)){
    try {
      await apiClient.put(updateUrl, updateData);
      alert("저장 되었습니다.")
    } catch (error) {
      console.error(error);
    }
  }
};

// 아트워크 선택 토글 함수
const toggleActive = (index, id) => {
  const i = activeIndexes.value.indexOf(index);
  if (i === -1) {
    activeIndexes.value.push(index);
  } else {
    activeIndexes.value.splice(i, 1);
  }
  const clickIndex = clickOrder.value.indexOf(id);
  if (clickIndex !== -1) {
    clickOrder.value.splice(clickIndex, 1);
  } else {
    clickOrder.value.push(id);
  }
};

// 아트워크 오른쪽으로 이동 함수
const moveToRightItems = () => {
  clickOrder.value.forEach(id => {
    const itemIndex = leftItems.value.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      rightItems.value.push(leftItems.value[itemIndex]);
      leftItems.value.splice(itemIndex, 1);
    }
  });
  leftItems.value = leftItems.value.map(item => ({ ...item, isPortfolio: false }));
  rightItems.value = rightItems.value.map(item => ({ ...item, isPortfolio: true }));
  clickOrder.value = [];
  activeIndexes.value = [];
};

// 프로파일 미리보기 함수
const previewProFile = () => {
  if(!validationPortfolio()){
    return;
  }
  emit('mode-change', "view");
  profileModalVisible.value = !profileModalVisible.value;
};

// 포트폴리오 만들기 함수
const makePortfolio = () => {
  if(!validationPortfolio()){
    return;
  }
  emit('mode-change', "view");
  selectSizeModalVisible.value = true;
};

const validationPortfolio = () => {
  if (!checkAnySelected()) {
    alert("선택한 항목이 없습니다.");
    return false;
  }
  if (!checkStates.artistNameKo || !checkStates.artistNameEn) {
    alert("작가명을 선택 하세요.");
    return false;
  }
  if (!checkStates.exhibition && !checkStates.group_exhibition && !checkStates.residency && !checkStates.workshop) {
    alert("선택한 경력이 없습니다.\n경력 목록이 없으면 프로필에서 등록 해주세요.");
    return false;
  }
  return true;
}
// 모달 닫기 함수
const closeProfileModal = () => {
  emit('mode-change', "none");
  profileModalVisible.value = !profileModalVisible.value;
};

// PDF 모달 닫기 함수
const closePdfModal = () => {
  emit('mode-change', "none");
  selectSizeModalVisible.value = !selectSizeModalVisible.value;
};

// PDF 미리보기 모달 닫기 함수
const closePreViewPdfModal = () => {
  emit('mode-change', "none");
  pdfModalVisible.value = false;
  // window.location.reload();
};

// 아이템 업데이트 함수
const updateItems = () => {
  const allItems = [...leftItems.value, ...rightItems.value];
  const leftIDs = Array.from($(leftList.value).children()).map(el => $(el).data('id'));
  const rightIDs = Array.from($(rightList.value).children()).map(el => $(el).data('id'));

  leftItems.value = leftIDs.map(id => {
    const item = allItems.find(item => item.id === id);
    if (item) item.isPortfolio = false;
    return item;
  }).filter(item => item !== undefined);

  rightItems.value = rightIDs.map(id => {
    const item = allItems.find(item => item.id === id);
    if (item) item.isPortfolio = true;
    return item;
  }).filter(item => item !== undefined);
};

// PDF 미리보기 함수
const previewPdf = async () => {
  selectSizeModalVisible.value = false;
  pdfModalVisible.value = true;
  emit('mode-change', "none");

  let previousSmallItem = null;
  if (rightItems.value.length === 1) {
    const item = rightItems.value[0];
    singleImageList.value.push(item);
  } else {
    rightItems.value.forEach(item => {
      const size = item.size.split("x");
      if (size[0] < 91 || size[1] > 73) {
        singleImageList.value.push(item);
      } else {
        if (previousSmallItem) {
          doubleImageList.value.push([previousSmallItem, item]);
          previousSmallItem = null;
        } else {
          previousSmallItem = item;
        }
      }
    });
  }

  try {
    // PDF 중앙정렬
    if(selectedItemValues.value.filter(({ type }) => type === 'EXHIBITION').length > 4
        || selectedItemValues.value.filter(({ type }) => type === 'GROUP_EXHIBITION').length > 4){

      const frameElement1 = document.querySelector('.frame.frame2');
      const frameElement2 = document.querySelector('.frame.frame3');
      const liCount = frameElement1.querySelectorAll('li').length;
      const paddingValue = `${liCount * 7}px`;

      frameElement1.style.paddingTop = paddingValue;
      frameElement1.style.paddingBottom = paddingValue;
      frameElement2.style.paddingTop = paddingValue;
      frameElement2.style.paddingBottom = paddingValue;
    }
  }catch (error){
    console.error(error)
  }
};

// 텍스트 박스 제거 함수
const removeTextBox = () => {
  $(".text-box").remove();
};

// 이미지 박스 제거 함수
const removeImageBox = () => {
  $(".col2").remove();
};

// 이미지 업로드 미리보기 함수
const previewUploadImage = async (event) => {
  const file = event.target.files[0];
  const url = await toDataURL(URL.createObjectURL(file));
  const imgElement = new Image();
  imgElement.src = url;
  previewImageFile.value = { name: file.name, url, type: file.type };
  hideMessage();
};

// DataURL 변환 함수
const toDataURL = async (src) => {
  let blob = await fetch(src).then(r => r.blob());
  return await new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

// 크기 변경 함수
const changeWidthHeight = (event) => {
  const sizes = {
    A4: [210, 297],
    A5: [148, 210],
    B5: [182, 257],
    B6: [128, 182],
    Presentation: [297, 167],
    default: [210, 297]
  };
  const [width, height] = sizes[event.target.value] || sizes.default;
  pdfSettings.value.pageWidth = width;
  pdfSettings.value.pageHeight = height;
};

// 방향 변경 함수
const changeDirection = (event) => {
  pdfSettings.value.orientation = event.target.value;
};
const textarea = ref('');
const handleInput = () => {
  if (textarea.value.length > 37) {
    textarea.value = textarea.value.slice(0, 37);
  }
};
// PDF 내보내기 함수
const exportPDF = async () => {
  if (confirm("PDF 저장 하시겠습니까?")) {
    try {
      border_style1.value.style.border = 0;
      border_style2.value.style.border = 0;
      $(".filebox").remove();
      $(".slider .button-box").remove();
      $(".btn_remove").remove();

      const { pageWidth, pageHeight, orientation, scale, imageQuality } = pdfSettings.value;
      const pdf = new jsPDF({
        orientation: orientation,
        unit: 'mm',
        format: [pageWidth, pageHeight]
      });
      const swiperContainer = mainSwiper.value.el;
      const inners = swiperContainer.querySelectorAll('.inner');

      for (const [index, inner] of Array.from(inners).entries()) {
        const innerWidth = inner.scrollWidth;
        const innerHeight = inner.scrollHeight;

        const canvas = await html2canvas(inner, { allowTaint: false, useCORS: true, width: innerWidth, height: innerHeight, scale: scale });
        const imgData = canvas.toDataURL('image/png', imageQuality);

        if (index > 0) {
          pdf.addPage();
        }

        const pdfPageWidth = pdf.internal.pageSize.getWidth();
        const pdfPageHeight = pdf.internal.pageSize.getHeight();

        const widthRatio = pdfPageWidth / canvas.width;
        const heightRatio = pdfPageHeight / canvas.height;
        const scaleRatio = Math.min(widthRatio, heightRatio);

        const imgWidth = canvas.width * scaleRatio;
        const imgHeight = canvas.height * scaleRatio;

        const xOffset = (pdfPageWidth - imgWidth) / 2;
        const yOffset = (pdfPageHeight - imgHeight) / 2;

        pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);
      }

      pdf.save(artist.value.artistNameKo + '-portfolio.pdf');

    } catch (error) {
      console.error(error);
    } finally {
      // window.location.reload();
    }
  }
};

// 스와이퍼 초기화 함수
const initializeSwiper = () => {
  if (!mainSwiper.value) {
    mainSwiper.value = new Swiper('.main-swiper', {
      slidesPerView: 1,
      spaceBetween: 10,
      loop: false,
      grabCursor: true,
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next',
      },
    });
  }

  if (mainSwiper.value && !thumbnailSwiper.value) {
    thumbnailSwiper.value = new Swiper('.thumbnail-swiper', {
      slidesPerView: 6,
      spaceBetween: 20,
      loop: false,
      grabCursor: true,
      slideToClickedSlide: true,
      on: {
        click: function () {
          const clickedIndex = this.clickedIndex;
          if (mainSwiper.value) {
            mainSwiper.value.slideTo(clickedIndex);
          }
        }
      },
      controller: {
        control: mainSwiper.value,
      },
    });
  }
};

const destroySwiper = () => {
  if (mainSwiper.value && typeof mainSwiper.value.destroy === 'function') {
    mainSwiper.value.destroy(true, true);
    mainSwiper.value = null;
  }

  if (thumbnailSwiper.value && typeof thumbnailSwiper.value.destroy === 'function') {
    thumbnailSwiper.value.destroy(true, true);
    thumbnailSwiper.value = null;
  }
};
const initializeSortable = () => {
  $(leftList.value).sortable({
    connectWith: $(rightList.value),
    stop: () => updateItems()
  });

  $(rightList.value).sortable({
    connectWith: $(leftList.value),
    stop: () => updateItems()
  });
};
const destroySortable = () => {
  if (leftList.value && $(leftList.value).data('ui-sortable')) {
    $(leftList.value).sortable('destroy');
  }
  if (rightList.value && $(rightList.value).data('ui-sortable')) {
    $(rightList.value).sortable('destroy');
  }
};

// 페이지 번호 포맷팅 함수
const formatPageNumber = (number) => number < 10 ? '0' + number : number.toString();

watch(pdfModalVisible, (newValue) => {
  if (newValue) {
    setTimeout(async () => {
      try {
        initializeSwiper();
      } catch (error) {
        console.error("Error initializing Swiper", error);
      }
    }, 100);
  } else {
    if (mainSwiper.value) {
      destroySwiper();
    }
  }
  if (newValue) {
    setTimeout(async () => {
      try {
        destroySortable();
      } catch (error) {
        console.error("Error initializing Swiper", error);
      }
    }, 100);
  } else {
    initializeSortable();
  }
});


onMounted(async () => {
  // await loadProfile();
  // initializeSortable();
});

onBeforeUnmount(() => {
  destroySortable();
  destroySwiper();
});
</script>


<style scoped>
.slider .button-box{width:842px;margin-top:37px;display:flex;justify-content: flex-end;}
.slider .button-box button{display: flex;align-items: center;justify-content: center;width: 125px;height: 31px;border-radius: 20px;background-color: #8c5a48;font-size: 15px;font-weight: bold;color: #000;}

.tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
</style>