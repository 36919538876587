<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: ARTICLE -->
    <div class="article">
      <div class="layout">
        <div class="box">
          <ArticleSnb/>
          <div v-if="news" class="content-panel">
            <div class="view-type1">
              <ArticleButtons v-model:isLike="news.isLike" :uuid="news.uuid" :likeType="news.likeType"/>
              <div class="img">
                <img :src="news.imageUrl" alt="">
              </div>
              <div class="info-box">
                <h3>[{{ getNewsTypeLabel(news.type) }}] {{ news.title }}</h3>
                <dl>
                  <dt>기간</dt>
                  <dd>{{ getPeriod() }}</dd>
                  <template v-if="news.organizer">
                    <dt>주최/주관</dt>
                    <dd>{{ news.organizer }}</dd>
                  </template>
                  <template v-if="getTime()">
                    <dt>시간</dt>
                    <dd>{{ getTime() }}</dd>
                  </template>
                  <template v-if="news.location">
                    <dt>
                      장소
                      <a @click="openKakaoMap(news.location)">
                        <img src="@/assets/images/icon/ico_map_pin.svg" alt="카카오맵"
                             style="width: clamp(16px, 1.4vw, 18px); cursor: pointer; margin-bottom: -2px;"/>
                      </a>
                    </dt>
                    <dd>{{ news.location }} {{ news.locationDetail }}</dd>
                  </template>
                  <template v-if="news.contactPhoneNum || news.contactEmail">
                    <dt>문의</dt>
                    <dd v-if="news.contactPhoneNum">{{ news.contactPhoneNum }}</dd>
                    <dd v-if="news.contactEmail">{{ news.contactEmail }}</dd>
                  </template>
                  <template v-if="news.content">
                    <dd>
                      <p class="txt white-space-pre-line">
                        {{ news.content }}
                      </p>
                    </dd>
                  </template>
                  <template v-if="news.originLink">
                    <dt>바로가기</dt>
                    <dd>
                      <a :href="news.originLink" target="_blank" class="link" rel="noopener noreferrer" >{{ news.originLink }}</a>
                    </dd>
                  </template>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: ARTICLE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {apiClient} from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import ArticleButtons from "@/components/article/ArticleButtons.vue";
import {ARTIST_ARTICLE_NEWS_TYPE} from "@/common/common-enum";
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();

const route = useRoute();
const articleUuid = ref(route.params.uuid);
const loading = ref(true);
const error = ref(null);
const news = ref(null);

const newsTypeMap = new Map(
    ARTIST_ARTICLE_NEWS_TYPE.map(({value, label}) => [value, label])
);

const getNewsTypeLabel = (type) => {
  return newsTypeMap.get(type);
}

const getPeriod = () => {
  let period = news.value.periodFrom + ' ~ ' + news.value.periodTo;
  if (news.value.periodMemo) {
    period += ' (' + news.value.periodMemo + ')';
  }
  return period;
}

const getTime = () => {
  let time = '';
  if (news.value.timeFrom) {
    time += news.value.timeFrom;
  }

  if (news.value.timeTo) {
    time += ' ~ ' + news.value.timeTo;
  }

  if (news.value.timeMemo) {
    time += ' (' + news.value.timeMemo + ')';
  }

  return time;
}

const openKakaoMap = (address) => {
  if (!address) {
    return;
  }
  const encodedAddress = encodeURIComponent(address);
  const kakaoMapUrl = `https://map.kakao.com/?q=${encodedAddress}`;
  window.open(kakaoMapUrl, "_blank");
};

onMounted(async () => {
  try {
    uiStore.showLoading();
    const response = await apiClient.get(`/v1/contents/articles/news/${articleUuid.value}`);
    news.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
    uiStore.hideLoading();
  }
});
</script>