import MyProfile from "@/views/user/Profile.vue";
import ArtWork from "@/views/user/ProfileArtWork.vue";
import Portfolio from "@/views/user/Portfolio.vue";
import Signup from "@/views/Signup.vue";
import MyPage from "@/views/user/MyPage.vue";
import PasswordUpdate from "@/views/user/PasswordUpdate.vue";
import VerifyResult from "@/components/auth/VerifyResult.vue";
import {useAuthStore} from "@/store/auth.module";
import { useRoute, useRouter} from "vue-router";

export const userRoutes = [
    {
        path: '/auth/signup',
        name: 'Signup',
        component: Signup,
        beforeEnter: (to, from, next) => {
            if (!sessionStorage.getItem("reloaded")) {
                sessionStorage.setItem("reloaded", "true");
                window.location.href = to.fullPath; // 새로고침
            } else {
                sessionStorage.removeItem("reloaded"); // 이후에는 정상 라우팅
                next();
            }
        }
    },
    {
        path: '/auth/verifyResult',
        component: VerifyResult,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        beforeEnter: async (to, from, next) => {
            const authStore = useAuthStore();
            const router = useRouter();
            authStore.logout();
            window.location.href = '/';
            // router.push({name:'Home'}).then(value => {
            //     location.reload();
            // });
        }
    },
    {
        path: '/my/info',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/my/info/apply-artist',
        name: 'ApplyArtistPage',
        component: MyPage,
        meta: { isApplyArtist: true } 
    },
    {
        path: '/my/password',
        name: 'PasswordUpdate',
        component: PasswordUpdate,
    },
    {
        path: '/my',
        name: 'ART & ARTISTS',
        children: [
            {
                path: '/my/artist/profile',
                name: 'MyProfile',
                component: MyProfile,
            },
            {
                path: '/my/artist/artwork',
                name: 'ProfileArtWork',
                component: ArtWork,
            },
            {
                path: '/my/artist/portfolio',
                name: 'Portfolio',
                component: Portfolio,
            },
        ],
    },
];
