<template>
  <div class="empty-wrap">
    <img src="@/assets/images/icon/ico_search_g.svg" alt="">
    <p>검색된 내용이 없습니다.<br>다른 검색어를 입력해주세요.</p>
  </div>

</template>

<script setup>

</script>