<template>
  <div class="title">
    <span>{{ title }}</span>
  </div>
  <div class="col field style-03">
    <div class="row" v-for="(item, index) in localData" :key="index">
      <CustomSelect v-model="item.year" :options="selectYearRange" placeholder="연도" fieldClass="field-one field-select-1 no-line" />
      <div class="field-one input-text-1 no-line">
        <input type="text" :placeholder="placeholder" maxlength="200" v-model="item.title">
      </div>
      <button v-if="index === 0" type="button" class="add-list" @click="addListItem()">
        <img src="@/assets/images/icon/ico_add3.svg">
      </button>
      <button v-else type="button" class="add-list delete" @click="removeItem(index)">
        <img src="@/assets/images/icon/ico_delete.svg">
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, toRefs } from 'vue'
import { getSelectYearRange } from "@/common/common-function";
import CustomSelect from '@/components/ui/CustomSelect.vue';

const selectYearRange = getSelectYearRange();

const props = defineProps({
  title: String,
  placeholder: String,
  data: Array,
});
const { title, placeholder, data } = toRefs(props);

// props.data를 reactive 형태로 변환한 내부 데이터
const localData = reactive(
  (props.data && props.data.length > 0) // props.data가 유효한 경우
    ? props.data.map((item) => ({
      year: item.year,
      title: item.title, // 기존 문자열을 객체 속성으로 변환
    }))
    : [{ year: '', title: '' }] // props.data가 없거나 빈 배열인 경우 기본값 추가
);

const addListItem = () => {
  const maxItems = 30;
  if (localData.length >= maxItems) {
    alert(`최대 ${maxItems}개까지 등록 가능합니다`);
    return;
  }
  localData.push({ year: '', title: '' });
};

const removeItem = (index) => {
  localData.splice(index, 1);

  // 데이터가 비어 있으면 기본값 추가
  if (localData.length === 0) {
    localData.push({ year: '', title: '' });
  }
};

const getFilteredData = () => {
  return localData.filter(({ year, title }) => {
    return year && title;
  });
};

const validateData = () => {
  let isAnyFieldFilled = false; // 최소 하나의 항목이 입력되었는지 확인

  for (const item of localData) {
    const { year, title } = item;
    if (year || title) {
      isAnyFieldFilled = true;
      if (!year || !title) {
        return {
          isValid: false,
          errorMessage:
            '입력 중인 수상 내역이 있습니다. 저장하시려면 모든 항목을 입력해주세요.\n사용하지 않을 정보라면 입력된 항목을 삭제해 주세요.',
        };
      }
    }
  }

  // Validation 성공
  return {
    isValid: true,
    errorMessage: null,
  };
};


defineExpose({
  getFilteredData,
  validateData
});
</script>

<style scoped></style>