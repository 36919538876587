<template>
  <div class="modal modal-cs" id="fmb-cs-modal"> <!-- Pagination.vue onPagePopState 에서 열려있는지 잡는 id-->
    <div class="modal-inner">
      <div class="modal-title">
        <img src="@/assets/images/common/logo_b2.svg" alt="">
        <p>접수된 순서대로 답변 드립니다. (휴일 제외 최대 3일 소요)</p>
      </div>
      <div class="modal-content">
        <span class="tabs-title">분류</span>
        <div class="modal-tabs">
          <div class="tab-titles">
            <div class="radio-one" v-for="tab in tabs" :key="tab.id">
              <input type="radio" :id="'tab' + tab.id" name="tab" :checked="activeTab === tab.id"
                @change="switchTab(tab.id)" />
              <label :for="'tab' + tab.id" class="tab-label">
                {{ tab.label }}
              </label>
            </div>
          </div>
          <!-- 탭 콘텐츠 -->
          <div class="tab-wrap">    
            <component :is="currentTabContent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, onMounted, onUnmounted} from 'vue';
import TabInquiryContent from '@/components/fab/TabInquiryContent.vue';
import TabContentRequest from '@/components/fab/TabContentRequest.vue';
import TabUserTypeChange from '@/components/fab/TabUserTypeChange.vue';

const props = defineProps({
  toggleModal: {
    type: Function,
  },
});

const activeTab = ref(1);
const switchTab = (tabId) => {
  activeTab.value = tabId;
};

const tabs = [
  { id: 1, label: '문의 사항', content: TabInquiryContent },
  { id: 2, label: '콘텐츠 게시 요청', content: TabContentRequest },
  { id: 3, label: '회원 유형 전환', content: TabUserTypeChange }
];

const currentTabContent = computed(() => {
  const active = tabs.find(tab => tab.id === activeTab.value);
  return active ? active.content : null;
});

const onCsModalPopState = (event) => {
  props.toggleModal(false);
};

onMounted(() => {
  window.history.pushState({csModalOpen: true}, '');
  window.addEventListener("popstate", onCsModalPopState);
});

onUnmounted(() => {
  window.removeEventListener("popstate", onCsModalPopState);
});
</script>