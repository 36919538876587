<template>
  <div class="modal-wrap modal" id="modalArtworkSave">
    <div class="modal-upload">
      <button type="button" class="close-modal" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <form action="" id="artForm">
        <div class="form-box">
          <div class="picture-box">
            <div ref="filebox" id="filebox" class="filebox">
              <label for="upload-img">작품 이미지 업로드</label>
              <input type="file" id="upload-img" accept="image/jpeg, image/png" @change="loadImage">
              <img ref="output" v-if="modalArtWork" id="output" :src="modalArtWork.imageUrl">
            </div>
            <p>
              jpg 또는 png 형식으로<br>
              고화질 파일을 업로드해주세요.
            </p>
          </div>
          <div class="field-box">
            <ul>
              <li>
                <div class="title">
                  <span class="req">장르</span>
                </div>
                <div class="col">
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.genre" :options="ARTIST_ACTIVITY_GENRES" placeholder="장르 선택" :is-disabled="true"/>
                    <div class="field" v-if="modalArtWork.genre === 'ETC'">
                      <input type="text" class="etc" v-model="modalArtWork.etc" placeholder="(직접 입력)">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">작품명</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="sub-title">
                      <span>국문</span>
                    </div>
                    <div class="field field-sep">
                      <input type="text" placeholder="국문 제목을 입력해주세요." v-model="modalArtWork.title">
                    </div>
                  </div>
                  <div class="row">
                    <div class="sub-title">
                      <span>영문</span>
                    </div>
                    <div class="field field-sep">
                      <input type="text" placeholder="영문 제목을 입력해주세요." v-model="modalArtWork.titleEn">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">제작 시기</span>
                </div>
                <div class="col">
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.year" :options="selectYearRange" placeholder="연도" :is-disabled="true"/>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">사이즈</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="field">
                      <input type="text" placeholder="가로 X 세로 X 높이 mm" required v-model="modalArtWork.size">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">재료</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="field">
                      <input type="text" placeholder="사용된 재료를 알려주세요." required v-model="modalArtWork.material">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">테마</span>
                </div>
                <div class="col">
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeSubject" :options="ARTIST_ARTWORK_THEME_SUBJECT"
                                  placeholder="표현대상" :is-disabled="true"/>
                  </div>
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeTime" :options="ARTIST_ARTWORK_THEME_TIME"
                                  placeholder="시간" :is-disabled="true"/>
                  </div>
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeFocus" :options="ARTIST_ARTWORK_THEME_FOCUS"
                                  placeholder="포커스" :is-disabled="true"/>
                  </div>
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeStyle" :options="ARTIST_ARTWORK_THEME_STYLE"
                                  placeholder="방식" :is-disabled="true"/>
                  </div>
                </div>
              </li>
              <li class="explaint">
                <div class="title">
                  <span>작품 설명<br><p style="font-size: 13px">({{ charCount }} / {{ maxLength }} 자)</p></span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="field">
                      <textarea name="" id="" v-model="modalArtWork.description" :maxlength="maxLength"
                                placeholder="아티스트의 창작 의도나 작품을 이해하는 데 도움이 되는 설명이나 키워드를 입력해주세요. 이 정보는 다른 회원에게는 공개되지 않으며, 아티클21에서 해당 아티스트 회원에게 기업이나 브랜드와의 컬래버레이션, 아트상품 제작 등을 제안하는 데 활용할 수 있습니다."></textarea>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="button-box">
              <button v-if="modalMode === MODE_MODIFY" type="button" class="cancel" id="removeArtwork"
                      @click="openDeleteModal">아트워크 삭제하기
              </button>
              <button type="button" id="btnArtSave" @click="saveArtWork">아트워크 {{
                  modalMode === MODE_REGISTER ? "등록" :
                      "수정"
                }}하기
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- 아트워크 삭제 안내 모달 -->
  <ArtworkDeleteModal
      v-if="isDeleteModalVisible"
      @close="closeDeleteModal"
      @delete="deleteArtWork"
  />
</template>

<script setup>

import {
  ARTIST_ACTIVITY_GENRES,
  ARTIST_ARTWORK_THEME_FOCUS,
  ARTIST_ARTWORK_THEME_STYLE,
  ARTIST_ARTWORK_THEME_SUBJECT,
  ARTIST_ARTWORK_THEME_TIME
} from "@/common/common-enum";
import CustomSelect from "@/components/ui/CustomSelect.vue";
import {defineProps, watch, ref, computed, onMounted, onUnmounted} from "vue";
import {apiClient} from "@/services/auth-header";
import {getSelectYearRange} from "@/common/common-function";
import {useUiStore} from "@/store/useUiStore";
import ArtworkDeleteModal from "@/components/profileArtWork/ArtworkDeleteModal.vue";

const uiStore = useUiStore();
const selectYearRange = getSelectYearRange();

const output = ref(null);
const filebox = ref(null);
const selectedImageFile = ref(null);

const MODE_REGISTER = 'register';
const MODE_MODIFY = 'modify';

const maxLength = 600; // 한글 포함 시 안전한 문자 수 제한
const charCount = computed(() => modalArtWork.value?.description?.length);

const props = defineProps({
  artWork: Object,
  modalMode: String,
});

const emit = defineEmits(["close"]);

const modalArtWork = ref({...props.artWork});

watch(() => props.artWork, (newVal) => {
  modalArtWork.value = {...newVal};
}, {deep: true});

const loadImage = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedImageFile.value = file;
    output.value.src = URL.createObjectURL(file);
    output.value.onload = () => {
      URL.revokeObjectURL(output.value.src);
      filebox.value.classList.add('on');
    };
  }
};

const saveArtWork = async () => {
  if (!validateArtWorkSave()) {
    return;
  }

  const formData = new FormData();
  if (selectedImageFile.value) {
    formData.append('image', selectedImageFile.value);
  }
  formData.append('reqDto', new Blob([JSON.stringify(modalArtWork.value)], {type: 'application/json'}));
  try {
    uiStore.showLoading();
    let response;
    if (props.modalMode === MODE_REGISTER) {
      response = await apiClient.post('/v1/artists/me/artworks', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      response = await apiClient.put(`/v1/artists/me/artworks/${modalArtWork.value.uuid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }

    if (response.status === 200) {
      const str = props.modalMode === MODE_REGISTER ? "저장" : "수정";
      alert(str + " 되었습니다.")
    }
    window.history.back();
    location.reload();
  } catch (error) {
    console.error('Error saving artwork:', error);
  } finally {
    uiStore.hideLoading();
  }
};

const validateArtWorkSave = () => {
  if (!modalArtWork.value.imageUrl && !selectedImageFile.value) {
    alert("이미지는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.genre === null || modalArtWork.value.genre === '') {
    alert("장르는 필수 입니다.")
    return false;
  }

  const { title, titleEn } = modalArtWork.value;
  if ((title === null || title.trim() === '') && (titleEn === null || titleEn.trim() === '')) {
    alert("작품명 국문 또는 영문 중 하나는 필수입니다.");
    return false;
  }

  if (modalArtWork.value.year === null || modalArtWork.value.year === '') {
    alert("제작 시기는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.size === null || modalArtWork.value.size.trim()  === '') {
    alert("사이즈는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.material === null || modalArtWork.value.material.trim()  === '') {
    alert("재료는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeSubject === null || modalArtWork.value.themeSubject === '') {
    alert("테마 - 표현대상은 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeTime === null || modalArtWork.value.themeTime === '') {
    alert("테마 - 시간은 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeFocus === null || modalArtWork.value.themeFocus === '') {
    alert("테마 - 포커스는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeStyle === null || modalArtWork.value.themeStyle === '') {
    alert("테마 - 방식은 필수 입니다.")
    return false;
  }

  return true;
}

const closeModal = () => {
  window.history.back();
}

const handleArtWorkModalPopState = () => {
  if(isDeleteModalVisible.value){
    return;
  }
  emit("close");
}

onMounted(() => {
  window.history.pushState({artWorkModalOpen: true}, '');
  window.addEventListener('popstate', handleArtWorkModalPopState);
});

onUnmounted(() => {
  window.removeEventListener('popstate', handleArtWorkModalPopState);
});

/* 아트워크 삭제 모달 s */
const isDeleteModalVisible = ref(false);
const openDeleteModal = () => {
  isDeleteModalVisible.value = true;
};

const closeDeleteModal = () => {
  isDeleteModalVisible.value = false;
};

const deleteArtWork = async () => {
  try {
    uiStore.showLoading();
    const response = await apiClient.delete(`/v1/artists/me/artworks/${modalArtWork.value.uuid}`);
    if (response.status === 200) {
      alert("아트워크가 삭제되었습니다.");
      // 삭제 후 모달 닫기 및 페이지 갱신 또는 상태 업데이트
      closeDeleteModal();
      emit("close");
    }
    location.reload();
    //router.push({name:route.name});
  } catch (error) {
    console.error("Error deleting artwork:", error);
  } finally {
    uiStore.hideLoading();
  }
};
/* 아트워크 삭제 모달 e */

</script>

<style scoped>

</style>