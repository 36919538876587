<template>
  <div class="download-wrapper">
    <button v-if="!btnSpinner"
            @click="downloadFiles(artWorkUuid)"
            class="down-load-btn"
            type="button"
    >
      <img src="@/assets/images/icon/ico_download.svg"/>
    </button>
    <div v-else class="spinner-container">
      <div class="btn-spinner" style="width: 100%; height: 100%"></div>
    </div>
    <div id="temp-downloadFiles-link" style="visibility: hidden">
    </div>
  </div>
</template>

<script setup>
import {defineProps, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {saveAs} from 'file-saver';
import JSZip from "jszip";

const props = defineProps({
  artWorkUuid: {
    type: String,
    required: true
  }
});

const btnSpinner = ref(false);

const downloadFiles = async (artWorkUuid) => {
  try {
    btnSpinner.value = true;
    const response = await apiClient.get('/v1/artists/me/artworks/file-urls', {
      params: {
        artworkUuids: [artWorkUuid] // 배열로 전달
      },
      paramsSerializer: params => {
        return new URLSearchParams(params).toString();
      }
    });

    if (response.status === 200) {
      const fileUrls = response.data.urls ?? [];
      await downloadAttachments(fileUrls);
    }

  } catch (error) {
    console.error("Error deleting artwork:", error);
  } finally {
    btnSpinner.value = false;
  }
}

async function downloadAttachments(urls) {
  try {

    for (let index = 0; index < urls.length; index++) {
      const url = urls[index];
      const link = document.createElement('a')
      link.href = url
      document.getElementById('temp-downloadFiles-link').appendChild(link);
      link.click()
      document.getElementById('temp-downloadFiles-link').removeChild(link);
    }

  } catch (error) {
    console.error("첨부파일 다운로드 중 오류 발생:", error);
  }
}
</script>

<style scoped>
.spinner-container {
  position: relative;
  width: 30px;
  height: 30px;
}

.down-load-btn{
  width: clamp(30px, 3.0vw, 40px);
}
</style>