<template>
  <!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb/>
            </div>
          </div>
          <div class="content-panel">
            <div class="customer">
              <div class="bbs">
                <section>
                  <div class="title-wrap">
                    <h2 class="section-title">1:1 문의</h2>
                  </div>
                  <div class="bbs-list">
                    <div class="list-top">
                      <p class="total">
                        전체 <em>{{ totalItems }}</em>건
                      </p>
                      <SearchBar v-model="searchQuery" placeholder="검색어" @submit="onSearch"/>
                    </div>
                    <ul class="inquiry-table">
                      <li class="noti-head">
                        <div class="col col1">유형</div>
                        <div class="col col2">제목</div>
                        <div class="col col3">등록일</div>
                        <div class="col col4">상태</div>
                      </li>
                      <li v-for="(item, index) in items" :key="index">
                        <div class="col col1">
                          {{ item.type }}
                        </div>
                        <div class="col col2">
                          <router-link :to="{
                            name: 'InquiryDetail',
                            params: { uuid: item.uuid },
                          }" :class="{ new: !item.isRead && item.isAnswered }">
                            <span>{{ item.title }}</span>
                          </router-link>
                        </div>
                        <div class="col col3">{{ item.createdAt }}</div>
                        <div class="col col4">
                          <span :class="['status', item.isAnswered ? 'done' : 'ing']">
                            {{ item.isAnswered ? '답변완료' : '답변대기' }}
                          </span>
                        </div>
                      </li>
                    </ul>

                    <div class="button-box">
                      <router-link :to="{ name: 'InquiryCreate' }">
                        문의하기
                      </router-link>
                    </div>
                  </div>
                  <Pagination :current-page="currentPage" :total-pages="totalPages"
                              :visible-count="5" @goToPage="goToPage"/>
                  <InfiniteScroll :on-reach-end="moreData" :current-page="currentPage"
                                  :total-pages="totalPages" :is-fetching="isFetching"/>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useUnreadStore} from "@/store/unread.module";
import {apiClient} from "@/services/auth-header";
import router from "@/router";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import Pagination from "@/components/ui/Pagination.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll.vue";
import SearchBar from "@/components/ui/SearchBar.vue";
import {INQUIRY_TYPE} from "@/common/common-enum";
import {getLabelFromType} from "@/common/common-function";
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();

// 상태 변수 선언
const unreadStore = useUnreadStore();
const route = useRoute();
const loading = ref(true);
const error = ref(null);
const API_URL = "/v1/support/inquiries";
const items = ref([]);
const totalItems = ref(0);

// 페이지 정보
const searchQuery = ref(route.query.q || ""); // URL의 title 쿼리값 초기화
const currentPage = ref(Number(route.query.page || 0)); // 현재 페이지
const size = ref(10);
const totalPages = ref(0);
const isFetching = ref(false);

function onSearch(query) {
  searchQuery.value = query;
  currentPage.value = 0;
  goToPage();
}

async function goToPage(page = 0, append = false) {
  await router.push({name: route.name, query: {q: searchQuery.value, page: page}});
}

function moreData(page = 0, append = false) {
  loadItem(page, append);
}

const loadItem = async (page = 0, append = false) => {
  if (isFetching.value) {
    return;
  }
  isFetching.value = true;

  try {
    uiStore.showLoading();
    const response = await apiClient.get(API_URL, {
      params: {q: searchQuery.value, page: page, size: size.value},
    });

    let newData = response.data.content;
    newData = newData.map(item => {
      item.type = getLabelFromType(INQUIRY_TYPE, item.type);
      item.createdAt = item.createdAt.substring(0, 10);
      return item;
    });

    if (append) {
      items.value.push(...newData);
    } else {
      items.value = newData;
    }

    totalItems.value = response.data.totalElements;
    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  } finally {
    isFetching.value = false;
    loading.value = false;
    uiStore.hideLoading();
  }
};

onMounted(() => {
  // document.body.classList.remove("bg-white");
  loadItem(currentPage.value);
  unreadStore.fetchAllUnreadCounts();
});
</script>
