<template>
  <div class="wrap" v-if="totalPages > 1" >
    <div v-if="isFetching" class="spinner-wrapper">
      <div class="btn-spinner"></div>
    </div>
    <a v-else class="more" @click="handleClick">
      <span>{{ currentPage + 1 < totalPages ? 'See More' : 'Close' }}</span>
      <img
          src="@/assets/images/icon/ico_toggle_y.svg"
          alt=""
          :style="{ transform: currentPage + 1 < totalPages ? 'rotate(0deg)' : 'rotate(180deg)' }"
      />
    </a>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';
const isFetching = ref(false);
const props = defineProps({
  totalPages: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  fetchItems: {
    type: Function,
    required: true,
  },
  itemRef: {
    type: HTMLElement,
    required: false,
  },
});

const handleClick = async () => {
  if (props.currentPage + 1 < props.totalPages) {
    isFetching.value = true;
    await props.fetchItems(props.currentPage + 1, true);
    isFetching.value = false;
  } else {
    await props.fetchItems(0);
    props.itemRef.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    });
  }
};
</script>

<style scoped>
.spinner-wrapper {
  height: 85px;
}

@media (max-width: 768px) {
  .wrap {
    margin-top: 30px;
  }
  .wrap a.more {
    row-gap: 6px;
  }
  .wrap a.more span {
    font-size: 14px;
  }
  .wrap a.more img {
    width: 12px;
    height: 5px;
  }
  .spinner-wrapper {
    height: 31px;
  }
}

</style>
