<template>
  <!-- s::PAGE -->
  <div class="contents">
    <!-- s:: ART & ARTIST -->
    <div v-if="artist" class="art-artist">
      <div class="view">
        <div class="layout">
          <div class="box">
            <div class="view-panel">
              <div class="title-wrap">
                <h2 class="section-title">Profile</h2>
                <div class="view-btns-wrap">
                  <HeartButton v-model:isLike="artist.isLike" :uuid="artist.uuid" :likeType="artist.likeType" heartLocation="buttons"/>
                  <ShareButton location="artist"/>
                </div>
              </div>
              <div class="artist-profile">
                <div class="profile-box">
                  <div class="profile">
                    <div class="profile-txt-wrap">
                      <h2>{{ artist.nameEn }}</h2>
                      <h3>{{ artist.nameKo }}</h3>
                    </div>
                    <figure>
                      <img :src="artist.imageUrl" :alt="artist.nameKo || artist.nameEn">
                    </figure>
                  </div>
                  <div class="career">
                    <div class="inner-wrap">
                      <div style="height: 48px">

                      <button type="button" class="open-career" v-show="!isExpanded" @click="toggleExpand" :style="{ visibility: isMobile ? 'visible' : 'hidden' }">
                        See More
                      </button>
                      <button type="button" class="close" v-show="isExpanded" @click="toggleExpand" style="display: inline-block;" :style="{ visibility: isMobile ? 'visible' : 'hidden' }">
                        <img src="@/assets/images/icon/ico_close.svg" alt="Close">
                      </button>
                      </div>
                      <div class="career-wrap" :style="{ overflow: isExpanded ? 'auto' : 'hidden' }">
                        <!--  :style="{ height: isExpanded ? '880px' : 'fit-content' }" -->
                        <div class="col1">
                          <h2>{{ artist.introduction }}</h2>
                        </div>
                        <div v-if="allInfo" class="col2">
                          <transition-group name="fade">
                            <dl v-for="[key, value] in visibleItems" :key="key">
                              <dt>{{ key }}</dt>
                              <dd>
                                <ul>
                                  <template v-for="(item, i) in getVisibleDetails(value)" :key="i">
                                    <li v-html="item"></li>
                                  </template>
                                </ul>
                              </dd>
                            </dl>
                          </transition-group>
                        </div>
                      </div>
                    </div>
<!--                    <router-link v-if="artist.artistArticleSpecial"-->
<!--                                 :to="{ name: 'SpecialDetail', params: { uuid: artist.artistArticleSpecial.articleSpecialUuid } }">-->
<!--                      <div class="button-box" >-->
<!--                        <button type="button" class="btn-art" style="background-color: #1B1A1F; color: #fff;">-->
<!--                      <span style="color: #fff;">ARTICLE</span>-->
<!--                      <img src="@/assets/images/icon/ico_next.svg" alt="">-->
<!--                        </button>-->
<!--                      </div>-->
<!--                    </router-link>-->
                  </div>
                </div>
                <div class="banner-box" v-if="artist.artistArticleSpecial">
                  <figure><img :src="artist.artistArticleSpecial.imageUrl" alt=""></figure>
                  <div class="banner-txt-wrap">
                    <p>
                      {{ artist.artistArticleSpecial.message }}
                    </p>
                    <router-link :to="{ name: 'SpecialDetail', params: { uuid: artist.artistArticleSpecial.articleSpecialUuid } }">
                      <div class="button-box">
                        <button type="button" class="btn-art">
                          <span>SPECIAL</span>
                          <img src="@/assets/images/icon/ico_right.svg" alt="">
                        </button>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <div ref="viewPanelRef" class="view-panel" v-if="artWorks && artWorks.length" >
              <div class="title-wrap style-2">
                <h2 ref="SeeMoreTopRef" class="section-title">artwork</h2>
                <ul>
                  <li><a :class="{ on: !selectedArtWorksCategory }" @click="setCategory(null)">ALL</a></li>
                  <li v-for="category in artWorksCategories" :key="category.uuid">
                    <a :class="{ on: selectedArtWorksCategory && selectedArtWorksCategory.uuid === category.uuid }"
                       @click="setCategory(category)">{{ category.name }}</a>
                  </li>
                </ul>
              </div>

              <div v-if="artWorks && artWorks.length" class="artwork-list tab-content">
                <ul class="favorite artwork">
                  <transition-group name="fade">
                    <li v-for="(artWork, index) in artWorks" :key="artWork.uuid">
                      <img :src="artWork.imageUrl" :alt="artWork.title || artWork.titleEn" class="artWorkImg">
                      <div class="mask">
                        <p>{{ artWork.title || artWork.titleEn }}<br/>{{ artWork.year }}</p>
                      </div>
                      <HeartButton v-model:isLike="artWork.isLike" :uuid="artWork.uuid" :likeType="artWork.likeType"/>
                      <ShareButton :uuid="artWork.uuid"/>
                      <button type="button" class="btn-more" @click="artWorkModalOpen(index)">See More</button>
                    </li>
                  </transition-group>
                </ul>
                <SeeMoreButton
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :fetchItems="fetchArtWorks"
                    :itemRef="SeeMoreTopRef"
                />
              </div>
            </div>

            <div class="view-panel" v-if="artist.videoLink">
              <div class="title-wrap">
                <h2 class="section-title">video clip</h2>
              </div>
              <div class="video-box">
                <iframe :src="getEmbedVideoLink(artist.videoLink)" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
            <div class="view-panel" v-if="artist.interviews && artist.interviews.length">
              <div class="title-wrap">
                <h2 class="section-title">interview</h2>
              </div>
              <div class="interview-wrap">
                <ul>
                  <li v-for="(interview, index) in artist.interviews" :key="index">
                    <a v-if="interview.interviewLink" :href="interview.interviewLink" target="_blank" class="title" rel="noopener noreferrer nofollow" >
                      <div class="txt-wrap">
                        <span class="num">{{ index + 1 }}</span>
                        <span class="title"> {{ interview.interviewTitle || interview.interviewLink }}</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ArtWorksModalSwiper
      v-model:isArtWorkModalOpen="isModalOpen"
      v-model:artWorks="artWorks"
      :slideIndex="artWorksSlideIndex"
      location="artistDetail"
      :totalPages="totalPages"
      :currentPage="currentPage"
      :fetchItems="fetchArtWorks"
  />

</template>

<script setup>
import {onMounted, ref, watch, computed, onUnmounted, nextTick} from 'vue';
import {apiClient} from '@/services/auth-header';
import {useRoute, useRouter} from "vue-router";
import {ARTIST_ACTIVITY_GENRES, ARTIST_EDUCATIONS, ARTIST_EDUCATIONS_STATUS} from "@/common/common-enum";
import HeartButton from "@/components/ui/HeartButton.vue";
import ShareButton from "@/components/ui/ShareButton.vue";
import ArtWorksModalSwiper from "@/components/ui/ArtWorksModalSwiper.vue";
import {useUiStore} from "@/store/useUiStore";
import { useDeviceStore } from "@/store/deviceStore";
import SeeMoreButton from "@/components/ui/SeeMoreButton.vue";
const deviceStore = useDeviceStore();
const isMobile = computed(() => deviceStore.isMobile);

const uiStore = useUiStore();
const router = useRouter();
const route = useRoute();
const artistUserAccountId = ref(route.params.userAccountId);
const artist = ref(null);
const artWorks = ref([]);
const artWorksCategories = ref(null);
const isModalOpen = ref(false);
const artWorksSlideIndex = ref(-1);
const SeeMoreTopRef = ref(null);

const artWorkModalOpen = async (index) => {
  await nextTick();
  artWorksSlideIndex.value = index
  isModalOpen.value = true;
}

watch(() => isModalOpen.value, (newVal) => {
  if (newVal) {
    uiStore.showOverlay();
  } else {
    uiStore.hideOverlay();
  }
})

const selectedArtWorksCategory = ref(null);

// 페이지 정보
const currentPage = ref(0);
const size = computed(() => (window.innerWidth <= 768 ? 8 : 9));
const totalPages = ref(0);
const groupedExperiences = ref({})
const allInfo = ref({})
const viewPanelRef = ref(null);

const isExpanded = ref(!isMobile.value); // Profile "See More" 버튼 상태 (기본 false)

const artistEducationsMap = ARTIST_EDUCATIONS.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

const artistEducationsStatusMap = ARTIST_EDUCATIONS_STATUS.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

const artistActivityGenresMap = ARTIST_ACTIVITY_GENRES.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

// 전체에서 maxVisibleItems 개수만큼 보여줌
const visibleItems = computed(() => (isExpanded.value ? Object.entries(allInfo.value) : Object.entries(allInfo.value).slice(0, 3)));

// 각 항목에서 maxVisibleDetails 개수만큼 보여줌
const getVisibleDetails = (details) => (isExpanded.value ? details : details.slice(0, 3));

// "See More" 버튼 클릭 핸들러
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

const setAllInfo = () => {
  if (artist.value.activityGenreTypes && artist.value.activityGenreTypes.length > 0) {
    allInfo.value['Genre'] = [artist.value.activityGenreTypes
    .map(item =>
        artistActivityGenresMap[item].value !== 'ETC'
            ? artistActivityGenresMap[item].label
            : artist.value.activityGenreEtc
    )
    .join(', ')
    ];
  }

  // Solo Exhibition 처리 (여기서 <span> 태그 포함하여 처리)
  if (groupedExperiences.value['SOLO_EXHIBITION']) {
    allInfo.value['Solo Exhibition'] = groupedExperiences.value['SOLO_EXHIBITION'].map(
        (item) => {
          return `
          <span class="year">${item.year}</span>
          <div class="rest">
            <span class="title">${item.title}</span>
            <span class="separator">|</span>
            <span class="place">${item.location}</span>
            <span class="separator">|</span>
            <span class="region">${item.region}</span>
          </div>
        `;
        }
    );
  }

  if (groupedExperiences.value['GROUP_EXHIBITION']) {
    allInfo.value['Group Exhibition'] = groupedExperiences.value['GROUP_EXHIBITION'].map(
        (item) => {
          return `
          <span class="year">${item.year}</span>
          <div class="rest">
            <span class="title">${item.title}</span>
            <span class="separator">|</span>
            <span class="place">${item.location}</span>
            <span class="separator">|</span>
            <span class="region">${item.region}</span>
          </div>
        `;
        }
    );
  }

  if (artist.value.educations && artist.value.educations.length > 0) {
    const ARTIST_EDUCATIONS = [
      {value: 'GRADUATE', label: '대학원'},
      {value: 'UNDERGRADUATE', label: '학부'},
      {value: 'ETC', label: '기타'},
    ]
    // 출력 순서: 대학원 > 학부 > 기타, 라벨은 첫번째꺼만 출력.
    allInfo.value['Education'] = ARTIST_EDUCATIONS.flatMap(({value, label}) => {
      const filtered = artist.value.educations
      .filter((item) => item.type === value)
      .map((item, index) => {
        const displayLabel = index === 0 ? label : '';  // 첫 번째 항목만 라벨 표시
        return `
          <span class="label ko" style="">${displayLabel}</span>
          <span class="separator">|</span>
          <span class="year">${item.year}</span>
          <div class="rest">
            <span class="schoolName">${item.schoolName}</span>
            <span class="separator">|</span>
            <span class="major">${item.major}</span>
            <span class="separator">|</span>
            <span class="status"> ${artistEducationsStatusMap[item.status].label}</span>
          </div>
      `;
      });
      return filtered;
    });
  }

  if (groupedExperiences.value['RESIDENCY']) {
    allInfo.value['Residency'] = groupedExperiences.value['RESIDENCY'].map(
        (item) => {
          return `
          <span class="year">${item.year}</span>
          <div class="rest">
            <span class="title">${item.title}</span>
            <span class="separator">|</span>
            <span class="place">${item.location}</span>
            <span class="separator">|</span>
            <span class="region">${item.region}</span>
          </div>
        `;
        }
    );
  }

  if (groupedExperiences.value['WORKSHOP']) {
    allInfo.value['Workshop'] = groupedExperiences.value['WORKSHOP'].map(
        (item) => {
          return `
          <span class="year">${item.year}</span>
          <div class="rest">
            <span class="title">${item.title}</span>
            <span class="separator">|</span>
            <span class="place">${item.location}</span>
            <span class="separator">|</span>
            <span class="region">${item.region}</span>
          </div>
        `;
        }
    );
  }

  if (groupedExperiences.value['GRANT']) {
    allInfo.value['Grant'] = groupedExperiences.value['GRANT'].map(
        (item) => {
          return `
          <span class="year">${item.year}</span>
          <div class="rest">
            <span class="title">${item.title}</span>
            <span class="separator">|</span>
            <span class="place">${item.location}</span>
            <span class="separator">|</span>
            <span class="region">${item.region}</span>
          </div>
        `;
        }
    );
  }

  // 'Region' 추가
  let regionInfo = [];

  if (artist.value.domesticArea) {
    regionInfo.push(`
      <span class="place ko">국내</span>
      <span class="separator">|</span>
      <span class="region">${artist.value.domesticArea}</span>
    `);
  }

  if (artist.value.overseasArea) {
    regionInfo.push(`
      <span class="place ko">해외</span>
      <span class="separator">|</span>
      <span class="region">${artist.value.overseasArea}</span>
    `);
  }

  if (regionInfo.length > 0) {
    allInfo.value['Region'] = regionInfo;
  }

  if (artist.value.awards && artist.value.awards.length > 0) {
    allInfo.value['Award'] = artist.value.awards.map(item => {
      return `
        <span class="year">${item.year}</span>
        <div class="rest">
          <span class="title">${item.title}</span>
        </div>
      `;
    });
  }

  if (artist.value.collections && artist.value.collections.length > 0) {
    allInfo.value['Collection'] = artist.value.collections.map(item => {
      return `
         <span class="place">${item}</span>
      `;
    });
  }
}

const getEmbedVideoLink = (videoLink) => {
  if (!videoLink) {
    return ''; // 값이 없으면 빈 문자열이나 null 반환
  }

  // 1) YouTube 일반 링크 (예: https://www.youtube.com/watch?v=abcdef)
  //    쿼리 파라미터 v=값 추출
  const youtubeWatchRegex = /(?:youtube\.com\/watch\?v=)([^&]+)(.*)/;
  const youtubeShortRegex = /(?:youtu\.be\/)([^?]+)(.*)/;

  // 2) Vimeo 링크 (예: https://vimeo.com/12345678)
  const vimeoRegex = /(?:vimeo\.com\/)([^?]+)/;

  // 1. YouTube - watch 링크
  let match = videoLink.match(youtubeWatchRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  // 2. YouTube - 단축 링크
  match = videoLink.match(youtubeShortRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  // 3. Vimeo 링크
  match = videoLink.match(vimeoRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://player.vimeo.com/video/${videoId}`;
  }

  // url 이 아닌 경우 `현재url/{videoLink}` 화면을 iframe에 띄우면서 에러 발생.
  // url 아닌 경우 null 리턴. 
  try {
    const url = new URL(videoLink);
  } catch (error) {
    console.error("Invalid URL:", videoLink, error.message);
    return null;
  }

  // 4. 해당 사항 없으면 원본 링크 그대로 반환 or 처리
  return videoLink;
}

const fetchProfile = async () => {
  if (artistUserAccountId.value) {
    try {
      const artistResponse = await apiClient.get(`/v1/contents/artists/${artistUserAccountId.value}`);
      artist.value = artistResponse.data;
      groupedExperiences.value = artist.value.experiences.reduce((acc, experience) => {
        // 그룹화 기준: type
        const type = experience.type;

        // 해당 type이 처음이면 빈 배열 초기화
        if (!acc[type]) {
          acc[type] = [];
        }

        // 현재 experience를 해당 type에 추가
        acc[type].push(experience);

        return acc;
      }, {});
      setAllInfo();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
};

const fetchArtWorks = async (page = 0, append = false) => {
  try {
    uiStore.showLoading();
    if (artistUserAccountId.value) {
      let api = `/v1/contents/artists/${artistUserAccountId.value}/art-works?page=${page}&size=${size.value}`;
      if (selectedArtWorksCategory.value) {
        api += `&cUuid=${selectedArtWorksCategory.value.uuid}`;
      }
      const response = await apiClient.get(api);

      if (append) {
        artWorks.value = [...artWorks.value, ...response.data.content];
      } else {
        artWorks.value = response.data.content;
      }
      currentPage.value = response.data.pageable.pageNumber;
      totalPages.value = response.data.totalPages;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    uiStore.hideLoading();
  }
}

const loadMoreArtWorks = () => {
  if (currentPage.value < totalPages.value - 1) {
    fetchArtWorks(currentPage.value + 1, true);
  } else {
    fetchArtWorks(0);
    viewPanelRef.value.scrollIntoView({behavior: "smooth",});
  }
};

const fetchArtWorksCategories = async () => {
  try {
    const response = await apiClient.get(`/v1/contents/artists/${artistUserAccountId.value}/art-works/categories`);
    artWorksCategories.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const setCategory = (category) => {
  if (category) {
    if (!selectedArtWorksCategory.value || selectedArtWorksCategory.value.uuid !== category.uuid) {
      selectedArtWorksCategory.value = category;
      fetchArtWorks(0);
    }
  } else {
    if (selectedArtWorksCategory.value) {
      selectedArtWorksCategory.value = null;
      fetchArtWorks(0);
    }
  }
};

const fetchNextPageIfNeeded = async (targetUuid) => {
  if (currentPage.value < totalPages.value - 1) {
    await fetchArtWorks(currentPage.value + 1, true);
  } else {
    console.log(`No more pages to load for uuid: ${targetUuid}`);
  }
};

const openArtWorkIfExists = async (newValue, targetUuid) => {
  const index = newValue.findIndex(item => item.uuid === targetUuid);
  if (index !== -1) {
    await artWorkModalOpen(index);
  } else {
    await fetchNextPageIfNeeded(targetUuid);
    await nextTick();
  }
};

const fetchProfileStatus = async () => {
  try {
    const response = await apiClient.get(`/v1/contents/artists/${artistUserAccountId.value}/profile-status`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

onMounted(async () => {
  uiStore.showLoading();
  const isProfileRegistered = await fetchProfileStatus();
  if (!isProfileRegistered) {
    uiStore.hideLoading();
    alert("프로필이 등록되지 않은 아티스트입니다.")
    return router.replace({name: "ArtistList"});
  }

  await fetchProfile();
  await fetchArtWorksCategories();
  await fetchArtWorks();
  if (route.query.artwork) {
    watch(() => artWorks.value, async (newValue) => {
      await openArtWorkIfExists(newValue, route.query.artwork);
    }, {immediate: true});
  }
  uiStore.hideLoading();
});

onUnmounted(() => {

});
</script>

<style scoped>
/* 애니메이션 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

ul.favorite li img.artWorkImg {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

ul.favorite li {
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}


/* .art-artist .artist-profile .profile-box .career .col2 dl:nth-child(n+3) {display: none;} */
/* .art-artist .artist-profile .profile-box .career .col2 dl dd ul li:nth-child(n+4) {display: none;} */
</style>
