<template>
  <router-link :to="to">
    <div class="img">
      <img :src="item.previewImageUrl || item.imageUrl" alt="" />
    </div>
    <div class="txt-box">
      <h3 class="title">
        <span class="text"><template v-if="item.type">[{{ getNewsTypeLabel(item.type) }}]</template>{{ item.title }}</span>
        <HeartButton v-model:isLike="localIsLike" :uuid="item.uuid" :likeType="item.likeType" />
      </h3>
      <p class="first">{{ item.periodFrom }} ~ {{ item.periodTo }}</p>
      <p v-if="item.organizer" class="second">{{ item.organizer }}</p> <!--news -->
      <p v-else-if="item.location" class="second">{{ item.location }}</p>
    </div>
  </router-link>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from 'vue';
import HeartButton from "@/components/ui/HeartButton.vue";
import {ARTIST_ARTICLE_NEWS_TYPE} from "@/common/common-enum";

const props = defineProps({
  isLike: Boolean,
  item: Object,
  to: Object,
});

const newsTypeMap = new Map(
    ARTIST_ARTICLE_NEWS_TYPE.map(({value, label}) => [value, label])
);

const getNewsTypeLabel = (type) => {  // news
  return newsTypeMap.get(type);
}

const isLike = ref(props.isLike);
const emit = defineEmits(["update:isLike"]);

const localIsLike = computed({
  get: () => isLike.value,
  set: (newValue) => {
    isLike.value = newValue;
    emit("update:isLike", newValue);
  }
});


</script>

<style scoped>
.img img {
  aspect-ratio: 287.73 / 394.6;
  /*object-fit: cover;*/
}

.txt-box {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.title span {
  font-size: clamp(14px, 3.5vw, 20px);
  font-weight: bold;
  flex-grow: 1;
  flex-basis: 0;
  text-align: left;
  letter-spacing: -0.6px;
  color: #fff;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 460px) {
  .title span {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 최대 2줄 */
    -webkit-box-orient: vertical;
    line-height: 1.4; /* 줄 간격 조절 */
    max-height: calc(1.4em * 2); /* 최대 2줄 높이 */
    white-space: normal; /* 여러 줄 허용 */
  }
}

</style>