<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: ARTICLE -->
    <div class="article">
      <div class="layout">
        <div class="box">
          <ArticleSnb/>
          <div class="content-panel">
            <div class="search-wrap">
              <SearchBar v-model="searchQuery" placeholder="검색어" @submit="onSearch"/>
            </div>
            <div v-if="special.length" class="list-type3 special">
              <ul>
                <li v-for="(item, index) in special" :key="index">
                  <ArticleCardSpecial v-model:isLike="item.isLike" :item="item"
                                      :to="{ name: 'SpecialDetail', params: { uuid: item.uuid } }"/>
                </li>
              </ul>
            </div>
            <NoSearchResult v-else/>
            <Pagination :current-page="currentPage" :total-pages="totalPages"
                        :visible-count="5" @goToPage="goToPage"/>
            <InfiniteScroll :on-reach-end="moreData" :current-page="currentPage"
                            :total-pages="totalPages" :is-fetching="isFetching"/>
          </div>
        </div>
      </div>

    </div>
    <!-- e:: ARTICLE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {apiClient} from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import SearchBar from "@/components/ui/SearchBar.vue";
import Pagination from "@/components/ui/Pagination.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll.vue";
import ArticleCardSpecial from "@/components/article/ArticleCardSpecial.vue";
import NoSearchResult from "@/components/ui/NoSearchResult.vue";
import {ARTICLE_LIST_SORT_OPTIONS} from "@/common/common-enum";
import {useUiStore} from "@/store/useUiStore";
import router from "@/router";

const uiStore = useUiStore();
const route = useRoute();
const loading = ref(true);
const error = ref(null);
const special = ref([]);

// 페이지 정보
const searchQuery = ref(route.query.q || "");
const currentPage = ref(Number(route.query.page || 0));
const size = ref(10);
const totalPages = ref(0);
const isFetching = ref(false);
const selectedSort = ref(ARTICLE_LIST_SORT_OPTIONS[0]);

async function fetchSpecials(page = 0, append = false) {
  if (isFetching.value) {
    return;
  }
  isFetching.value = true;

  try {
    uiStore.showLoading();
    const response = await apiClient.get(
        `/v1/contents/articles/specials?q=${searchQuery.value}&page=${page}&size=${size.value}&sort=${selectedSort.value.value}&direction=${selectedSort.value.direction}`);
    const newData = response.data.content;

    if (append) {
      special.value.push(...newData);
    } else {
      special.value = newData;
    }

    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (err) {
    error.value = err;
  } finally {
    isFetching.value = false;
    loading.value = false;
    uiStore.hideLoading();
  }
}

async function goToPage(page = 0, append = false) {
  await router.push(
      {name: route.name, query: {q: searchQuery.value, page: page, sort: selectedSort.value.value, direction: selectedSort.value.direction}});
}

function moreData(page = 0, append = false) {
  fetchSpecials(page, append);
}

function onSearch(query) {
  searchQuery.value = query;
  currentPage.value = 0;
  goToPage();
}

onMounted(async () => {
  await fetchSpecials(currentPage.value);
});

</script>

<style scoped>

</style>