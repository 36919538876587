import {apiClient} from "@/services/auth-header";

export function useNicePopup() {
  const open = async (options) => {
    // options.popupURL, options.width, options.height 등
    const {
      type,
      onPopupClose,     // 팝업 종료 시 호출할 콜백
    } = options;

    const authResult = await authentication(type);
    const form = createNiceForm(authResult);
    document.body.appendChild(form);

    // 팝업 열기
    const popup = window.open(
        '',
        'popupChk',
        'width=480,height=812,top=100,left=100,menubar=no,status=no,toolbar=no,titlebar=yes,location=no,scrollbars=no'
    );

    if (popup) {
      form.submit();

      // 팝업 닫힘 체크
      const popupCheckInterval = setInterval(() => {
        if (popup.closed) {
          clearInterval(popupCheckInterval);
          // 팝업이 닫혔으므로 onPopupClose() 콜백 실행
          if (onPopupClose && typeof onPopupClose === 'function') {
            onPopupClose();
          }

          document.body.removeChild(form);
        }
      }, 500);
    } else {
      alert("팝업이 차단되었습니다. 브라우저 설정에서 팝업 차단을 해제해주세요.");
    }

  };

  return { open };
}

const authentication = async (type) => {
  const verifyInfo = {};
  localStorage.removeItem('verify');
  const returnUrl = "https://article21.kr/auth/verifyResult?type=" + type
  // const returnUrl = "http://localhost:8080/auth/verifyResult?type=" + type
  try {
    const response = await apiClient.post('/v1/auth/identity', {
      returnUrl: returnUrl
    });
    verifyInfo.req_no = response.data.uniqueVal;
    verifyInfo.key = response.data.key;
    verifyInfo.iv = response.data.iv;
    verifyInfo.hmac_key = response.data.hmacKey;
    localStorage.setItem('verify', JSON.stringify(verifyInfo));

    return {
      tokenVersionId: response.data.tokenVersionId,
      encData: response.data.encData,
      integrityValue: response.data.integrity
    };
  } catch (error) {
    console.error(error);
    return {
      tokenVersionId: '',
      encData: '',
      integrityValue: ''
    }
  }
}

const createNiceForm = (authResult) => {
  const form = document.createElement('form');
  form.name = 'form_chk';
  form.method = 'POST';
  form.action = 'https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb';
  form.target = 'popupChk';

  const inputs = [
    { name: 'm', value: 'service' },
    { name: 'token_version_id', value: authResult.tokenVersionId },
    { name: 'enc_data', value: authResult.encData },
    { name: 'integrity_value', value: authResult.integrityValue },
  ];
  inputs.forEach(({ name, value }) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.id = name; // id와 name 동일
    input.name = name;
    input.value = value;
    form.appendChild(input);
  });
  return form;
}
