import { ref, computed } from 'vue';
import router from "@/router";
import { apiClient } from "@/services/auth-header";
import {useUiStore} from "@/store/useUiStore";
import {useAuthStore} from "@/store/auth.module";
export function inquiryFunction() {
  const uiStore = useUiStore();
  const authStore = useAuthStore();
  const isLogin = authStore.status.loggedIn;
  const apiUrl = computed(() => isLogin? "/v1/support" : "/v1/support/guest");

  const inquiry = ref({
    type: "",
    title: "",
    content: "",
    email: "",
    phoneNum: "",
  });

  const selectedFiles = ref([]);

  const selectedCategory = (type) => {
    inquiry.value.type = type;

    if(inquiry.value.type === "CONTENT_POST_REQUEST") {
      inquiry.value.content =` [콘텐츠 게시 요청] 예시
기간: 2025. 01. 01. ~ 2025. 12. 31.
장소: 국립현대미술관 서울관 1전시실
문의: 000-0000-0000 (전시/행사/사업에 대한 문의처)
링크: www.article21.kr (홈페이지 등 관련 링크)
내용: 전시/행사/사업에 대한 소개글`;
    }
  };

  const formatPhoneNumber = (event) => {
    let cleaned = inquiry.value.phoneNum.replace(/\D/g, '')
    let formatted = ''

    if (cleaned.length > 3) {
      formatted = cleaned.slice(0, 3) + '-'
      if (cleaned.length > 7) {
        formatted += cleaned.slice(3, 7) + '-'
        formatted += cleaned.slice(7, 11)
      } else {
        formatted += cleaned.slice(3, 7)
      }
    } else {
      formatted = cleaned
    }

    inquiry.value.phoneNum = formatted
  };

  const loadFile = (event) => {
    const files = event.target.files;
    // 이미 두 개의 파일이 선택되었으면 추가 파일을 선택하지 못하도록 제한
    if (files.length + selectedFiles.value.length > 2) {
      alert("최대 2개의 파일만 업로드 가능합니다.");
      return;
    }

    // 파일을 배열에 추가
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSizeInMB = file.size / (1024 * 1024);

      // 파일 크기 제한: 10MB 이하만 허용
      if (fileSizeInMB > 10) {
        alert("10MB 이하의 파일만 업로드할 수 있습니다.");
        continue; // 이 파일은 무시하고 다음 파일로 진행
      }

      selectedFiles.value.push(file);
    }
  };

  const deleteFile = (fileName, index) => {
    if (confirm(`${fileName}을 삭제 하시겠습니까?`)) {
      selectedFiles.value.splice(index, 1);
    }
  };

  const loadProfile = async () => {
    if(!isLogin) return;
    try {
      uiStore.showLoading();
      const response = await apiClient.get('/v1/users/me');
      inquiry.value.email = response.data.email;
      inquiry.value.phoneNum = response.data.phoneNum;
      formatPhoneNumber();
    } catch (error) {
      console.error("loadProfile err :", error)
    } finally {
      uiStore.hideLoading();
    }
  };

  const validateInquiry = () => {
    if (!inquiry.value.type) {
      alert("카테고리를 선택해주세요!");
      return false;
    }

    if (!inquiry.value.title) {
      alert("제목을 입력해주세요!");
      return false;
    }

    if (!inquiry.value.content) {
      alert("내용을 입력해주세요!");
      return false;
    }

    if (!inquiry.value.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inquiry.value.email)) {
      alert("유효한 이메일 주소를 입력해주세요!");
      return false;
    }

    if (!inquiry.value.phoneNum || !/^(01[0-9]-\d{4}-\d{4}|\d{11})$/.test(inquiry.value.phoneNum)) {
      alert("유효한 전화번호를 입력해주세요! (예: 010-1234-5678)");
      return false;
    }

    return true; // 모든 조건 통과
  };

  const saveInquiry = async (isFab = false) => {
    if (!validateInquiry()) {
      return; // 검증 실패 시 종료
    }

    if (confirm("등록 하시겠습니까?")) {
      const formData = new FormData();

      if (selectedFiles.value && selectedFiles.value.length > 0) {
        selectedFiles.value.forEach((file) => {
          formData.append('files', file); // key 이름은 백엔드와 맞춰서 'files'로 설정
        });
      }

      formData.append(
          'reqDto', // key 이름은 백엔드에서 기대하는 이름으로 설정
          new Blob([JSON.stringify(inquiry.value)], { type: 'application/json' })
      );

      try {
        uiStore.showLoading();
        const response = await apiClient.post(apiUrl.value + '/inquiries', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        alert("등록 되었습니다.");
        if(!isFab) {
          await router.push({name: 'Inquiry'});
        } else {
          window.history.back();
          window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching artist data:", error);
      } finally {
        uiStore.hideLoading();
      }
    }
  };

  return { isLogin, inquiry, selectedFiles, selectedCategory, loadProfile, saveInquiry, loadFile, deleteFile };
}