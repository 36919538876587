<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

<!--  <div v-else-if="error" class="error">{{ error.message }}</div>-->

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb />
            </div>
          </div>
          <div class="content-panel">
            <div class="inquiry">
              <h2 class="page-title">1:1 문의</h2>
              <form>
                <fieldset>
                  <div class="form-box">
                    <div class="field-row">
                      <div class="label">
                        <span>분류</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <select v-model="inquiry.type" @change="selectedCategory(inquiry.type)" style="width: 300px;">
                            <option value="" disabled>카테고리 선택</option>
                            <option v-for="type in INQUIRY_TYPE" :value="type.value" style="font-weight: bold">{{ type.label }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>제목</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input type="text" v-model="inquiry.title" placeholder="제목을 입력해주세요.">
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>내용</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <textarea v-model="inquiry.content" placeholder="내용을 입력해주세요."></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>
                          첨부파일
                        </span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-wrap">
                          <div class="file-box">
                            <label for="fileInput" class="file-label">
                              <span>파일</span>
                            </label>
                            <input type="file" id="fileInput" class="file-input" @change="loadFile" accept="image/*"
                              multiple />
                            <div class="file-txt">
                              <span class="file-sub">10MB 이하 파일 업로드 가능</span>
                            </div>
                          </div>
                          <div class="file-names">
                            <template v-for="(file, index) in selectedFiles" :key="index">
                              <div class="file-name" :class="'file-item-' + index">
                                <span>{{ file.name }}</span>
                                <button @click="deleteFile(file.name, index)" type="button">
                                  <img src="@/assets/images/icon/ico_close_gray.svg"
                                    style="width: 10px; padding-left: 5px" alt="" />
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>이메일</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input type="text" v-model="inquiry.email" placeholder="답변을 받아볼 이메일 주소를 적어주세요.">
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>전화번호</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input type="text" v-model="inquiry.phoneNum" placeholder="휴대폰 번호를 적어주세요.">
                        </div>
                      </div>
                    </div>
                    <div class="button-box">
                      <button type="button" @click="saveInquiry">
                        등록하기
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
<!--  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--    <span></span>-->
<!--  </button>-->
</template>

<script setup>
import { onMounted, ref } from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import { INQUIRY_TYPE } from "@/common/common-enum";
import {inquiryFunction} from "@/composables/inquiryFunction";

const {inquiry, selectedFiles, selectedCategory, loadProfile, saveInquiry, loadFile, deleteFile }= inquiryFunction();

const loading = ref(true);
// const error = ref(null);


onMounted(async () => {
  await loadProfile();
  loading.value = false;
});
</script>