<template>
  <router-link :to="to">
    <div class="img">
      <img :src="item.previewImageUrl" alt="" />
    </div>
    <div class="txt-box">
      <h3 class="title">
        <span class="text">{{ item.title }}</span>
        <HeartButton v-model:isLike="localIsLike"  :uuid="item.uuid" :likeType="item.likeType" />
      </h3>
      <p>{{ item.subTitle }}</p>
    </div>
  </router-link>
</template>

<script setup>
import {computed, defineEmits, defineProps, ref} from 'vue';
import HeartButton from "@/components/ui/HeartButton.vue";

const props = defineProps({
  isLike: Boolean,
  item: Object,
  to: Object,
});

const isLike = ref(props.isLike);

const emit = defineEmits(["update:isLike"]);

const localIsLike = computed({
  get: () => isLike.value,
  set: (newValue) => {
    isLike.value = newValue;
    emit("update:isLike", newValue);
  }
});
</script>

<style scoped>
.img img {
  aspect-ratio: 647 / 394.6;
  /*object-fit: cover;*/
}

.txt-box {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 5px;
}

.title span {
  font-size: clamp(14px, 3.5vw, 20px);
  font-weight: bold;
  flex-grow: 1;
  flex-basis: 0;
  text-align: left;
  letter-spacing: -0.6px;
  color: #fff;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.article .list-type3>ul li button.btn-heart {
  position: static;
  bottom: auto;
  right: auto;
}
</style>