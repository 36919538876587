<template>
  <h2>MY PAGE</h2>
  <dl>
    <dt v-if="isArtist || isArtistBeforeProfile || isArtistPending"><a href="#">ART & ARTISTS</a></dt>
    <dd v-if="isArtist || isArtistBeforeProfile || isArtistPending">
      <ul>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.startsWith('/my/artist/profile') }">
          <router-link v-if="!isArtistPending" :to="{ name: 'MyProfile' }">프로필 관리</router-link>
          <a v-else href="#">프로필 관리</a>
        </li>
        <li @click="handleArtistMenuClick" :class="{ on: route.path.startsWith('/my/artist/artwork') }">
          <router-link v-if="!isArtistPending && !isArtistBeforeProfile" :to="{ name: 'ProfileArtWork' }">아트워크 관리</router-link>
          <a v-else href="#">아트워크 관리</a>
        </li>
        <li>
          <a style="cursor: not-allowed"
             @mouseover="hoverTextPortfolio = 'COMING SOON'"
             @mouseleave="hoverTextPortfolio = '포트폴리오 제작'">
            {{ hoverTextPortfolio }}</a>
        </li>
<!--        <li @click="handleArtistMenuClick" :class="{ on: route.path.startsWith('/my/artist/portfolio') }">-->
<!--          <router-link v-if="!isArtistPending || !isArtistBeforeProfile" :to="{ name: 'Portfolio' }">포트폴리오 제작</router-link>-->
<!--          <a v-else href="#">포트폴리오 제작</a>-->
<!--        </li>-->
      </ul>
    </dd>
    <dt><a href="#">MY FAVORITE</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/favorite/artist') }">
          <router-link :to="{ name: 'FavoriteArtist' }">관심 아티스트</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/favorite/artwork') }">
          <router-link :to="{ name: 'FavoriteArtwork' }">관심 아트워크</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/favorite/article') }">
          <router-link :to="{ name: 'FavoriteArticle' }">스크랩 아티클</router-link>
        </li>
      </ul>
    </dd>
    <dt><a href="#">회원 정보 관리</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/info') }">
          <router-link :to="{ name: 'MyPage' }">나의 정보</router-link>
        </li>
      </ul>
    </dd>
    <dt><a href="#">고객센터</a></dt>
    <dd>
      <ul>
        <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/support/notice') }">
          <div class="link-wrapper" :class="{ new: unreadStore.unreadNoticesCount > 0 }">
            <router-link :to="{ name: 'Notice' }">공지 사항</router-link>
          </div>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/support/faq')  }">
          <router-link :to="{ name: 'FAQ' }">FAQ</router-link>
        </li>
        <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/support/inquiry') }">
          <div class="link-wrapper" :class="{ new: unreadStore.unreadAnsweredInquiriesCount > 0 }">
            <router-link :to="{ name: 'Inquiry' }">1:1 문의</router-link>
          </div>
        </li>
      </ul>
    </dd>
  </dl>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/store/auth.module';
import { useUnreadStore } from '@/store/unread.module';

const hoverTextPortfolio = ref('포트폴리오 제작');

const authStore = useAuthStore();
const unreadStore = useUnreadStore();

const route = useRoute();

const isArtist = computed(() => authStore.status.user.roleType === "ARTIST");
const isArtistBeforeProfile = computed(() => authStore.status.user.roleType === "ARTIST_BEFORE_PROFILE");
const isArtistPending = computed(() => authStore.status.user.roleType.startsWith("ARTIST_PENDING"));

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};

const handleArtistMenuClick = () => {
  if (isArtistPending.value) {
    alert('현재 승인 대기 중이므로 해당 메뉴를 이용할 수 없습니다.');
    return;
  }

  if (isArtistBeforeProfile.value) {
    alert('아직 프로필 등록이 완료되지 않았습니다.')
    return;
  }

  //toggleMenu();
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(() => {
  checkMobile();
});

</script>

<style scoped>
.link-wrapper {
  display: flex;
  align-items: center;
}

.link-wrapper.new::after {
  content: 'N';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #f00;
  color: #fff;
  font-size: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  /* 간격 추가 */
}
</style>