<template>
  <div class="view-btns">
    <!-- 하트 버튼 -->
    <HeartButton v-model:isLike="localIsLike" :uuid="uuid" :likeType="likeType" heartLocation="buttons" />

    <!-- 공유 버튼 -->
    <ShareButton location="button"/>

    <!-- 뒤로가기 버튼 -->
    <a class="btn" @click="handleBack">
      <img src="@/assets/images/icon/ico_right.svg" alt="" style="transform: rotate(180deg);">
      <span>Back</span>
    </a>
  </div>
</template>

<script setup>
import { defineProps,defineEmits,computed } from 'vue';
import { useRoute } from "vue-router";
import router from "@/router";
import HeartButton from "@/components/ui/HeartButton.vue";
import ShareButton from "@/components/ui/ShareButton.vue";

const route = useRoute();

const props = defineProps({
  isLike: Boolean,
  uuid: String,
  likeType: String,
});

const emit = defineEmits(["update:isLike"]);

const localIsLike = computed({
  get: () => props.isLike, // 부모에서 받은 값 읽기
  set: (newValue) => emit("update:isLike", newValue), // 변경 시 부모에게 전달
});

function handleBack() {
  // console.log("route.path",route.path,history.state.back,route)
  if(history.state.back) {
    router.back();
  }
  else {
    const basePath = route.path.split('/').slice(0, 3).join('/');
    router.push({path: basePath});
  }
}

</script>

<style scoped>

</style>