<template>
  <div class="article">
    <h3 ref="ItemRef">{{ sectionTitle }}</h3>
    <div :class="sectionClass">
      <ul>
        <transition-group name="fade">
          <li v-for="(item, index) in items" :key="index">
            <ArticleCardSpecial v-if="sectionType === 'SPECIAL'" v-model:isLike="item.isLike" :item="item"
                                :to="{ name: detailRouteName, params: { uuid: item.uuid } }"/>
            <ArticleCard v-else v-model:isLike="item.isLike" :item="item" :to="{ name: detailRouteName, params: { uuid: item.uuid } }"/>
          </li>
        </transition-group>
      </ul>
    </div>
    <SeeMoreButton
        :totalPages="totalPages"
        :currentPage="currentPage"
        :fetchItems="fetchItems"
        :itemRef="ItemRef"
    />

  </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import {apiClient} from "@/services/auth-header";
import ArticleCard from '@/components/article/ArticleCard.vue';
import ArticleCardSpecial from "@/components/article/ArticleCardSpecial.vue";
import SeeMoreButton from "@/components/ui/SeeMoreButton.vue";

const items = ref([]);
const currentPage = ref(Number(0));
const totalPages = ref(0);
const isFetching = ref(false);
const ItemRef = ref(null);

// 부모로부터 받은 props
const props = defineProps({
  sectionType: {
    type: String,
    required: true
  },
  sectionTitle: {
    type: String,
    required: true
  },
  sectionClass: {
    type: String,
    required: true
  },
  detailRouteName: {
    type: String,
    required: true
  },
  size: {
    type: Number,
    required: true,
  }
});

async function fetchItems(page = 0, append = false) {
  if (isFetching.value) {
    return;
  }
  isFetching.value = true;

  try {
    const response = await apiClient.get(`/v1/users/me/likes?likeType=${props.sectionType}&page=${page}&size=${props.size}`);
    const newData = response.data.content;

    if (append) {
      items.value.push(...newData);
    } else {
      items.value = newData;
    }

    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (err) {
    //error.value = err;
  } finally {
    isFetching.value = false;
  }
}

onMounted(async () => {
  await fetchItems();
});

</script>

<style scoped>
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media all and (max-width: 768px) {
  .article .list-type1.favorite > ul {
    flex-wrap: wrap;
    overflow-x: hidden;
    width: 100%;
    padding-bottom: 0;
  }

  .article .list-type1.favorite > ul li:last-child {
    margin-right: 0;
  }

  .article .list-type1.favorite > ul li {
    min-width: 48%;
  }

  .wrap {
    margin-top: 15px;
  }

  .wrap a.more {
    row-gap: 10px;
  }

  .wrap a.more span {
    font-size: 20px;
  }

  .wrap a.more img {
    width: 25%;
    height: auto;
  }
}
</style>