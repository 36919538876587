import ArtistList from '@/views/artist/ArtistList.vue';
import ArtistDetail from '@/views/artist/ArtistDetail.vue';

export const artistRoutes = [

    {
        path: '/artist',
        redirect: '/artist',
        children: [
            {
                path: '/artist',
                name: 'ArtistList',
                component: ArtistList,
                props: true
            },
            {
                path: '/artist/:userAccountId',
                name: 'ArtistDetail',
                component: ArtistDetail,
                props: true
            },
        ]
    },
];