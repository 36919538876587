<template><!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb/>
            </div>
          </div>
          <div class="content-panel">
            <div class="my-artartist">
              <div class="profile">
                <div class="title-wrap">
                  <h2 class="section-title">PROFILE</h2>
                </div>
                <form>
                  <div class="register">
                    <div class="picture-box">
                      <div class="img-wrap">
                        <div class="filebox">
                          <label v-if="!previewImageFile.url" for="upload-img" class="img-upload-label">사진 등록</label>
                          <input v-if="!previewImageFile.url" type="file" id="upload-img" accept="image/*"
                                 @change="(event) => previewImage(event)">
                          <img v-if="previewImageFile.url" id="output" :src="previewImageFile.url"
                               :alt="previewImageFile.name"/>
                          <div v-if="previewImageFile.url" class="mask">
                            <label for="upload-img" class="img-upload-label">사진 수정</label>
                            <input type="file" id="upload-img" @change="(event) => previewImage(event)"
                                   accept="image/*"/>
                          </div>
                        </div>
                        <div class="txt-wrap">
                          <div class="txt-group">
                            <span>사진은 10MB 이하</span>
                            <span>PDF, JPG, PNG 형식</span>
                          </div>
                          <!-- <span>사진 크기는 3:4비율로 노출됩니다.</span> -->
                        </div>
                      </div>

                    </div>
                    <div class="cont-box">
                      <ul>
                        <li>
                          <div class="title">
                            <span class="req">이름</span>
                          </div>
                          <div class="col">
                            <div class="row">
                              <div class="sub-title">
                                <span>국문</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.nameKo" maxlength="10" placeholder="홍길동">
                              </div>
                            </div>
                            <div class="row">
                              <div class="sub-title">
                                <span>영문</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.nameEn" maxlength="22" placeholder="GILDONG HONG">
                              </div>
                            </div>
                          </div>
                        </li>
                        <ProfileEducation ref="educationRef" :data="artist.educations"
                                          :is-no-education="artist.uuid && artist.educations.length === 0"/>
                        <ProfileExperience ref="experienceRef" :data="artist.experiences"/>

                        <li class="mt">
                          <div class="title">
                            <span class="req">활동 지역</span>
                          </div>
                          <div class="col">
                            <div class="row">
                              <div class="sub-title">
                                <span>국내</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.domesticArea" maxlength="200" placeholder="주요 활동 도시명을 적어주세요.">
                              </div>
                            </div>
                            <div class="row">
                              <div class="sub-title">
                                <span>해외</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.overseasArea" maxlength="200"
                                       placeholder="주요 활동 도시명을 적어주세요. (생략 가능)">
                              </div>
                            </div>
                          </div>
                          <button type="button" class="add-list no-text">
                            <img src="@/assets/images/icon/ico_add3.svg">
                          </button>
                        </li>

                        <li class="colspan">
                          <div class="title">
                            <span class="req">활동 장르(중복 가능)</span>
                          </div>
                          <div class="col">
                            <div class="row">
                              <div class="field">
                                <div class="inner-list">
                                  <div class="li">
                                    <!-- 배포 후에도 칸 밀리면, 여기는 레이아웃이랑 css 수정 해야할듯  -->
                                    <div class="chk-group">
                                      <span v-for="genre in ARTIST_ACTIVITY_GENRES.slice(0, 4)" :key="genre.value"
                                            class="checkbox-type1 chk-one" :class="genre.value === 'ETC' ? '' : ''">
                                        <input type="checkbox" :id="'chk1-' + genre.value" :value="genre.value"
                                               v-model="artist.activityGenreTypes">
                                        <label :for="'chk1-' + genre.value">{{ genre.label }}</label>
                                        <input type="text" class="etc chk-etc-input" placeholder="(직접 입력)"
                                               v-model="artist.activityGenreEtc" maxlength="200"
                                               v-if="genre.value === 'ETC' && artist.activityGenreTypes.includes('ETC')">
                                      </span>
                                    </div>
                                    <div class="chk-group">
                                      <span v-for="genre in ARTIST_ACTIVITY_GENRES.slice(4,)" :key="genre.value"
                                            class="checkbox-type1 chk-one" :class="genre.value === 'ETC' ? 'chk-etc' : ''">
                                        <input type="checkbox" :id="'chk1-' + genre.value" :value="genre.value"
                                               v-model="artist.activityGenreTypes">
                                        <label :for="'chk1-' + genre.value">{{ genre.label }}</label>
                                        <input type="text" class="chk-etc-input" placeholder="(직접 입력)"
                                               v-model="artist.activityGenreEtc" maxlength="200"
                                               v-if="genre.value === 'ETC' && artist.activityGenreTypes.includes('ETC')">
                                      </span>
                                    </div>
                                    <!-- <span><input type="text" class="etc chk-etc-input" placeholder="(직접 입력)"
                                        v-model="artist.activityGenreEtc"
                                        v-if="artist.activityGenreTypes && artist.activityGenreTypes.includes('ETC')"></span> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <!-- 수상 -->
                        <li class="colspan mt">
                          <ProfileAward ref="awardRef" title="수상 내역" placeholder="수상내역을 적어주세요." :data="artist.awards"/>
                        </li>
                        <!-- 작품소장 -->
                        <li class="colspan">
                          <ProfileInput ref="collectionRef" title="작품 소장 내역" placeholder="작품이 소장된 기관 또는 장소가 있다면 적어주세요."
                                        :data="artist.collections"/>
                        </li>
                        <li class="colspan">
                          <div class="title">
                            <span class="req">아티스트 한 줄 소개</span>
                          </div>
                          <div class="col field style-04">
                            <div class="row">
                              <div class="field-one input-text-1 no-line">
                                <input type="text" maxlength="40" placeholder="프로필에 들어갈 문구입니다. 자유롭게 적어주세요.(40자 이내)"
                                       v-model="artist.introduction">
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="colspan">
                          <ProfileInterview ref="interviewRef" title="인터뷰 링크" placeholder="인터뷰 기사 링크가 있다면 넣어주세요."
                                            :data="artist.interviews"/>
                        </li>
                        <li class="colspan">
                          <div class="title">
                            <span>동영상 링크</span>
                          </div>
                          <div class="col field style-04">
                            <div class="row">
                              <div class="field-one input-text-1 no-line">
                                <input type="text" placeholder="아티스트나 작품 정보가 담긴 동영상 링크가 있다면 넣어주세요."
                                       v-model="artist.videoLink" @input="validateVideoLink" maxlength="255"
                                       :style="{ color: isValidVideoLink ? 'inherit' : 'red' }">
                              </div>
                            </div>
                            <!-- <p v-if="artist.videoLink && !isValidVideoLink" style="color: red;">
                              링크를 확인해주세요.
                            </p> -->
                          </div>
                        </li>

                      </ul>
                      <div class="button-box">
                        <!--                        <button type="button" @click="reload" style="margin-right: 10px;">취소하기</button>-->
                        <button type="button" @click="saveProfile">저장하기</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ImageCropper :imageFile="imageFile" :maxWidth="maxWidth" :maxHeight="maxHeight" :isFixedSize="isFixedSize"
                        @cropped="handleCroppedImage"/>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
</template>


<script setup>
import {onMounted, ref, watch} from 'vue';
import 'swiper/swiper-bundle.css';
import {apiClient} from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import ProfileEducation from "@/components/profile/ProfileEducation.vue";
import ProfileInput from "@/components/profile/ProfileInput.vue";
import ProfileExperience from "@/components/profile/ProfileExperience.vue";
import ProfileAward from '@/components/profile/ProfileAward.vue';
import {ARTIST_ACTIVITY_GENRES} from "@/common/common-enum";
import ImageCropper from '@/components/ui/ImageCropper.vue';
import {useUiStore} from "@/store/useUiStore";
import {useAuthStore} from "@/store/auth.module";
import ProfileInterview from "@/components/profile/ProfileInterview.vue";

const uiStore = useUiStore();
const authStore = useAuthStore();

// 상태 변수 선언
const error = ref(null);
const loading = ref(true);

const sideRef = ref(null);
const educationRef = ref(null);
const experienceRef = ref(null);
const awardRef = ref(null);
const collectionRef = ref(null);
const interviewRef = ref(null);

const artist = ref({});
const previewImageFile = ref(null);
const selectedCropImageFile = ref(null);
const selectedOriginImageFile = ref(null);

const reload = () => {
  window.location.reload();
};

const koreanRegex = /^[가-힣]*$/; // 한글만 허용
const englishRegex = /^[A-Z ]*$/;
const noEnglishRegex = /[^A-Za-z]/g;
const koreanNumberRegex = /^[가-힣0-9]*$/;  // 한글 + 숫자 허용
const englishNumberRegex = /^[A-Za-z0-9]*$/;  // 영문 대소문자 + 숫자 허용

watch(() => artist.value.nameKo, (newVal) => {
  if (newVal?.length > 1 && !koreanRegex.test(newVal)) {
    artist.value.nameKo = newVal.replace(/[^가-힣]/g, '');
  }
});

watch(() => artist.value.nameEn, (newVal) => {
  if (newVal?.length > 1 && !englishRegex.test(newVal)) {
    artist.value.nameEn = newVal.replace(/[^A-Za-z ]/g, '').toUpperCase();
  }
});

watch(() => artist.value.domesticArea, (newVal) => {
  if (newVal?.length > 1) {
    artist.value.domesticArea = newVal.replace(/[A-Za-z]/g, '');
  }
});

const isValidVideoLink = ref(true);

const validateVideoLink = () => {
  try {
    const url = new URL(artist.value.videoLink);
    isValidVideoLink.value = true; // 유효성 검사 로직 추가
  } catch (error) {
    isValidVideoLink.value = false;
  }
};

// 데이터 로드 및 초기화
const loadData = async () => {
  try {
    uiStore.showLoading();
    const response = await apiClient.get('/v1/artists/me');
    artist.value = response.data;
    previewImageFile.value = {url: artist.value.imageUrl};
    validateVideoLink();
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false;
    uiStore.hideLoading();
  }
};

const saveProfile = async () => {
  const isValid = await validateMandatory();
  if (!isValid) {
    uiStore.hideLoading();
    return;
  }

  if (confirm("저장 하시겠습니까?")) {
    uiStore.showLoading();
    const formData = new FormData();
    if (selectedCropImageFile.value) {
      formData.append('cropImage', selectedCropImageFile.value);
      formData.append('originImage', selectedOriginImageFile.value);
    }

    artist.value.educations = educationRef.value.getFilteredData();
    artist.value.experiences = experienceRef.value.getFilteredData();
    artist.value.awards = awardRef.value.getFilteredData();
    artist.value.collections = collectionRef.value.getData();
    artist.value.interviews = interviewRef.value.getData();
    formData.append('reqDto', new Blob([JSON.stringify(artist.value)], {type: 'application/json'}));
    try {
      const response = await apiClient.put('/v1/artists/me', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (authStore.status.user.roleType === "ARTIST_BEFORE_PROFILE") {
        authStore.status.user.roleType = "ARTIST";
        localStorage.setItem('user', JSON.stringify(authStore.status.user));
      }

      alert("저장 되었습니다.");
      location.reload();
    } catch (error) {
      console.error('Error saveProfile data:', error);
    } finally {
      uiStore.hideLoading();
    }
  }
};

const validateMandatory = async () => {
  if (!previewImageFile.value.url) {
    alert('사진을 등록해 주세요.')
    return false;
  }

  if (!artist.value.nameKo && !artist.value.nameEn) {
    alert('작가명을 입력해 주세요.')
    return false;
  }

  if (!educationRef.value.validateData().isValid) {
    alert(educationRef.value.validateData().errorMessage);
    return false;
  }

  if (!experienceRef.value.validateData().isValid) {
    alert(experienceRef.value.validateData().errorMessage);
    return false;
  }

  if (!artist.value.domesticArea && !artist.value.overseasArea) {
    alert('활동지역을 입력해 주세요.');
    return false;
  }

  if (!artist.value.activityGenreTypes || artist.value.activityGenreTypes.length === 0) {
    alert('활동 장르를 선택해 주세요.');
    return false;
  }

  if (!artist.value.introduction.trim()) {
    alert('아티스트 한 줄 소개을 입력해 주세요.');
    return false;
  }

  // if (!awardRef.value.validateData().isValid) {
  //   alert(awardRef.value.validateData().errorMessage);
  //   return false;
  // }

  return true;
}

// crop img
const imageFile = ref(null);
const maxWidth = 193 * 5;
const maxHeight = 270 * 5;
let isFixedSize = false; // 이미지 크기 고정 여부(고정 하면 maxWidth, maxHeight size 로 파일 생성, 안하면 비율만 유지)

const previewImage = async (event) => {
  const fileInput = event.target; // file input element
  selectedOriginImageFile.value = fileInput.files[0];
  const file = fileInput.files[0];

  if (!file) {
    fileInput.value = "";
    return;
  }
  const maxFileSize = 10 * 1024 * 1024; // 10MB
  if (file.size > maxFileSize) {
    alert("파일 크기가 너무 큽니다. 10MB 이하의 파일만 업로드 가능합니다.");
    return;  // 용량 초과시 함수 종료
  }

  // 부모에서 이미지 파일만 전달
  imageFile.value = file;
  fileInput.value = "";
};

const handleCroppedImage = async (newVal) => {
  const {file, url, name} = newVal;
  previewImageFile.value = {url, name};
  selectedCropImageFile.value = file;
};

// 컴포넌트가 마운트되었을 때 데이터 로드
onMounted(() => {
  loadData();
});

</script>

<style scoped>

.mypage .my-artartist .profile .register .picture-box .img-wrap .filebox:hover .mask {
  opacity: 1;
}

.mypage .my-artartist .profile .register .picture-box .img-wrap .filebox .mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  opacity: 0;
  z-index: 1;
}

.img-upload-label {
  border-radius: 5px;
  background-color: #F7BE17;
  color: #151419;
  height: 36px;
}

</style>