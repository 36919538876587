// faqData.js

// 데이터: 회원 구분 > 대분류 > 소분류 > 제목, 내용
export const faqData = {
  common: [  // 공통 관련 데이터
    {
      category: '회원 정보',  // 대분류
      items: [
        {
          type: '회원 권한',  // 소분류
          title: '일반 회원과 아티스트 회원은 무엇이 다른가요?',
          content: 'ARTICLE21은 \'모든 회원\'에게 다양한 아티스트의 프로필과 아트워크, 아티클을 탐색할 수 있는 기능을 제공합니다. \n'
              + '각자의 취향에 따라 관심 아티스트와 아트워크, 아티클을 스크랩할 수 있는 기능을 포함하고 있습니다. \n'
              + '2025년 커뮤니티와 커머스 기능도 순차적으로 오픈할 예정입니다.\n'
              + '\n'
              + '만약 미술 전공자이거나 활동 이력(전시, 수상 내역 등)을 가진 아티스트라면 \'아티스트 회원\'으로 가입하실 수 있습니다. \n'
              + '아티스트 회원에게는 자신의 프로필과 아트워크를 자유롭게 업데이트하고 공유할 수 있는 개인 페이지가 생성됩니다.\n'
              + '또한 여기 등록된 정보들을 통해 쉽고 빠르게 포트폴리오를 제작할 수 있는 기능도 추가될 예정입니다.'
        },
        {
          type: '회원 전환',
          title: '일반 회원에서 아티스트 회원으로 전환할 수 있나요?',
          content: '아래 순서로 신청하시면 됩니다.\n'
              + '\n'
              + '1. \'MY PAGE\' → \'회원 정보 관리\' → \'나의 정보\' → 우측 상단 \'아티스트 회원으로 전환 신청\' 클릭\n'
              + '2. 추가 회원 정보 입력 및 아티스트 활동 자료 업로드(재학/졸업증명서, 예술인활동증명, 전시 홍보물 등 1개 이상)  → \'신청하기\' 클릭'
        },
        {
          type: '개인정보 변경',
          title: '연락처, 비밀번호 등 개인정보를 변경하는 방법은?',
          content: '\'\'MY PAGE\' → \'회원 정보 관리\' → \'나의 정보\'에서 변경해 주세요.'
        },
        {
          type: '회원 탈퇴',
          title: '회원 탈퇴 방법은?',
          content: '\'\'MY PAGE\' → \'회원 정보 관리\' → \'나의 정보\'에서 좌측 하단의 \'회원 탈퇴\'를 클릭해 주세요.'
        },
      ]
    },
    {
      category: '콘텐츠',
      items: [
        {
          type: '콘텐츠 제안',
          title: 'ARTICLE 페이지에 전시, 행사 정보 게시를 요청하는 방법은?',
          content: '우측 하단의 고객센터 버튼(헤드폰 모양)을 클릭하고 아래 순서로 요청하시면 됩니다.\n'
              + '\n'
              + '1. 분류에서 \'콘텐츠 게시 요청\' 클릭\n'
              + '2. 제목, 기간, 장소, 문의처, 관련 링크, 소개글, 관련 이미지 첨부(포스터 등)\n'
              + '3. 작성자 이메일과 전화번호 입력(내용 확인이나 추가 자료가 필요할 경우 담당자가 연락드릴 수 있습니다.)\n'
              + '4. 개인정보 수집 및 이용 동의 체크 후 \'보내기\' 클릭'
        },
        {
          type: '콘텐츠 공유',
          title: 'ARTICLE의 기사가 마음에 드는데, 그대로 복사해서 다른 사이트에 올려도 될까요?',
          content: 'ARTICLE21의 모든 콘텐츠는 무단 복제 및 재배포가 금지되어 있습니다.   \n'
              + 'ARTICLE21과 아티스트들의 권리 보호를 위해 \'공유하기\' 버튼을 활용해 주세요. '
        },
        {
          type: '콘텐츠 사용',
          title: 'ART & ARTISTS 페이지에 있는 작품 이미지를 캡쳐해서 사용해도 되나요?',
          content: '모든 아트워크는 해당 페이지의 아티스트에게 저작권이 있습니다.  \n'
              + 'ARTICLE21은 아티스트의 권리 보호를 위해 불법 복제나 도용을 일체 금하며, 위반 시 관련 법에 의해 처벌될 수 있습니다.'
        },
        {
          type: '좋아요 기능',
          title: '\'좋아요\' 버튼을 누른 관심 아티스트, 아트워크, 아티클을 한 번에 보는 방법은?',
          content: '\'MY PAGE\' → \'MY FAVORITE\'  → \'관심 아티스트\' / \'관심 아트워크\' / \'스크랩 아티클\' 페이지를 확인해 주세요.'
        },
      ]
    },
    {
      category: '기타',
      items: [
        {
          type: '사용자 지원',
          title: '도움이 필요합니다. 사용자 지원은 어떻게 받을 수 있나요?',
          content: '우측 하단 \'고객센터 버튼(헤드폰 모양)\' → 분류에서 \'문의 사항\' 클릭 후 요청 사항을 기재해 주세요.'
        },
        {
          type: '협업 제안',
          title: '협업을 제안하고 싶은 아티스트가 있다면 어떻게 연락을 취할 수 있나요?',
          content: 'ARTICLE21이 아티스트와의 성공적인 협업을 도와드립니다. \n'
              + '협업하고 싶은 아티스트와 시기, 방식 등 제안 사항을 메일(hello@article21.co.kr)로 보내주세요.  '
        }
      ]
    },
  ],
  artist: [  // 아티스트 관련 데이터
    {
      category: '아티스트 지원',
      items: [
        {
          type: '프로필 & 아트워크 관리',
          title: '프로필, 아트워크를 등록하면 어떤 이점이 있나요?',
          content: 'ART & ARTISTS 카테고리에 아티스트 개별 페이지와 함께 본인의 아이디가 포함된 링크가 생성됩니다. \n'
              + '개발이나 서버 비용 없이도 손쉽게 프로필과 작품 정보를 통합 관리, 공유할 수 있는 나만의 홈페이지를 만들 수 있습니다. \n'
              + '\n'
              + '프로필과 아트워크 업데이트를 활발히 할 경우, \n'
              + '새로운 아티스트나 작품을 찾는 큐레이터나 컬렉터들에게 홍보가 쉬워집니다. \n'
              + '아티스트와의 협업을 필요로 하는 기업 관계자들에게 흥미로운 제안을 받을 수도 있습니다.\n'
              + '\n'
              + '또한, 저작권 침해 분쟁 시 저작물 공표는 창작자가 법적으로 인정받을 수 있는 중요한 시점이 됩니다.\n'
              + 'ARTICLE21을 쉽고 빠르며 안정적인 작품 공표 장소로 활용해 보세요.'
        },
        {
          type: '프로필 & 아트워크 관리',
          title: '프로필과 아트워크 작성 팁이 있을까요?',
          content: '프로필은 정확하고 상세할수록, 아트워크는 다양할수록 좋습니다.\n'
              + '사소한 이력도 빠짐없이 기재하고 아직 등록하지 않은 아트워크가 있다면 서둘러 업로드해 주세요.\n'
              + '아티스트에 대한 정보가 많을수록 새로운 비즈니스 제안을 받을 기회가 늘어나고, ARTICLE21의 다양한 유저들을 팬으로 만들 수 있습니다.'
        },
        {
          type: '포트폴리오 제작',
          title: '포트폴리오 기능은 어떻게 이용할 수 있나요?',
          content: '\'MY PAGE\'에서 기 등록한 프로필과 아트워크 정보들 중 필요한 부분을 선택해 포트폴리오를 제작(PDF 다운로드)할 수 있습니다.\n'
              + '수정할 내용이 있거나 아직 등록하지 않은 정보를 추가하고 싶다면, 포트폴리오 제작 전 프로필과 아트워크를 업데이트해 주세요.\n'
              + '제작 기능은 2025년 5월부터 제공될 예정입니다. '
        },
      ]
    },
  ]
};
