<template>
  <!-- 선택 전: 회색 / 후 : 검정색 표현 -->
  <div :class="fieldClass"> 
    <!-- v-model을 사용하여 부모와 값 바인딩 -->
    <select :value="modelValue" @input="handleInput" required :style="{ color: modelValue ? '#151419' : '#B7B7B7' }">
      <option value="" :disabled="isDisabled">{{ placeholder }}</option>
      <option v-for="item in formattedOptions" :key="item.value" :value="item.value">
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';

// Props 정의
const props = defineProps({
  modelValue: { type: String, default: '' },
  options: Array,     // 옵션 리스트
  placeholder: { type: String, default: '선택' }, 
  fieldClass: { type: String, default: 'field-one field-select-1' },
  isDisabled: { type: Boolean, default: false },
});

const formattedOptions = computed(() => {
  if (props.options.length > 0 && typeof props.options[0] === 'object' && 'value' in props.options[0] && 'label' in props.options[0]) {
    return props.options;
  } else {
    // 단순 값 배열일 때는 value와 label로 변환
    return props.options.map(item => ({
      value: item,
      label: item,
    }));
  }
});

// `defineEmits`로 이벤트 정의
const emit = defineEmits(['update:modelValue']);

// 값 변경 시 부모에게 알리기 위한 핸들러
const handleInput = (event) => {
  // input 이벤트가 발생하면, 부모에게 값을 전달
  emit('update:modelValue', event.target.value); 
};
</script>
