<template>
  <div v-if="isVisible" class="floating-menu-box" :style="{ zIndex: isModalOpen ? 1001 : '' }">
    <ul>
      <li>
        <button type="button" class="btn-floating" :class="{close : isModalOpen}" @click="toggleModal(true)">
          <img src="@/assets/images/icon/ico_cs.svg" alt="">
        </button>
        <button type="button" class="btn-floating" :class="{close : !isModalOpen}" @click="historyBack">
          <img src="@/assets/images/icon/ico_close.png" alt="">
        </button>
      </li>
    </ul>
  </div>

  <button v-show="isVisible" type="button" class="btn-floating top" @click="scrollToTop">
    <img src="@/assets/images/icon/ico_top.svg" alt=""/>
    TOP
  </button>

  <CSModal v-if="isModalOpen" :toggle-modal="toggleModal"/>
</template>

<script setup>
import {ref, onMounted, onUnmounted, watch, nextTick} from "vue";
import CSModal from "@/components/fab/CSModal.vue";
import {useUiStore} from "@/store/useUiStore";

const uiStore = useUiStore();
const isModalOpen = ref(false);

const historyBack = () => {
  window.history.back();
}

const toggleModal = (status) => {
  isModalOpen.value = status;
  if (status) {
    uiStore.showOverlay();
  } else {
    uiStore.hideOverlay();
  }
};

// const isHome = ref(false);

// watch(
//   () => route.name,
//   (newName) => {
//     if (newName === 'Home') {
//       isHome.value = true;
//     } else {
//       isHome.value = false;
//     }
//   },
//   { immediate: true }
// );

// 스크롤 상태를 관리(사용안하면 추후 삭제)
const isVisible = ref(true);
const handleScroll = () => {
  isVisible.value = window.scrollY > 100; // 스크롤이 100px 이상일 때 버튼 보이기
};

const scrollToTop = () => {
  window.scrollTo({top: 0, behavior: "smooth"}); // 스크롤 최상단 이동
};

onMounted(() => {
  //window.addEventListener("scroll", handleScroll); // 스크롤 이벤트 등록
});

onUnmounted(() => {
  //window.removeEventListener("scroll", handleScroll); // 스크롤 이벤트 제거
});
</script>