<template>
  <div v-show="isArtWorkModalOpen" class="modal-popup modal-artwork">
    <div class="modal-btn-wrap view">
      <router-link v-if="location === 'favoriteArtwork' && activeArtWork" @click="emit('update:isArtWorkModalOpen', false);"
                   :to="{ name: 'ArtistDetail', params: { userAccountId: activeArtWork.artistUserAccountId } }" class="btn">
        <span>아티스트 프로필 가기</span>
        <img src="@/assets/images/icon/ico_right.svg" alt="">
      </router-link>

      <HeartButton v-if="artWorks && artWorks.length && activeArtWork" v-model:isLike="activeArtWork.isLike" :uuid="activeArtWork.uuid"
                   :likeType="activeArtWork.likeType" heartLocation="buttons"/>
      <ShareButton v-if="artWorks && artWorks.length && activeArtWork" :artist-user-account-id="activeArtWork.artistUserAccountId"
                   :uuid="activeArtWork.uuid"
                   location="button"/>
      <button type="button" class="close-modal close-artwork" @click="historyBack"><img
          src="@/assets/images/icon/ico_close_b.svg" alt=""></button>
    </div>

    <div class="modal-con">
      <div class="swiper-container slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="artWork in artWorks" :key="artWork.uuid" :data-id="artWork.uuid">
            <div class="inner">
              <div class="img zoom-area" @click="toggleArtWorkEnlarge" >
                <img :class="enlargedClass" :src="artWork.imageUrl"
                     :alt="artWork.title || artWork.titleEn"/>
              </div>
              <div class="txt-box">
                <p style="white-space: pre-line;">{{ formatArtWorkText(artWork) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-control">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>

      <div class="swiper-container slider-thumbnail">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="artWork in artWorks" :key="artWork.uuid">
            <img :src="artWork.imageUrl" :alt="artWork.title"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineEmits, defineProps, nextTick, onMounted, onUnmounted, ref, toRefs, watch, computed} from 'vue';
import {useRoute, useRouter} from "vue-router";
import Swiper from "swiper";
import HeartButton from "@/components/ui/HeartButton.vue";
import ShareButton from "@/components/ui/ShareButton.vue";

const props = defineProps({
  isArtWorkModalOpen: {type: Boolean, default: false},
  artWorks: {type: Array, default: () => []},
  slideIndex: {type: Number, default: -1},
  location: {type: String, default: 'artistDetail'},
  totalPages: {type: Number, required: true},
  currentPage: {type: Number, required: true},
  fetchItems: {type: Function, required: true},
});
const {artWorks, isArtWorkModalOpen, slideIndex, location} = toRefs(props);

watch(() => props.isArtWorkModalOpen, (newVal) => {
  if (newVal) {
    openArtWorkModal(slideIndex.value);
    activeIndex.value = slideIndex.value;
  }
});

const route = useRoute();
const router = useRouter();
const emit = defineEmits(["update:isArtWorkModalOpen"]);

const isArtWorkEnlarged = ref(false);
const artWorkMainSwiper = ref(null);
const artWorkThumbnailSwiper = ref(null);

const activeIndex = ref(0); // 현재 슬라이드 인덱스 추적

const activeArtWork = computed(() => {
  if (!artWorks.value || !artWorks.value.length) {
    return null;
  }
  return artWorks.value[activeIndex.value];
});

const enlargedClass = computed(() => ({
  enlarged: isArtWorkEnlarged.value
}));

const toggleArtWorkEnlarge = () => {
  isArtWorkEnlarged.value = !isArtWorkEnlarged.value;
};

const initializeSwiper = async () => {
  await nextTick();

  artWorkThumbnailSwiper.value = new Swiper('.slider-thumbnail', {
    slidesPerView: 'auto',
    freeMode: true,
    grabCursor: true,
    spaceBetween: 20,
    centeredSlides: true,
    breakpoints: {
      769: {spaceBetween: 10},
      1025: {spaceBetween: 15},
    },
  });

  artWorkMainSwiper.value = new Swiper('.slider', {
    slidesPerView: 'auto',
    grabCursor: true,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    thumbs: {
      swiper: artWorkThumbnailSwiper.value
    },
    on: {
      slideChange: () => {
        activeIndex.value = artWorkMainSwiper.value.activeIndex;
        artWorkThumbnailSwiper.value.slideTo(activeIndex.value, 200, true);

        if (activeIndex.value >= artWorks.value.length - 2) {
          if (props.currentPage + 1 < props.totalPages) {
            props.fetchItems(props.currentPage + 1, true);
          }
        }
      }
    }
  });

  artWorkMainSwiper.value?.update();
  artWorkThumbnailSwiper.value?.update();
};

const openArtWorkModal = async (slideIndex) => {
  await nextTick();
  window.history.pushState({modalOpen: true}, "", window.location.href);
  await nextTick(() => {
    // 먼저 메인 슬라이드와 썸네일 슬라이드 업데이트
    artWorkMainSwiper.value.update();
    artWorkThumbnailSwiper.value.update();
    if (artWorkMainSwiper.value) {
      if (slideIndex !== -1) {
        artWorkMainSwiper.value.slideTo(slideIndex, 0, false); // 해당 슬라이드로 즉시 이동
        // artWorkThumbnailSwiper.value.slideTo(slideIndex, 0, false); // 해당 슬라이드로 즉시 이동
        // artWorkMainSwiper.value.slideTo(slideIndex, 500, true) // 애니메이션
        // artWorkThumbnailSwiper.value.slideTo(slideIndex, 500, true) // 애니메이션
      } else {
        console.warn(`Slide with id ${slideIndex} not found`);
      }
    }
  });
};

const historyBack = () => {
  window.history.back();
}

const onArtWorksModalPopState = (event) => {
  closeArtWorkModal();
};

const closeArtWorkModal = () => {
  emit("update:isArtWorkModalOpen", false);
  if (route.query?.artwork) {
    router.replace({query: {...route.query, artwork: undefined}});
  }
};

const formatArtWorkText = (artWork) => {
  let formattedText = [artWork.title, artWork.titleEn].filter(Boolean).join(' | ');

  const additionalInfo = [artWork.material, artWork.size, artWork.year].filter(Boolean).join(' | ');

  if (additionalInfo) {
    formattedText += `\n${additionalInfo}`;
  }

  return formattedText;
};

onMounted(async () => {
  window.addEventListener("popstate", onArtWorksModalPopState);
  await initializeSwiper();
})

onUnmounted(() => {
  window.removeEventListener("popstate", onArtWorksModalPopState);
  if (artWorkMainSwiper.value) {
    artWorkMainSwiper.value.destroy();
    artWorkMainSwiper.value = null;
  }
  if (artWorkThumbnailSwiper.value) {
    artWorkThumbnailSwiper.value.destroy();
    artWorkThumbnailSwiper.value = null;
  }
});

</script>


<style scoped>
.swiper-slide-thumb-active {
  border: 3px solid #F7BE17;
  box-sizing: border-box;
}

.modal-artwork .modal-con .slider .swiper-slide .inner .img img {
  filter: drop-shadow(16px 16px 8px rgba(0, 0, 0, 0.5)) drop-shadow(-2px -2px 8px rgba(0, 0, 0, 0.5));
}

.modal-popup.modal-artwork {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.modal-artwork .modal-con {
  height: 100dvh;
  overflow: hidden; /* 스크롤 없애기 */
  display: flex;
  flex-direction: column;
  padding-bottom: 0; /* 필요 시 조정 */
}

.modal-con .slider {
  flex: 5;
}

.modal-con .slider-thumbnail {
  flex: 1;
}

.modal-artwork .modal-con .slider-thumbnail .swiper-slide {
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  width: auto;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

/* swiper 테스트중
.modal-artwork .modal-con .slider-thumbnail .swiper-slide img{
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.enlarged {
  transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
}

.swiper-slide .img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-artwork .modal-con .slider-thumbnail .swiper-slide img {
  aspect-ratio: 1 / 1;
}



.modal-artwork .modal-con {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modal-artwork .modal-con .slider {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh
}

.modal-artwork .modal-con .slider .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.modal-artwork .modal-con .slider .swiper-slide .inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.modal-artwork .modal-con .slider .swiper-slide .inner .img {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}



.modal-artwork .modal-con .slider .swiper-slide .inner .img img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.modal-artwork .modal-con .slider .swiper-slide .inner .txt-box {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
}

*/

</style>