import MyPageLayout from "@/components/layout/MyPageLayout.vue";
import FavoriteArtist from "@/views/user/FavoriteArtist.vue";
import FavoriteArticle from "@/views/user/FavoriteArticle.vue";
import FavoriteArtwork from "@/views/user/FavoriteArtWork.vue";

// MYPAGE > MY FAVORITE 만 적용 추후 MYPAGE 다른 부분 옮기기
export const myPageRoutes = [
  {
    path: "/my",
    component: MyPageLayout, // 공통 레이아웃 적용
    children: [
      {
        path: "favorite/artist",
        name: "FavoriteArtist",
        component: FavoriteArtist,
      },
      {
        path: "favorite/artwork",
        name: "FavoriteArtwork",
        component: FavoriteArtwork,
      },
      {
        path: "favorite/article",
        name: "FavoriteArticle",
        component: FavoriteArticle,
      },
    ],
  },
];
